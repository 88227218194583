<main-content>
 <div class="container">
  <div class="posts">
    <h1>İlanlar</h1>
  </div>
  <mat-tab-group (selectedTabChange)="tabChange($event)" [selectedIndex]="currentIndex$ | async">
      <mat-tab label="Ana Sayfa">
        <job-posting-home></job-posting-home>
      </mat-tab>
      <mat-tab label="Staj İlanları"> 
        <search-job-posting></search-job-posting>
      </mat-tab>
  </mat-tab-group>
 </div>
</main-content>
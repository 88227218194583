import { Component } from '@angular/core';
import { BlogService } from '@common/services/blog.service';
import { map } from 'rxjs';

@Component({
  selector: 'blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent {

  constructor(
    public blogService:BlogService
  ) { }

  public data$ = this.blogService.getHome().pipe(map(res => res.data));

}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';

@Component({
  selector: 'discovery-categories',
  templateUrl: './discovery-categories.component.html',
  styleUrls: ['./discovery-categories.component.scss'],
})
export class DiscoveryCategoriesComponent {
  constructor(
    public discoveryCatalogService: DiscoveryCatalogService,
    public activatedRoute: ActivatedRoute
  ) {}

  public filterText$ = new BehaviorSubject<string>('');
  public get filterText() {
    return this.filterText$.value;
  }

  public set filterText(value) {
    this.filterText$.next(value);
  }

  public searchParam$ = this.activatedRoute.queryParams.pipe(
    map((params) => params['search'] || '')
  );

  public categories$ = this.discoveryCatalogService
    .getCategories()
    .pipe(map((res) => res.data))
    .pipe(
      switchMap((categories) =>
        this.filterText$.pipe(
          map((text) => {
            const newCategories = { ...categories };
            if (text === '') {
              return categories;
            } else {
              Object.keys(categories).forEach((key) => {
                newCategories[key] = categories[key].filter((item) =>
                  item.name.toLowerCase().includes(text.toLowerCase())
                );
              });
              return newCategories;
            }
          })
        )
      )
    );

  public searchChange(event: any) {
    this.filterText$.next(event.target.value);
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { concat } from 'rxjs/internal/observable/concat';
import { from } from 'rxjs/internal/observable/from';
import { interval } from 'rxjs/internal/observable/interval';
import { of } from 'rxjs/internal/observable/of';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { delay } from 'rxjs/internal/operators/delay';
import { ignoreElements } from 'rxjs/internal/operators/ignoreElements';
import { map } from 'rxjs/internal/operators/map';
import { repeat } from 'rxjs/internal/operators/repeat';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'hero-text-animation',
  templateUrl: './hero-text-animation.component.html',
  styleUrls: ['./hero-text-animation.component.scss'],
})
export class HeroTextAnimationComponent implements OnInit {
  typedText$: Observable<string>
  titles = ['Şimdi', 'Burada'];
  fonts = ['font1', 'font2', 'font3', 'font4'];
  currentIndex: number = 0;
  currentFontStyle: string = 'Arial, sans-serif';

  constructor(
    //platformId
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.typedText$ = this.getTypewriterEffect(this.titles);
    } else {
      this.typedText$ = of(this.titles[0])
    }
  }

  private type({ word, speed, backwards = false }: any) {
    return interval(speed).pipe(
      map((x) =>
        backwards
          ? word.substring(0, word.length - x)
          : word.substring(0, x + 1)
      ),
      take(word.length)
    );
  }
  typeEffect(word: string) {
    return concat(
      this.type({ word, speed: 50 }),
      of('').pipe(delay(1200), ignoreElements()),
      this.type({ word, speed: 30, backwards: true }),
      of('').pipe(delay(300), ignoreElements())
    );
  }
  getTypewriterEffect(titles: string[]) {
    return from(titles).pipe(
      concatMap((title) => this.typeEffect(title)),
      repeat()
    );
  }

  private changeFontStyle() {
    this.currentIndex = this.currentIndex === 2 ? 0 : this.currentIndex + 1;
    this.currentFontStyle = this.fonts[this.currentIndex];
  }
}

<ng-container *ngIf="!controller.trendsLoading">
  <div class="container trends">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="row">
          <div
            class="col-md-4"
            style="padding: 15px"
            *ngFor="let item of controller.trendsData.trending"
          >
            <generic-card [data]="item"></generic-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="controller.trendsLoading">
  <discovery-loading-row></discovery-loading-row>
</ng-container>

<div class="container-fluid header">
  <div class="row">
    <div class="col-12">
      <!-- background image -->
      <media-image
        class="img-fluid"
        [src]="user.backdrop || 'assets/img/profile-background.png'"
        appereance="rounded"
        objectFit="contain"
        aspectRatio="16/3"
        [fill]="true"
        [priority]="true"
      ></media-image>
      <div class="row align-items-center justify-content-between">
        <!--Avatar -->
        <div class="col-4 d-flex align-items-start avatar-card">
          <media-image class="media-img" width="100" height="100" appereance="circle" [src]="user.avatar" alt="" />
        </div>
        <!--Right side buttons-->
        <div class="col-8 d-flex justify-content-end flat-colored-button-1">
          <button
            *ngIf="isMe; else followButton"
            mat-stroked-button
            color="primary"
            [routerLink]="['/admin/profile']"
            routerLinkActive="router-link-active"
          >
            <mat-icon> edit </mat-icon>
            Profili Düzenle
          </button>
          <ng-template #followButton>
            <button
              mat-stroked-button
              color="primary"
              class="message"
              (click)="followerService.toogleExtended(user).subscribe()"
            >
              {{ user.currentUserFollowed ? "Takibi Bırak" : "Takip Et" }}
            </button>
          </ng-template>
        </div>
      </div>

      <div class="row align-items-center justify-content-between">
        <!-- User İnfo -->
        <div class="col-6 d-flex align-items-start">
          <div style="padding-bottom: 16px;">
            <h2 class="mat-header" style="margin: 2px;">
              {{ user.display_name }}
            </h2>
            <!-- <h3 class="mat-subtitle-1">{{user.jobs}}</h3> -->
            <h4 class="mat-subtitle-2 user-info" style="margin: 2px;">{{ user?.headnote }}</h4>
            <h5 class="mat-subtitle-3 user-info" style="margin: 2px;">{{ user?.followerCount }} Takipçi</h5>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end flat-button-gray">
          <ng-container *ngIf="user.contactList && user.contactList.length > 0">
            <span *ngFor="let contact of user.contactList">
              <a
                [href]="contact.data"
                target="_blank"
                mat-flat-button
                style="border-radius: 100px"
              >
                <div style="display: flex; align-items: center; gap: 10px">
                  <media-image
                    [src]="contact.data | favicon"
                    style="height: 16px"
                    appereance="rounded"
                    objectFit="cover"
                    width="16"
                    height="16"
                    aspectRatio="1/1"
                  ></media-image>
                  <span>{{ contact.name | titlecase }}</span>
                </div>
              </a>
            </span>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="user.extraSections && filtered.length > 0">
        <div class="row experience">
          <ng-container
            *ngFor="let section of filtered | slice : 0 : 4; let i = index"
          >
            <div class="col-md-3">
              <mat-card style="height: 100%" class="hover-effect-card">
                <div class="subheader-avatar-card">
                  <div
                    class="avatar-icon"
                    *ngIf="!getAvatar(section)"
                    [style.backgroundColor]="getRandomColor(i)"
                  >
                    <div class="avatar-background">
                      <mat-icon *ngIf="section.type === 'education'"
                        >school</mat-icon
                      >
                      <mat-icon *ngIf="section.type === 'experience'"
                        >work</mat-icon
                      >
                      <mat-icon *ngIf="section.type === 'certificate'"
                        >insert_drive_file</mat-icon
                      >
                    </div>
                  </div>
                  <media-image
                    *ngIf="getAvatar(section)"
                    appereance="rounded"
                    objectFit="cover"
                    width="48"
                    height="48"
                    [zIndex]="50"
                    [src]="getAvatar(section)"
                  ></media-image>
                  <div>
                    <div class="text-muted mt-1">
                      <span *ngIf="section.type === 'education'">EĞİTİM</span>
                      <span *ngIf="section.type === 'experience'">DENEYİM</span>
                      <span *ngIf="section.type === 'certificate'"
                        >SERTİFİKA</span
                      >
                    </div>
                    <div class="organization">{{ section.organization }}</div>
                    <div class="position">
                      {{ section.name }}
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-container>
          <div class="col-md-3" *ngIf="isMe">
            <mat-card
              style="height: 100%; padding: 10px"
              class="hover-effect-card"
              (click)="openBottomSheet()"
            >
              <div class="subheader-avatar-card">
                <div
                  class="avatar-icon"
                  [style.backgroundColor]="getRandomColor(0)"
                >
                  <div class="avatar-background">
                    <mat-icon>edit</mat-icon>
                  </div>
                </div>
                <div>
                  <div class="organization">Deneyimlerinizi Düzenleyin</div>
                  <div class="position">Kendinizi daha iyi tanıyın!</div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<main-content>
  <div class="header">
    <div class="container">
      <h1>Kişiler</h1>
      <mat-tab-group mat-stretch-tabs="false">
        <mat-tab label="Ana sayfa">
          <people-home></people-home>
        </mat-tab>
        <mat-tab label="Öğrenciler">
          <people-students></people-students>
        </mat-tab>
        <mat-tab label="Profesyoneller">
          <people-professionals></people-professionals>
        </mat-tab>
        <mat-tab label="Arkadaşlarım"> </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</main-content>

<main-content>
  <ng-container *ngIf="post$ | async as post; else loading">
    <div class="container">
      <post [post]="post" [showCommentButton]="false"></post>
      <div class="replies">
        <post-replies [post]="post"></post-replies>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="loading">
      <center-loading></center-loading>
    </div>
  </ng-template>
</main-content>

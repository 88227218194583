<mat-card appearance="outlined" class="community mat-elevation-z0 hover-effect-card" [routerLink]="['/community',community.id]">
  <div class="backdrop-area" style="position: relative; height: 120px">
    <div
      style="position: absolute"
      class="backdrop"
      [style.backgroundImage]="'url(' + community.backdrop + ')'"
    ></div>
    <div
      style="
        width: 100%;
        height: 120px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px 12px 0 0;
      "
    ></div>
    <img class="avatar" [src]="community.avatar" alt="Avatar" />
  </div>
  <h3 class="name">{{ community.name }}</h3>
  <p class="short-desc">{{ community.summary }}</p>
  <p class="role">Katılımcı üye</p>
</mat-card>

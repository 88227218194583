import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '@common/services/pages.service';
import { BehaviorSubject, catchError, map, switchMap, throwError } from 'rxjs';

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  constructor(
    public pagesService: PagesService,
    public activatedRoute: ActivatedRoute
  ) {}

  public error$ = new BehaviorSubject<any>(null);

  public page$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        const slug = params['slug'];
        if (slug) {
          return this.pagesService.getBySlug(slug).pipe(map((res) => {
            console.log(res);
            return res.data.page
          }));
        }
        return null;
      })
    )
    .pipe(
      catchError((error) => {
        this.error$.next(error);
        return throwError(() => error);
      })
    );
}

<div class="message-other">
  <message-images
    *ngIf="message.files?.length"
    [message]="message"
  ></message-images>
  <message-video
    *ngIf="message?.files?.length && message.files[0].type.includes('video')"
    [message]="message"
  ></message-video>

  <div class="message-text">{{ message.text }}</div>
</div>

<ng-container *ngIf="data$ | async as data; else loading">
  <div class="container">
    <div
      class="row saved"
      style="width: 100%; margin-top: 5%; padding-left: 2%"
    >
      <mat-chip-set *ngIf="savedFilterType$ | async as selectedType">
        <mat-chip-option
          *ngFor="let type of types"
          (click)="selectType(type.type)"
          [selected]="selectedType == type.type"
        >
          <mat-icon matChipTrailingIcon>{{ type.icon }}</mat-icon>
          {{ type.name }}
        </mat-chip-option>
      </mat-chip-set>
    </div>
    <ng-container *ngIf="!loadingData">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div
              class="col-md-4"
              style="padding: 15px"
              *ngFor="let item of data.saved"
            >
              <generic-card [data]="item"></generic-card>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="loadingData">
      <discovery-loading-row></discovery-loading-row>
    </ng-container>
  </div>
</ng-container>
<ng-template #loading>
  <discovery-loading-row></discovery-loading-row>
</ng-template>

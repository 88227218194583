<main-content>
  <div class="header">
    <div class="container">
      <h1>Arkadaşlar</h1>
      <div class="content">
        <div class="d-flex justify-content-between">
          <h2>Favoriler</h2>
          <button mat-button color="primary">Hepsini gör</button>
        </div>
        <div class="joined">
          <button mat-icon-button class="icon-left"><mat-icon>chevron_left</mat-icon></button>
          <mat-card
            appearance="outlined"
            class="person mat-elevation-z0"
            *ngFor="let person of people"
          >
            <div
              class="backdrop-area"
              style="position: relative; height: 120px"
            >
              <div
                style="position: absolute"
                class="backdrop"
                [style.backgroundImage]="'url(' + person.backdrop + ')'"
              ></div>
              <div
                style="
                  width: 100%;
                  height: 120px;
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.3);
                  border-radius: 12px 12px 0 0;
                "
              ></div>
              <img class="avatar" [src]="person.avatar" alt="Avatar" />
            </div>
            <h3 class="name">{{ person.name }}</h3>
            <p class="short-desc">{{ person.shortDesc }}</p>
            <p class="role">Katılımcı üye</p>
          </mat-card>
          <button mat-icon-button class="icon-right">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <info-card
          title="Yeni Bağlantılar Kurun"
          icon="person_add"
          text="Kişiler sayfasında ortak kurumlarda okuduğunuz, çalıştığınız, ilgi alanlarına sahip olduğunuz insanları bulun, bağlantı kurun."
          buttonText="Kişileri Keşfet"
        ></info-card>
        <div class="following-area">
          <h2>Tüm arkadaşlar</h2>
          <div class="followed row">
            <div class="d-flex community" *ngFor="let person of people">
              <img [src]="person.avatar" alt="" class="avatar" />
              <div>
                <h3 class="name">{{ person.name }}</h3>
                <p class="short-desc">{{ person.shortDesc }}</p>
                <p class="details">35.701 Takipçi • Eğlence Sektörü Şirketi</p>
                <br />
                <button
                  style="
                    border-style: none;
                    background-color: #c9cbfb;
                    color: #4b53f2;
                  "
                  mat-raised-button
                  color="accent"
                  class="action mat-elevation-z0"
                >
                  Takip Ediliyor&nbsp;<mat-icon>check</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="following-area">
          <h2>Sizi Takip Edenler</h2>
          <div class="followed row">
            <div class="d-flex community" *ngFor="let person of people">
              <img [src]="person.avatar" alt="" class="avatar" />
              <div>
                <h3 class="name">{{ person.name }}</h3>
                <p class="short-desc">{{ person.shortDesc }}</p>
                <p class="details">35.701 Takipçi • Eğlence Sektörü Şirketi</p>
                <br />
                <button
                  mat-raised-button
                  color="primary"
                  class="action mat-elevation-z0"
                >
                  Takip Et&nbsp;<mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main-content>

<div class="flipping-section-container">
    <div class="grid-container mask1">
        <div class="grid-item item1 color1"></div>
        <div class="grid-item item2 no-color"></div>
        <div class="grid-item item1 ">
            <div class="flipping-card">
                <div class="flipping-content item1 color2">
                    <div class="front"></div>
                    <div class="back">
                        <img src="/assets/img/turkcell-2.png" width="100%" height="100%"/>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="grid-item item2 color3"></div>
        <div class="grid-item item1 no-color"></div>
        <div class="grid-item item1 no-color"></div>
        <div class="grid-item item1 color4"></div>
        <div class="grid-item item1 no-color"></div>
        <div class="grid-item item2 color6">
            <div class="flipping-card">
                <div class="flipping-content item2">
                    <div class="front"></div>
                    <div class="back">
                        <img style="object-fit: fill;" src="/assets/img/thy.png" width="100%" height="100%"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-item item1 no-color"></div>
        <div class="grid-item item2 no-color"></div>
        <div class="grid-item item1 color5"></div>
    </div>
    <div class="right-side">
        <div class="badge">UNILOCKED PARTNERLERİ</div>
              <h1 class="hero-text">Dünyanın En Prestijli Okulları
            Ve En Büyük Şirketleri Burada</h1>
            <div class="buttons">
                <button class="secondary-button">Okulları Keşfedin</button>
                <button class="primary-button">
                    Şirketleri Keşfedin
                </button>
            </div>
            
    </div>
</div>

  
    
  
  
  
import { Component } from '@angular/core';

@Component({
  selector: 'home-partners',
  templateUrl: './home-partners.component.html',
  styleUrls: ['./home-partners.component.scss']
})
export class HomePartnersComponent {

}

import { Injectable } from '@angular/core';
import { AppHttpClient } from '../http/http.service';
import { Members } from 'src/app/models/relations/members';
import { User } from 'src/app/models/user';
import { Settings } from '@common/auth/settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class BootstrapDataService {
  constructor(private http: AppHttpClient) {}

  public init() {
    return this.http.get<{
      success: boolean;
      data: {
        followedIds: any[];
        user: User;
        settings: Settings[];
        members: Members[];
        unreadNotifications: number;
      };
    }>('bootstrap-data');
  }
}

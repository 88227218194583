import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiteModule } from './site/site.module';
import { AppCommonModule } from '@common/common.module';
import { AuthModule } from '@common/auth/auth.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ToastModule } from '@common/core/toast/toast/toast.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonService } from '@common/services/button.service';
import { ErrorPageModule } from '@common/ui/error-page/error-page.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular'; // Import the Sentry module
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from '@common/ui/paginator/mat-paginator-intl-cro';
import { MetaTagsInterceptor } from '@common/core/meta/meta-tags-interceptor';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = {
  url: environment.api,
  options: {
    transports: ['websocket'],
    autoConnect: false,
  },
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SiteModule,
    AuthModule,
    ToastModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ErrorPageModule,
    MarkdownModule.forRoot(),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    provideClientHydration(withEventReplay()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    SsrCookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    ButtonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MetaTagsInterceptor,
      multi: true,
    },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(),
    provideMarkdown(),
  ],
})
export class AppModule {}

import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'job-posting',
  templateUrl: './job-posting.component.html',
  styleUrls: ['./job-posting.component.scss'],
})
export class JobPostingComponent {
  constructor(public activatedRoute: ActivatedRoute,public router:Router) {}

  public tabChange(event: any) {
    if (event.index == 0) {
      this.router.navigate(['/job-posting']);
    }
  }

  public currentIndex$ = this.activatedRoute.queryParams.pipe(
    map((params) => {
      if (params['tag']) {
        return 1;
      } else {
        return 0;
      }
    })
  );
}

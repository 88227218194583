import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHomeComponent } from './main-home/main-home.component';
import { GuestHomeComponent } from './main-home/guest-home/guest-home.component';

import { UserHomeComponent } from './main-home/user-home/user-home.component';
import { SiteRoutingModule } from './site.routing.module';
import { AppCommonModule } from '@common/common.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ExploreComponent } from './explore/explore.component';
import { AnimatedCounterDirective } from './main-home/guest-home/animated-counter.directive';
import { MainMenuComponent } from '../../common/ui/main-menu/main-menu.component';
import { ProjectsComponent } from './projects/projects.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProjectsHomeComponent } from './projects/tabs/projects-home/projects-home.component';
import { CommunitiesComponent } from './communities/communities.component';
import { ProjectCardComponent } from './projects/components/project-card/project-card.component';
import { ProjectsFromCommunitiesComponent } from './projects/tabs/projects-from-communities/projects-from-communities.component';
import { ProjectsFromFriendsComponent } from './projects/tabs/projects-from-friends/projects-from-friends.component';
import { MyProjectsComponent } from './projects/tabs/my-projects/my-projects.component';
import { CompaniesComponent } from './companies/companies.component';
import { PeopleComponent } from './people/people.component';
import { PeopleHomeComponent } from './people/panels/people-home/people-home.component';
import { PeopleStudentsComponent } from './people/panels/people-students/people-students.component';
import { PeopleProfessionalsComponent } from './people/panels/people-professionals/people-professionals.component';
import { FriendsComponent } from './friends/friends.component';
import { SavedComponent } from './saved/saved.component';
import { SavedPostsComponent } from './saved/panels/saved-posts/saved-posts.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EventsComponent } from './events/events.component';

import { AuthModule } from '@common/auth/auth.module';
import { UserProfileComponent } from './profiles/user-profile/user-profile.component';
import { ProfileContentComponent } from './profiles/profile-content/profile-content.component';
import { MatChipsModule } from '@angular/material/chips';
import { SchoolComponent } from './school/school.component';

import { SearchResultsComponent } from './search-results/search-results.component';
import { BrowserModule } from '@angular/platform-browser';

import { SearchPostsComponent } from './search-results/tabs/search-posts/search-posts.component';
import { SearchPeopleComponent } from './search-results/tabs/search-people/search-people.component';
import { SearchProjectsComponent } from './search-results/tabs/search-projects/search-projects.component';
import { SearchCompaniesComponent } from './search-results/tabs/search-companies/search-companies.component';
import { SearchEventsComponent } from './search-results/tabs/search-events/search-events.component';
import { SearchCommunitiesComponent } from './search-results/tabs/search-communities/search-communities.component';
import { SearchHighlightsComponent } from './search-results/tabs/search-highlights/search-highlights.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { JobPostingComponent } from './job-posting/job-posting.component';

import { LayoutModule } from '@angular/cdk/layout';
import { MainContentComponent } from '../../common/ui/main-content/main-content.component';

import { MessagesComponent } from './messages/messages.component';
import { CompaniesExtendedComponent } from './companies/companies-extended/companies-extended.component';
import { PeopleExtendedComponent } from './people/people-extended/people-extended.component';
import { ProjectsExtendedComponent } from './projects/projects-extended/projects-extended.component';
import { CommunitiesExtendedComponent } from './communities/communities-extended/communities-extended.component';
import { EventsExtendedComponent } from './events/events-extended/events-extended.component';
import { UserPostsComponent } from './profiles/user-profile/user-posts/user-posts.component';
import { UserHighlightsComponent } from './profiles/user-profile/user-highlights/user-highlights.component';
import { PlanetLeftComponent } from './main-home/guest-home/planet-left/planet-left.component';
import { PlanetRightComponent } from './main-home/guest-home/planet-right/planet-right.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DiscoveryCatalogComponent } from './discovery-catalog/discovery-catalog.component';
import { CommunityCardComponent } from './discovery-catalog/catalog-cards/community-card/community-card.component';
import { CompanyCardComponent } from './discovery-catalog/catalog-cards/company-card/company-card.component';
import { PersonCardComponent } from './discovery-catalog/catalog-cards/person-card/person-card.component';
import { ProjectPostCardComponent } from './discovery-catalog/catalog-cards/project-post-card/project-post-card.component';
import { EventsCardComponent } from './discovery-catalog/catalog-cards/events-card/events-card.component';
import { JobCardComponent } from './discovery-catalog/catalog-cards/job-card/job-card.component';
import { UploadModule } from '@common/core/upload/upload.module';
import { HomePartnersComponent } from './main-home/guest-home/home-partners/home-partners.component';
import { InternCardComponent } from './discovery-catalog/catalog-cards/intern-card/intern-card.component';
import { ServiceErrorComponent } from './service-error/service-error.component';
import { MessagesMainComponent } from './messages/components/messages-main/messages-main.component';
import { MessagesBoxComponent } from './messages/components/messages-box/messages-box.component';
import { MessagesMenuComponent } from './messages/components/messages-menu/messages-menu.component';
import { ChatBoxComponent } from './messages/components/chat-box/chat-box.component';
import { MessageItemComponent } from './messages/components/message-item/message-item.component';
import { MessageUserComponent } from './messages/components/message-item/message-user/message-user.component';
import { MessageOtherComponent } from './messages/components/message-item/message-other/message-other.component';
import { NewChatComponent } from './messages/components/new-chat/new-chat.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WriteMessageComponent } from './messages/components/write-message/write-message.component';
import { PersonCardBigComponent } from './people/components/person-card-big/person-card-big.component';
import { PersonCardSmallComponent } from './people/components/person-card-small/person-card-small.component';
import { CompanyCardBigComponent } from './companies/components/company-card-big/company-card-big.component';
import { CompanyCardSmallComponent } from './companies/components/company-card-small/company-card-small.component';
import { CommunityCardBigComponent } from './communities/components/community-card-big/community-card-big.component';
import { CommunityCardSmallComponent } from './communities/components/community-card-small/community-card-small.component';
import { DiscoverySpecialForYouComponent } from './discovery-catalog/panels/discovery-special-for-you/discovery-special-for-you.component';
import { DiscoveryTrendsComponent } from './discovery-catalog/panels/discovery-trends/discovery-trends.component';
import { DiscoveryCategoriesComponent } from './discovery-catalog/panels/discovery-categories/discovery-categories.component';
import { DiscoveryFollowedComponent } from './discovery-catalog/panels/discovery-followed/discovery-followed.component';
import { DiscoverySavedComponent } from './discovery-catalog/panels/discovery-saved/discovery-saved.component';
import { GenericCardComponent } from './discovery-catalog/catalog-cards/generic-card/generic-card.component';
import { CategoriesSearchComponent } from './discovery-catalog/panels/discovery-categories/categories-search/categories-search.component';
import { PageComponent } from './page/page.component';
import { ErrorPageModule } from '@common/ui/error-page/error-page.module';
import { MatButtonModule } from '@angular/material/button';
import { CheckoutComponent } from './checkout/checkout.component';

import { PurchaseEventTicketComponent } from './events/purchase-event-ticket/purchase-event-ticket.component';
import { ProfilesModule } from './profiles/profiles/profiles.module';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { PostsModule } from './posts/posts.module';
import { PostPageComponent } from './posts/post-page/post-page.component';
import { TextEditorComponent } from '@common/text-editor/text-editor.component';
import { TextEditorModule } from '@common/text-editor/text-editor.module';
import { BlogModule } from './blog/blog.module';
import { BlogCardComponent } from './blog/blog-card/blog-card.component';
import { MessageImagesComponent } from './messages/components/message-item/message-images/message-images.component';
import { MessageFilePreviewComponent } from './messages/components/message-file-preview/message-file-preview.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MessageChatDetailsComponent } from './messages/components/message-chat-details/message-chat-details.component';
import { JobPostingModule } from './job-posting/job-posting/job-posting.module';
import { SearchJobPostingComponent } from './job-posting/search-job-posting/search-job-posting.component';
import { JobPostingHomeComponent } from './job-posting/job-posting-home/job-posting-home.component';
import { JobPostingApplicationComponent } from './job-posting/job-posting-application/job-posting-application.component';
import { SearchPersonCardComponent } from './search-results/search-cards/search-person-card/search-person-card.component';
import { JobPostingCardSmallComponent } from './job-posting/components/job-posting-card-small/job-posting-card-small.component';
import {
  NgxSkeletonLoaderComponent,
  NgxSkeletonLoaderModule,
} from 'ngx-skeleton-loader';
import { JobPostingLoaderComponent } from './job-posting/components/job-posting-loader/job-posting-loader.component';
import { DiscoveryLoadingWithTitlesComponent } from './discovery-catalog/loading/discovery-loading-with-titles/discovery-loading-with-titles.component';
import { DiscoveryLoadingRowComponent } from './discovery-catalog/loading/discovery-loading-row/discovery-loading-row.component';
import { UserInterestsComponent } from './discovery-catalog/panels/user-interests/user-interests.component';
import { MatMenuModule } from '@angular/material/menu';
import { NotFoundComponent } from './not-found/not-found.component';
import { MaterialDesignModule } from '@common/material-design/material-design.module';
import { InViewportModule } from 'ng-in-viewport';
import { UserProfileHeaderComponent } from './profiles/user-profile/user-profile-header/user-profile-header.component';
import { UserAboutComponent } from './profiles/user-profile/user-about/user-about.component';
import { UserEducation } from '../models/relations/school/user-education';
import { UserCommunitiesComponent } from './profiles/user-profile/user-communities/user-communities.component';
import { UserProjectsComponent } from './profiles/user-profile/user-projects/user-projects.component';
import { UserSkillsComponent } from './profiles/user-profile/user-skills/user-skills.component';
import { UserFeaturedComponent } from './profiles/user-profile/user-featured/user-featured.component';
import { UserFollowingComponent } from './profiles/user-profile/user-following/user-following.component';
import { UserExtrasectionComponent } from './profiles/user-profile/user-extrasection/user-extrasection.component';
import { ProfileEditingModule } from '../admin/profile-editing/profile-editing.module';
import { EditProfileBottomSheetComponent } from './profiles/user-profile/edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';
import { UsertypefilterPipe } from '@common/core/pipes/usertypefilter.pipe';
import { MessageRoomMembersComponent } from './messages/components/message-room-members/message-room-members.component';
import { MessageMemberDetailsComponent } from './messages/components/message-member-details/message-member-details.component';
import { MessageVideoComponent } from './messages/components/message-item/message-video/message-video.component';
import { MessageChatFilesComponent } from './messages/components/message-chat-files/message-chat-files.component';
import { PersonalCalendarComponent } from './personal-calendar/personal-calendar.component';
import { PersonalCalendarListComponent } from './personal-calendar/personal-calendar-list/personal-calendar-list.component';
import { PersonalCalendarWeekComponent } from './personal-calendar/personal-calendar-week/personal-calendar-week.component';
import { PersonalCalendarMonthComponent } from './personal-calendar/personal-calendar-month/personal-calendar-month.component';
import { CalendarModule } from '../admin/calendar/calendar.module';
import { IsRoomEditablePipe } from './messages/components/message-chat-details/is-room-editable.pipe';
import { MakeMessageAdminButtonTextPipe } from './messages/components/message-room-members/make-message-admin-button-text.pipe';
import { HeroTextAnimationComponent } from './main-home/guest-home/hero-text-animation/hero-text-animation.component';
import { BlogPostsComponent } from './main-home/guest-home/blog-posts/blog-posts.component';
import { FlippingCardsComponent } from './main-home/guest-home/flipping-cards/flipping-cards.component';
import { FooterComponent } from './main-home/guest-home/footer/footer.component';
import { PopularTodayComponent } from './main-home/guest-home/popular-today/popular-today.component';
import { YoumightknowComponent } from '@common/ui/youmightknow/youmightknow.component';
import { PartnersComponent } from './main-home/guest-home/partners/partners.component';

@NgModule({
  declarations: [
    MainHomeComponent,
    GuestHomeComponent,
    UserHomeComponent,
    ExploreComponent,
    AnimatedCounterDirective,
    ProjectsComponent,
    ProjectsHomeComponent,
    CommunitiesComponent,
    ProjectCardComponent,
    ProjectsFromCommunitiesComponent,
    ProjectsFromFriendsComponent,
    MyProjectsComponent,
    CompaniesComponent,
    PeopleComponent,
    PeopleHomeComponent,
    PeopleStudentsComponent,
    PeopleProfessionalsComponent,
    FriendsComponent,
    SavedComponent,
    SavedPostsComponent,
    NotificationsComponent,
    UserProfileComponent,
    EventsComponent,
    ProfileContentComponent,
    SchoolComponent,
    SearchResultsComponent,
    SearchPostsComponent,
    SearchPeopleComponent,
    SearchProjectsComponent,
    SearchCompaniesComponent,
    SearchEventsComponent,
    SearchCommunitiesComponent,
    SearchHighlightsComponent,

    JobPostingComponent,
    EventDetailsComponent,
    PlanetLeftComponent,
    PlanetRightComponent,
    MessagesComponent,
    CompaniesExtendedComponent,
    PeopleExtendedComponent,
    ProjectsExtendedComponent,
    CommunitiesExtendedComponent,
    EventsExtendedComponent,
    UserPostsComponent,
    UserHighlightsComponent,
    HomePartnersComponent,
    DiscoveryCatalogComponent,
    CommunityCardComponent,
    CompanyCardComponent,
    PersonCardComponent,
    ProjectPostCardComponent,
    EventsCardComponent,
    JobCardComponent,
    InternCardComponent,
    ServiceErrorComponent,
    MessagesMainComponent,
    MessagesBoxComponent,
    MessagesMenuComponent,
    ChatBoxComponent,
    MessageItemComponent,
    MessageUserComponent,
    MessageOtherComponent,
    NewChatComponent,
    WriteMessageComponent,
    PersonCardBigComponent,
    PersonCardSmallComponent,
    CompanyCardBigComponent,
    CompanyCardSmallComponent,
    CommunityCardBigComponent,
    CommunityCardSmallComponent,
    DiscoverySpecialForYouComponent,
    DiscoveryTrendsComponent,
    DiscoveryCategoriesComponent,
    DiscoveryFollowedComponent,
    DiscoverySavedComponent,
    GenericCardComponent,
    CategoriesSearchComponent,
    PageComponent,
    CheckoutComponent,
    PurchaseEventTicketComponent,
    ProjectDetailsComponent,
    PostPageComponent,
    MessageImagesComponent,
    MessageFilePreviewComponent,
    MessageChatDetailsComponent,
    SearchJobPostingComponent,
    JobPostingHomeComponent,
    JobPostingApplicationComponent,
    SearchPersonCardComponent,
    JobPostingCardSmallComponent,
    JobPostingLoaderComponent,
    DiscoveryLoadingWithTitlesComponent,
    DiscoveryLoadingRowComponent,
    UserInterestsComponent,
    NotFoundComponent,
    UserProfileHeaderComponent,
    UserAboutComponent,
    UserCommunitiesComponent,
    UserProjectsComponent,
    UserSkillsComponent,
    UserFeaturedComponent,
    UserFollowingComponent,
    UserExtrasectionComponent,
    EditProfileBottomSheetComponent,
    UsertypefilterPipe,
    MessageRoomMembersComponent,
    MessageMemberDetailsComponent,
    MessageVideoComponent,
    MessageChatFilesComponent,
    PersonalCalendarComponent,
    PersonalCalendarListComponent,
    PersonalCalendarWeekComponent,
    PersonalCalendarMonthComponent,
    IsRoomEditablePipe,
    MakeMessageAdminButtonTextPipe,
    HeroTextAnimationComponent,
    BlogPostsComponent,
    FlippingCardsComponent,
    FooterComponent,
    PopularTodayComponent,
    PartnersComponent
  ],

  imports: [
    MaterialDesignModule,
    InViewportModule,
    CommonModule,
    BrowserModule,
    LayoutModule,
    SiteRoutingModule,
    AppCommonModule,
    YoumightknowComponent,
    FormsModule,
    ReactiveFormsModule,
    UploadModule,
    ErrorPageModule,
    ProfilesModule,
    PostsModule,
    TextEditorModule,
    BlogModule,
    JobPostingModule,
    NgxSkeletonLoaderModule,
    ProfileEditingModule,
    CalendarModule,
    
],

  exports: [],
})
export class SiteModule {}

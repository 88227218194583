<div class="container">
  <mat-card appearance="outlined" class="education card profile-card">
    <div class="title">
      <h2 *ngIf="type === 'education'">Eğitim</h2>
      <h2 *ngIf="type === 'experience'">Deneyim</h2>
      <h2 *ngIf="type === 'certificate'">Sertifikalar</h2>
      <div>
        <div>
          <button
            mat-button
            color="primary"
            *ngIf="isMe"
            (click)="openBottomSheet()"
          >
            <mat-icon>edit</mat-icon> Düzenle
          </button>
        </div>
      </div>
    </div>
    <div class="education-area">
      <div class="education-content" *ngFor="let section of extraSections">
        <div class="top">
          <div class="avatar-container">
            <span class="avatar-icon" *ngIf="!getAvatar(section)">
              <mat-icon *ngIf="type === 'education'">school</mat-icon>
              <mat-icon *ngIf="type === 'experience'">work</mat-icon>
              <mat-icon *ngIf="type === 'certificate'"
                >insert_drive_file</mat-icon
              >
            </span>
            <media-image
              *ngIf="getAvatar(section)"
              appereance="rounded"
              objectFit="cover"
              width="48"
              height="48"
              [src]="getAvatar(section)"
            ></media-image>
            <div class="description-text">
              <h3 class="education-title">{{ section.organization }}</h3>
              <p class="name">
                {{ section.name }}
              </p>
              <p class="content">
                {{ section.startDate | date : "YYYY" }} -
                {{ section.endDate | date : "YYYY" }}
              </p>
              <!-- {{ section.startDate }} •
       -->
            </div>
          </div>
        </div>
        <div class="mid">
          <p class="description">{{ section.description }}</p>
        </div>
        <div
          class="talents"
          *ngIf="section.talents && section.talents.length > 0"
        >
          <h4 class="talents-header mat-subtitle-2">Edinilen Yetenekler</h4>
          <mat-chip-listbox aria-label="Talents">
            <mat-chip-option
              *ngFor="let talent of section.talents"
              class="colored-chip"
              >{{ talent }}</mat-chip-option
            >
          </mat-chip-listbox>
        </div>

        <div class="communities">
          <ng-container
            *ngIf="section.communities && section.communities.length > 0"
          >
            <div class="communities-header">
              <h4>Topluluklar ve Faaliyetler</h4>
              <button mat-button color="primary">Hepsini Gör</button>
            </div>
            <div class="row">
              <div
                class="col-sm-4"
                *ngFor="let community of section.communities"
              >
                <div class="community">
                  <img class="community-image" [src]="community.image" alt="" />
                  <div class="community-info">
                    <h5 class="community-name">{{ community.name }}</h5>
                    <p class="community-role">{{ community.role }}</p>
                    <p class="community-date">Mayıs 2022 - Günümüz</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div
          class="featured"
          *ngIf="section.featuredContent && section.featuredContent.length > 0"
        >
          <h4 class="featured-header">Öne Çıkanlar</h4>
          <div class="row">
            <div class="col-sm-4" *ngFor="let item of section.featured">
              <profile-content [item]="item"></profile-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { MessageMember } from 'src/app/models/messages/message-member';
import { MessagingService } from '../../messaging.service';

@Component({
  selector: 'message-member-details',
  templateUrl: './message-member-details.component.html',
  styleUrls: ['./message-member-details.component.scss'],
})
export class MessageMemberDetailsComponent implements OnInit {
  constructor(public messaging: MessagingService, public auth: AuthService) {}

  public receiver: MessageMember;

  ngOnInit(): void {
    this.receiver = this.getReceiver();
  }

  public getReceiver(): MessageMember {
    const room = this.messaging.currentRoom$.value;
    return room.members.find(
      (member: MessageMember) =>
        member.memberId !== this.auth.selectedMember.profileId
    );
  }

  public close() {}
}

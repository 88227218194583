<form [formGroup]="form">
  <div class="inputs row">
    <div class="input col-sm-4">
      <mat-form-field appearance="outline" class="ff">
        <mat-label>Arama</mat-label>
        <input matInput formControlName="query" />
      </mat-form-field>
    </div>
    <div class="input col-sm-4">
      <tag-input [tags]="tags"></tag-input>
    </div>
    <div class="input col-sm-4">
      <mat-form-field appearance="outline" class="ff">
        <mat-label>Konum</mat-label>
        <input matInput formControlName="location" />
      </mat-form-field>
    </div>
  </div>
  <div class="row main">
    <div class="col-sm-4 left-panel">
      <ng-container *ngIf="results$ | async as results">
        <mat-card class="left-card">
          <div
            class="job-card active"
            *ngFor="let job of results"
            (click)="selectedJobPosting = job"
          >
            <div class="top">
              <div class="image">
                <img [src]="job.creator.avatar" alt="avatar" />
              </div>
              <div class="name-company">
                <h2 [innerHTML]="job.title"></h2>
                <p>{{ job.creator.display_name }}</p>
              </div>
            </div>
            <div class="bottom">
              <p>
                Öne Çıkarılan İlan • {{ job.startDate | dateAgo }} Yayınlandı
              </p>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </div>
    <div class="col-sm-8">
      <job-posting-details
        *ngIf="selectedJobPosting"
        [jobPosting]="selectedJobPosting"
      ></job-posting-details>
    </div>
  </div>
</form>

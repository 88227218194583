<mat-card
  appearance="outlined"
  class="project-card z-elevation-0"
  [class.dark]="dark"
>
  <img class="project-image" [src]="getImage() | imageFile" alt="" />
  <div class="project-details">
    <h3>{{ project.name }}</h3>
    <div class="workers-area">
      <div class="workers">
        <img
          class="worker"
          *ngFor="let worker of project.participants; let i = index"
          [src]="worker.user.avatar"
          alt=""
          [style.z-index]="3 - i"
        />
        <div *ngIf="project.participants && project.participants.length > 3" class="worker more-worker" [style.z-index]="0">+{{project.participants.length - 3}}</div>
      </div>
      <div class="workers-text">
        <p>{{ getParticipantText()}}</p>
      </div>
    </div>
    <p class="status">{{ project.startDate | dateLocale }} • Devam Ediyor</p>
    <div class="description">
      <p [innerHTML]="project.headnote"></p>
    </div>
    <div class="actions row">
      <div class="col-sm">
        <button
          routerLink="/project/{{ project.id }}"
          style="width: 100%"
          mat-flat-button
          color="primary"
          class="action mat-elevation-z0"
        >
          Detaylar
        </button>
      </div>
      <div class="col-sm" *ngIf="false">
        <button
          style="width: 100%"
          mat-flat-button
          color="accent"
          class="action mat-elevation-z0 accent-button"
        >
          Projeden Ayrıl
        </button>
      </div>
    </div>
  </div>
</mat-card>

import { Component } from '@angular/core';

@Component({
  selector: 'flipping-cards',
  templateUrl: './flipping-cards.component.html',
  styleUrls: ['./flipping-cards.component.scss']
})
export class FlippingCardsComponent {

}

import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '@common/services/projects.service';
import { map } from 'rxjs';

@Component({
  selector: 'projects-from-friends',
  templateUrl: './projects-from-friends.component.html',
  styleUrls: ['./projects-from-friends.component.scss'],
})
export class ProjectsFromFriendsComponent implements OnInit {
  constructor(public projectsService: ProjectsService) {}

  public projects$ = this.projectsService
    .getProjectsFromFriends()
    .pipe(map((response) => response.data));

  ngOnInit(): void {}

  public projects = [
    {
      image: 'assets/img/placeholder.png',
      name: 'AlecTED Otonom Araç Projesi',
      workers: [
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
      ],
    },
    {
      image: 'assets/img/placeholder.png',
      name: 'AlecTED Otonom Araç Projesi',
      workers: [
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
        {
          image: 'assets/img/placeholder.png',
          name: 'Elif Kemertaş',
        },
      ],
    },
  ];
}

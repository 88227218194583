import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FollowersService } from '@common/services/followers.service';
import { Profile } from 'src/app/models/profile';
import { EditProfileBottomSheetComponent } from '../edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';

@Component({
  selector: 'user-about',
  templateUrl: './user-about.component.html',
  styleUrls: ['./user-about.component.scss'],
})
export class UserAboutComponent {
  @Input() user: Profile;
  @Input() isMe: boolean;

  constructor(public _bottomSheet: MatBottomSheet) {}

  openBottomSheet(): void {
    this._bottomSheet.open(EditProfileBottomSheetComponent, {
      data: {
        selectedPart: 'about',
        profile: this.user,
      },
    });
  }
}

import { Component, Input } from '@angular/core';
import { Community } from 'src/app/models/community';
import { Company } from 'src/app/models/company';
import { DiscorveryItem } from 'src/app/models/discovery/item';
import { Event } from 'src/app/models/event';
import { JobPosting } from 'src/app/models/job-posting';
import { Project } from 'src/app/models/project';
import { User } from 'src/app/models/user';

@Component({
  selector: 'generic-card',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent {
  @Input() data: DiscorveryItem;

  public get asPerson() {
    return this.data.data as User;
  }

  public get asCompany() {
    return this.data.data as Company;
  }

  public get asEvent() {
    return this.data.data as Event;
  }

  public get asJobPosting() {
    return this.data.data as JobPosting;
  }

  public get asProject() {
    return this.data.data as Project;
  }

  public get asCommunity() {
    return this.data.data as Community;
  }
}

import { Toast } from '@common/core/toast/toast/toast.service';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, switchMap } from 'rxjs';
import { MessageMember } from '../../../../models/messages/message-member';
import { MessageRoom } from '../../../../models/messages/message-room';
import { MessagingService } from '../../messaging.service';

@UntilDestroy()
@Component({
  selector: 'message-room-members',
  templateUrl: './message-room-members.component.html',
  styleUrls: ['./message-room-members.component.scss'],
})
export class MessageRoomMembersComponent implements OnInit {
  constructor(public messaging: MessagingService, public toast: Toast) {}

  public members$ = new BehaviorSubject<MessageMember[]>(null);
  public room: MessageRoom;

  ngOnInit(): void {
    this.messaging.currentRoom$
      .pipe(switchMap((room) => this.messaging.getMembers(room.id)))
      .pipe(untilDestroyed(this))
      .subscribe((members) => {
        console.log('aa', members);

        this.members$.next(members);
      });
    this.messaging.currentRoom$.pipe(untilDestroyed(this)).subscribe((room) => {
      this.room = room;
    });
  }

  public openChat(member: MessageMember) {
    this.messaging.openRoomByProfile(member.member).subscribe();
  }

  public toggleIsAdmin(member: MessageMember) {
    this.messaging.toggleIsAdmin(member.member.id, this.room.id).subscribe({
      next: (room) => {
        if (
          room &&
          room.admins.find((admin) => admin.memberId == member.member.id)
        ) {
          this.toast.open(member.member.display_name + ' artık yönetici.');
        } else {
          this.toast.open(
            member.member.display_name + ' artık yönetici değil.'
          );
        }
        this.room = room;
      },
      error: () => {
        this.toast.open('Adminlik durumu değiştirilirken bir hata oluştu');
      },
    });
  }

  public removeMember(member: MessageMember) {
    this.messaging.removeMember(member.member.id, this.room.id).subscribe({
      next: () => {
        this.toast.open('Üye çıkarıldı.');
        this.room.members = this.room.members.filter(
          (m) => m.member.id != member.member.id
        );
        
      },
      error: () => {
        this.toast.open('Üye çıkarılırken bir hata oluştu');
      },
    });
  }
}

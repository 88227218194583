import { Document } from '../document';
import { Profile } from '../profile';
import { MessageMember } from './message-member';

export enum RoomType {
  DIRECT_MESSAGE = 'DIRECT_MESSAGE',
  GROUP = 'GROUP',
  CHANNEL = 'CHANNEL',
  ORGANIZATION = 'ORGANIZATION',
  OTHER = 'OTHER',
}

export interface MessageRoom extends Document {
  name?: string;
  description?: string;
  isPrivate?: boolean;
  roomType?: RoomType;
  image?: string;
  imageFile?: File;
  createdByType?: string;
  createdById?: string;
  createdBy?: Profile; // Change from Ref<Profile> to Profile
  members?: MessageMember[]; // Change from Ref<MessageMember>[] to MessageMember[]
  admins: MessageMember[]; // Change from Ref<MessageMember>[] to MessageMember[]
}

<main-content>
  <div class="container">
    <h4>Etkinlikler</h4>
    <mat-tab-group mat-stretch-tabs="false">
      <mat-tab label="Önerilen Etkinlikler">
        <div class="suggested-area" *ngIf="paginator.datas.get(key1)">
          <div class="d-flex justify-content-between">
            <h2>Önerilen Etkinlikler</h2>
          </div>
          <div class="row align-items-stretch">
            <div class="col-md-4 mt-4" *ngFor="let event of paginator.datas.get(key1)">
              <event-card [fullHeight]="true" [event]="event"></event-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Geçmiş Etkinlikleriniz">
        <div class="past-area" *ngIf="paginator.datas.get(key1)">
          <div class="d-flex justify-content-between flex-wrap">
            <h2>Geçmiş Etkinlikleriniz</h2>
            <paginator [key]="key1"></paginator>
          </div>
          <div class="row  align-items-stretch">
            <div class="col-md-4 mt-4" *ngFor="let event of paginator.datas.get(key1)">
              <event-card [fullHeight]="true" [event]="event"></event-card>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ng-template #loading>
      <p>Loading...</p>
    </ng-template>
    <p *ngIf="error">{{ error }}</p>
  </div>
</main-content>
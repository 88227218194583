import { Component, OnInit } from '@angular/core';

interface PopularCard {
  image: string;
  title: string;
  subtitle: string;
  content: string;
  like: string;
  comment: number;
}

@Component({
  selector: 'popular-today',
  templateUrl: './popular-today.component.html',
  styleUrls: ['./popular-today.component.scss']
})
export class PopularTodayComponent implements OnInit {
  
  popularCards! : PopularCard[];

  ngOnInit(): void {
    this.popularCards = [
      {
        image: '/assets/img/avatar1.png',
        title: 'Hasan Can Kaya',
        subtitle: 'ODTÜ EEE ⚡',
        content: 'Tübitak projemizin başarıyla kabul aldığını duyurmaktan gurur duyuyorum! Bu süreçte bize katkılarını sunan tüm hocalarımıza ve takım arkadaşım @Önder Yıldırım’a teşekkürlerimi sunarım.',
        like: '1.2B',
        comment: 103
      },
      {
        image: '/assets/img/avatar2.png',
        title: 'Microsoft',
        subtitle: 'Because impact matters',
        content: 'AIs powering major advances in business. Watch a free webinar on how to drive next-level insights with machine learning, advanced analytics, and business intelligence: http://msft.it/6046iGK0n',
        like: '1.5B',
        comment: 103
      },
      {
        image: '',
        title: 'Akdeniz Üniversitesi',
        subtitle: 'Eğitimde ve Bilimde Öncü Üniversite',
        content: 'Akdeniz Üniversitesi, THE 2024 Genç Üniversiteler Sıralamasında dünya genelinde 501+ bandından 251-300 bandına yükselirken Türkiye’de ise devlet üniversiteleri arasında birinci oldu.',
        like: '1.5B',
        comment: 103
      },
      {
        image: '/assets/img/avatar2.png',
        title: 'Felya Varol',
        subtitle: 'İnsan Kaynakları @ Turkcell',
        content: 'Uzun süre bir süreç sonrası GNÇYTNK  2024 Staj başvuru sürecini tamamlamış bulunuyoruz, bir sonraki aşamaya geçen arkadaşlarımızı tebrik ediyor ve sizleri aramızda görmek için sabırsızlanıyoruz!',
        like: '1.5B',
        comment: 103
      }
    ]
  }
}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CalendarService } from '@common/services/calendar.service';
import { CalendarMonthComponent } from 'src/app/admin/calendar/calendar-month/calendar-month.component';

@Component({
  selector: 'personal-calendar-month',
  templateUrl: './personal-calendar-month.component.html',
  styleUrls: ['./personal-calendar-month.component.scss', '../panel.scss'],
})
export class PersonalCalendarMonthComponent implements AfterViewInit {
  constructor(public calendarService: CalendarService) {}
  @ViewChild('calendar') calendar: CalendarMonthComponent;

  ngAfterViewInit(): void {
    this.calendar.dataObserver$.next((date) => {
      return this.calendarService.getMonth(date);
    });
  }
}

<mat-card
  appearance="outlined"
  class="community hover-effect-card"
  [routerLink]="['/community', community.id]"
>
  <div class="d-flex community">
    <img [src]="community.avatar" alt="" class="avatar" />
    <div>
      <h3 class="name">{{ community.name }}</h3>
      <p class="short-desc">{{ community.summary }}</p>
      <p class="details">Okul Topluluğu • Ankara, Türkiye</p>
      <div class="d-flex">
        <p>TED Üniversitesi kurumu altında</p>
      </div>
      <br />
      <follow-button [profile]="community" type="Comunity"></follow-button>
    </div>
  </div>
</mat-card>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceHtmlTags',
  standalone: true,
})
export class ReplaceHtmlTagsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value?.replace(/<[^>]*>/g, '');
  }
}

import { Component, Input } from '@angular/core';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'company-card-small',
  templateUrl: './company-card-small.component.html',
  styleUrls: ['./company-card-small.component.scss']
})
export class CompanyCardSmallComponent {

  @Input() company: Company;

}

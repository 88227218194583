<ng-container *ngIf="!controller.followedLoading">
  <div class="highlights">
    <div class="texts">
      <h3>Topluluklarımdan Projeler</h3>
      <p>
        Takip ettiğiniz veya üye olduğunuz topluluklardan keşfedilecek projeler.
      </p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item
          [itemSize]="33.33"
          *ngFor="let item of data.projectsFromCommunities"
        >
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>
  <info-card
    title="Gelecek ve Geçmiş Projelerinize Erişin"
    icon="people"
    text="Geçmişte bulunduğunuz ve gelecekte bulunacağınız projeleri Projeler sayfasından kontrol edin. Katalog'da beğendiğiniz "
    buttonText="Projeleri Keşfet"
    buttonColor="#4B53F2"
    class="info-card"
  >
  </info-card>

  <div class="highlights">
    <div class="texts">
      <h3>Takip Edilenlerden İlanlar</h3>
      <p>Takip ettiğiniz şirket ve kurumlardan yeni staj ilanları.</p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item
          [itemSize]="33.33"
          *ngFor="let item of data.followedJobPosts"
        >
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>

  <div class="highlights">
    <div class="texts">
      <h3>Takip Edilenlerden, Takip Edilesi Profiller</h3>
      <p>
        Takip ettiklerinizin, takipte olduğu şirketler, kurumlar, topluluklar ve
        kişiler.
      </p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item
          [itemSize]="33.33"
          *ngFor="let item of data.eventsFromFollowed"
        >
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>

  <div class="row" style="width: 100%; margin-top: 5%; padding-left: 2%">
    <div class="col-12" style="padding: 0">
      <h2>Favori Kategorileriniz</h2>
    </div>
    <mat-chip-option
      class="active"
      *ngFor="let item of data.favoriteCategories"
    >
      <span class="chip-text">Onaylılar</span>
    </mat-chip-option>
  </div>

  <div class="highlights">
    <div class="texts">
      <h3>Takip Edilenlerden Etkinlikler</h3>
      <p>Takip ettiklerinizin düzenledikleri etkinlikler.</p>
    </div>

    <div class="container-fluid">
      <slider>
        <slider-item
          [itemSize]="33.33"
          *ngFor="let item of data.eventsFromFollowed"
        >
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="controller.followedLoading">
  <discovery-loading-with-titles></discovery-loading-with-titles>
</ng-container>

import { Component, Input } from '@angular/core';
import { JobPosting } from 'src/app/models/job-posting';

@Component({
  selector: 'job-posting-card-small',
  templateUrl: './job-posting-card-small.component.html',
  styleUrls: ['./job-posting-card-small.component.scss']
})
export class JobPostingCardSmallComponent {
  @Input() item: JobPosting;
}

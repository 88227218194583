import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  constructor(
    public auth:AuthService
  ) {}

  public rxtest = new BehaviorSubject("test")

  ngOnInit(): void {
    setTimeout(() => {
      this.rxtest.next("test2")
    } , 2000)
  }
}

import { Component, Input } from '@angular/core';
import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'user-posts',
  templateUrl: './user-posts.component.html',
  styleUrls: ['./user-posts.component.scss']
})
export class UserPostsComponent {
  @Input() posts : any[];
  @Input() link : string[];
  @Input() isMe : boolean;
  constructor() {}

  ngOnInit(): void {}

  

  

 
  
}

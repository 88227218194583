<main-content>
  <ng-container *ngIf="!loading">
    <div class="header">
      <div class="container">
        <h1>Etkinlikler</h1>
        <div class="content">
          <div>
            <h2>Yaklaşan Etkinliklerimiz</h2>
          </div>
          <div class="joined">
            <slider *ngIf="events.upcoming?.length; else emptyEvent">
              <slider-item
                [itemSize]="50"
                *ngFor="let event of events.upcoming"
              >
                <event-card [event]="event"></event-card>
              </slider-item>
            </slider>
            <ng-template #emptyEvent>
              <div class="row">
                <div class="col-md-6">
                  <media-image
                    src="assets/img/events-empty.png"
                    style="width: 100%"
                    [fill]="true"
                    [priority]="true"
                  ></media-image>
                </div>
                <div class="col-md-6">
                  <h2>Yaklaşan etkinlik yok</h2>
                  <p>Tüm etkinlikleri keşif kataloğu ile keşfedebilirsiniz.</p>
                  <a
                    [routerLink]="['/discovery-catalog']"
                    routerLinkActive="router-link-active"
                    mat-button
                    color="primary"
                    >Kataloğa Git</a
                  >
                </div>
              </div>
            </ng-template>
          </div>

          <info-card
            [routerLink]="['/discovery-catalog']"
            routerLinkActive="router-link-active"
            title="Toplulukların etkinliklerini keşfet"
            icon="rocket-launch"
            text="Üyesi olduğun toplulukların yeni etkinlikleri keşfet ve ilgini çeken etkinlikleri düzenleyen yeni toplulukları takip et."
            buttonText="Etkinlikleri Keşfet"
          ></info-card>

          <div class="suggested-area">
            <div class="d-flex justify-content-between">
              <h2>Önerilen Etkinlikler</h2>
              <a
                [routerLink]="['/events-extended']"
                routerLinkActive="router-link-active"
                mat-button
                color="primary"
                >Hepsini gör</a
              >
            </div>
            <div class="joined">
              <div class="row align-items-stretch">
                <div
                  class="col-sm-6 col-lg-4"
                  *ngFor="let event of events.suggestions"
                >
                  <event-card [fullHeight]="true" [event]="event"></event-card>
                </div>
              </div>
            </div>
          </div>

          <div class="past-area">
            <div class="d-flex justify-content-between">
              <h2>Geçmiş Etkinlikleriniz</h2>
              <a
                [routerLink]="['/events-extended']"
                routerLinkActive="router-link-active"
                mat-button
                color="primary"
                >Hepsini gör</a
              >
            </div>
            <div class="joined">
              <div class="row align-items-stretch">
                <div class="col-md-4 mt-4" *ngFor="let event of events.past">
                  <event-card [fullHeight]="true" [event]="event"></event-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="container mt-4">
      <div class="mt-4">
        <ngx-skeleton-loader
          [theme]="{ width: '300px', height: '30px' }"
        ></ngx-skeleton-loader>

        <div class="row">
          <div class="col-md-4" *ngFor="let i of [1, 2, 3]">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '500px' }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <ngx-skeleton-loader [theme]="{width: '100%', height : '200px'}"></ngx-skeleton-loader>
      </div>

      <div class="mt-4">
        <ngx-skeleton-loader
          [theme]="{ width: '300px', height: '30px' }"
        ></ngx-skeleton-loader>

        <div class="row">
          <div class="col-md-4" *ngFor="let i of [1, 2, 3]">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '600px' }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</main-content>

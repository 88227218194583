import { Component, Input } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { MessagingService } from '../../messaging.service';
import { RoomType } from 'src/app/models/messages/message-room';
import { map } from 'rxjs';

@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
})
export class MessageItemComponent {

  @Input() message: any;

  constructor(public messaging: MessagingService, private auth: AuthService) {}

  public userId = this.auth.user?.id;

  public modifiedRoom$ = this.messaging.currentRoom$.pipe(map(room => {
    if (room && room.roomType == RoomType.DIRECT_MESSAGE) {
      
  
  

      
    }
  }))
}

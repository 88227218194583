import { Component } from '@angular/core';
import {
  DiscoveryCatalogService,
  SAVED_FILTER_TYPE,
} from '@common/services/discovery-catalog.service';
import { BehaviorSubject, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'discovery-saved',
  templateUrl: './discovery-saved.component.html',
  styleUrls: ['./discovery-saved.component.scss'],
})
export class DiscoverySavedComponent {
  public loadingData = false;
  constructor(public discoveryCatalogService: DiscoveryCatalogService) {}
  public types: {
    icon: string;
    type: SAVED_FILTER_TYPE;
    name: string;
  }[] = [
    {
      type: SAVED_FILTER_TYPE.ALL,
      icon: 'bookmark',
      name: 'Hepsi',
    },
    {
      type: SAVED_FILTER_TYPE.JOB_POSTING,
      icon: 'work',
      name: 'İş İlanları',
    },
    {
      type: SAVED_FILTER_TYPE.COMPANY,
      icon: 'business',
      name: 'Şirketler',
    },
    {
      type: SAVED_FILTER_TYPE.USER,
      icon: 'person',
      name: 'Kişiler',
    },
    {
      type: SAVED_FILTER_TYPE.COMMUNITY,
      icon: 'group',
      name: 'Topluluklar',
    },
    {
      type: SAVED_FILTER_TYPE.EVENT,
      icon: 'event',
      name: 'Etkinlikler',
    },
    {
      type: SAVED_FILTER_TYPE.UNIVERSITY,
      icon: 'school',
      name: 'Kurumlar',
    },
    {
      type: SAVED_FILTER_TYPE.PROJECT,
      icon: 'rocket_launch',
      name: 'Projeler',
    },
  ];

  public savedFilterType$ = new BehaviorSubject<SAVED_FILTER_TYPE>(
    SAVED_FILTER_TYPE.ALL
  );

  public data$ = this.savedFilterType$
    .pipe(
      switchMap((type) =>
        this.discoveryCatalogService.getSaved(type).pipe(map((res) => res.data))
      )
    )
    .pipe(
      tap(() => {
        this.loadingData = false;
      })
    );

  public selectType(type: SAVED_FILTER_TYPE) {
    this.loadingData = true;
    this.savedFilterType$.next(type);
  }
}

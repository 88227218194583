<main-content>
  <ng-container *ngIf="companies && !loading">
    <div class="header">
      <div class="container">
        <h1>Şirketler</h1>
        <div class="content">
          @if (companies?.followed?.length) {
          <div class="d-flex justify-content-between">
            <h2>Takip Ettiklerim</h2>

            <a
              [routerLink]="['/companies-extended']"
              routerLinkActive="router-link-active"
              mat-button
              color="primary"
              >Hepsini gör</a
            >
          </div>
          <div class="joined">
            <slider>
              <slider-item *ngFor="let company of companies.followed">
                <company-card-big [company]="company"></company-card-big>
              </slider-item>
            </slider>
          </div>
          } @else {
          <div class="row">
            <div class="col-md-6" style="padding: 0 5%">
              <media-image
                src="assets/img/community-2.png"
                [fill]="true"
                [priority]="true"
              ></media-image>
            </div>
            <div class="col-md-6">
              <h2>Henüz bir şirketi takip etmiyorsunuz</h2>
              <p>Tüm şirketleri keşif kataloğu ile keşfedebilirsiniz.</p>
              <a
                [routerLink]="['/discovery-catalog']"
                routerLinkActive="router-link-active"
                mat-button
                color="primary"
                >Kataloğa Git</a
              >
            </div>
          </div>
          }

          <info-card
            [routerLink]="['/people']"
            routerLinkActive="router-link-active"
            title="Yeni bağlantılar kurun"
            icon="person_add"
            text="Kişiler sayfasında ortak kurumlarda okuduğunuz, çalıştığınız, ilgi alanlarına sahip olduğunuz insanları bulun, bağlantı kurun."
            buttonText="Kişileri Keşfet"
          ></info-card>
          <div class="following-area">
            <h2>En çok takip edilenler</h2>
            <div class="followed row">
              <div
                class="col-md-4 col-sm-6"
                *ngFor="let company of companies.mostFollowed"
              >
                <company-card-small [company]="company"></company-card-small>
              </div>
            </div>
          </div>
          <div class="following-area">
            <h2>Takip edilmeye değer</h2>
            <div class="followed row">
              <div
                class="col-md-4 col-sm-6"
                *ngFor="let company of companies.suggestions"
              >
                <company-card-small [company]="company"></company-card-small>
              </div>
            </div>
          </div>
          <info-card
            [routerLink]="['/communities']"
            routerLinkActive="router-link-active"
            icon="group"
            title="Yeni Topluluklar Bulun"
            text="Farklı projeler yapan, yetenekli öğrencilerin bulunduğu toplulukları keşfedin, bir parçası olun ve devrim yaratacak yeni bir projenin öncüsü olun."
            buttonText="Toplulukları Keşfet"
          ></info-card>
          <div class="following-area">
            <h2>Çevrendekiler</h2>
            <div class="followed row">
              <div
                class="col-md-4 col-sm-6"
                *ngFor="let company of companies.around"
              >
                <company-card-small [company]="company"></company-card-small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="container mt-4">
      <div class="mt-4">
        <ngx-skeleton-loader
          [theme]="{ width: '300px', height: '30px' }"
        ></ngx-skeleton-loader>

        <div class="row">
          <div class="col-md-3" *ngFor="let i of [1, 2, 3, 4]">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '200px' }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <ngx-skeleton-loader
          [theme]="{ width: '100%', height: '150px' }"
        ></ngx-skeleton-loader>
      </div>

      <div class="mt-4">
        <ngx-skeleton-loader
          [theme]="{ width: '300px', height: '30px' }"
        ></ngx-skeleton-loader>

        <div class="row">
          <div class="col-md-3" *ngFor="let i of [1, 2, 3, 4]">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '200px' }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</main-content>

import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Pagination } from 'src/app/models/pagination';
import { User } from 'src/app/models/user';

const BASE = 'people';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  constructor(private http: AppHttpClient) {}

  public getIndex() {
    return this.http.get<
      StandartResponse<{
        following: User[];
        suggestions: User[];
        followingYou: User[];
      }>
    >(BASE);
  }

  public getStudents() {
    return this.http.get<
      StandartResponse<{
        suggestions: User[];
      }>
    >(BASE + '/students');
  }

  public getProfessionals() {
    return this.http.get<
      StandartResponse<{
        suggestions: User[];
      }>
    >(BASE + '/professionals');
  }

  public getFollowing() {
    return this.http.get<StandartResponse<Pagination<User>>>(
      `${BASE}/following`
    );
  }

  public getSuggestions() {
    return this.http.get<StandartResponse<Pagination<User>>>(
      `${BASE}/suggestions`
    );
  }

  public getFollowers() {
    return this.http.get<StandartResponse<Pagination<User>>>(
      `${BASE}/followers`
    );
  }
}

import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'person-card-big',
  templateUrl: './person-card-big.component.html',
  styleUrls: ['./person-card-big.component.scss'],
})
export class PersonCardBigComponent {
  @Input() public person: User;
}

import { Component, OnInit } from '@angular/core';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { map } from 'rxjs';
import { DiscoveryCatalogControllerService } from '../../discovery-catalog-controller.service';

@Component({
  selector: 'discovery-trends',
  templateUrl: './discovery-trends.component.html',
  styleUrls: ['./discovery-trends.component.scss'],
})
export class DiscoveryTrendsComponent implements OnInit {
  constructor(
    public discoveryCatalogService: DiscoveryCatalogService,
    public controller: DiscoveryCatalogControllerService
  ) {}

  ngOnInit() {
    if (!this.controller.trendsData) {
      this.discoveryCatalogService
        .getTrends()
        .pipe(map((res) => res.data))
        .subscribe({
          next: (data) => {
            this.controller.trendsData = data;
            this.controller.trendsLoading = false;
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'service-error',
  templateUrl: './service-error.component.html',
  styleUrls: ['./service-error.component.scss']
})
export class ServiceErrorComponent {

}

import { Component } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { CommunitiesService } from '@common/services/communities.service';

@Component({
  selector: 'communities-extended',
  templateUrl: './communities-extended.component.html',
  styleUrls: ['./communities-extended.component.scss']
})
export class CommunitiesExtendedComponent {
  constructor(public auth:AuthService, public communities:CommunitiesService) { }

  public following$ = this.communities.getFollowing();
  public joined$ = this.communities.getJoined();

  
  ngOnInit(): void {
    
  }
}

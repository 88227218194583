import { Injectable } from '@angular/core';
import { DiscorveryItem } from 'src/app/models/discovery/item';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryCatalogControllerService {
 
  constructor() {}

  public searchCategoriesData: {
    trending: DiscorveryItem[];
    partners: DiscorveryItem[];
    newCompanies: DiscorveryItem[];
    localCountries: DiscorveryItem[];
    companiesWithJobPostings: DiscorveryItem[];
  } = null;

  public searchCategoriesLoading = true;

  public trendsData: {
    trending: DiscorveryItem[];
  };

  public trendsLoading = true;

  public specialData: {
    interestedIn: DiscorveryItem[];
    highGrades: DiscorveryItem[];
    popularInYourLocation: DiscorveryItem[];
  };
  public specialLoading: boolean = true;

  public followedData: { projectsFromCommunities: DiscorveryItem[]; followedJobPosts: DiscorveryItem[]; projectsFromFollowed: DiscorveryItem[]; favoriteCategories: DiscorveryItem[]; eventsFromFollowed: DiscorveryItem[]; };
  public followedLoading: boolean = true;
}

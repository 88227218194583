import { Component, Input } from '@angular/core';
import { Community } from 'src/app/models/community';

@Component({
  selector: 'community-card-big',
  templateUrl: './community-card-big.component.html',
  styleUrls: ['./community-card-big.component.scss'],
})
export class CommunityCardBigComponent {
  @Input() community: Community;
}

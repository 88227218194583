<main-content>
  <div class="container" style="min-height: 84vh; align-items: start">
    <div class="row" style="width: 100%">
      <div style="width: 100%">
        <div class="d-flex flex-wrap justify-content-between">
          <div class="header">Keşif Kataloğu</div>
          <div>
            <button mat-flat-button color="primary" (click)="openInterests()">
              <mat-icon>auto_awesome</mat-icon>İlgi Alanlarım
            </button>
          </div>
        </div>
        <div
          class="tabs d-flex justify-content-between align-items-start"
          style="width: 100%"
        >
          <mat-tab-group
            style="width: 100%"
            [selectedIndex]="tabIndex$ | async"
          >
            <mat-tab label="Size Özel">
              <ng-template matTabContent>
                <discovery-special-for-you></discovery-special-for-you>
              </ng-template>
            </mat-tab>
            <mat-tab label="Trendler">
              <ng-template matTabContent>
                <discovery-trends></discovery-trends>
              </ng-template>
            </mat-tab>
            <mat-tab label="Kategoriler">
              <ng-template matTabContent>
                <discovery-categories></discovery-categories>
              </ng-template>
            </mat-tab>
            <mat-tab label="Takip Edilenler">
              <ng-template matTabContent>
                <discovery-followed></discovery-followed>
              </ng-template>
            </mat-tab>
            <mat-tab label="Kaydedilenler">
              <ng-template matTabContent>
                <discovery-saved></discovery-saved>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div
          class="selected-tags d-flex justify-content-between align-items-center"
          *ngIf="discoveryCatalogService.selectedTags | async as selectedTags"
        >
          <div class="content d-flex align-items-center">
            <h4>Seçilen Etiketler</h4>
            <div class="tags">
              <mat-chip-listbox>
                <mat-chip-option
                  class="selected"
                  *ngFor="let tag of selectedTags"
                  (click)="discoveryCatalogService.toggleTag(tag)"
                >
                  <span class="chip-text">{{ tag.name }}</span>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </div>
          <button
            *ngIf="selectedTagsBtoa$ | async as query"
            [routerLink]="['./']"
            [queryParams]="query"
            mat-flat-button
            color="primary"
          >
            Kategorilerde Ara
          </button>
        </div>
      </div>
    </div>
  </div>
</main-content>

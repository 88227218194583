<main-content>
  <div class="container">
    <h3 class="header">Topluluklar</h3>
    <mat-tab-group>
      <mat-tab label="Takip Ettiğim Topluluklar">
        <div class="joined">
          <div class="container">
            <div class="row" *ngIf="following$ | async as following">
              <div
                class="col-sm-6 col-lg-4 mt-2"
                *ngFor="let community of following.data"
              >
                <community-card-big
                  [community]="community"
                ></community-card-big>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Katıldığım Topluluklar">
        <div class="joined">
          <div class="container">
            <div class="row" *ngIf="joined$ | async as joined">
              <div
                class="col-sm-6 col-lg-4 mt-2"
                *ngFor="let community of joined.data"
              >
                <community-card-big
                  [community]="community"
                ></community-card-big>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</main-content>

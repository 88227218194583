import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FollowersService } from '@common/services/followers.service';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { Profile } from 'src/app/models/profile';
import { ExtraSection } from 'src/app/models/relations/profiles/extra-section';
import { EditProfileBottomSheetComponent } from '../edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';

@Component({
  selector: 'user-profile-header',
  templateUrl: './user-profile-header.component.html',
  styleUrls: ['./user-profile-header.component.scss'],
})
export class UserProfileHeaderComponent implements OnInit{
  @Input() user: Profile;
  @Input() isMe: boolean;
  constructor(
    public followerService: FollowersService,
    private _bottomSheet: MatBottomSheet,
    private profileEditing: ProfileEditingService
  ) {}

  public filtered: ExtraSection[] = [];
  ngOnInit(): void {
    this.filtered = this.getFiltered();
  }

  public colorArray = [
    '#615EFC',
    '#DC5F00',
    '#028391',
    '#FFA27F',
    '#C738BD',
    '#AF8F6F',
    '#D2649A',
    '#A91D3A',
    '#151515',
    '#03AED2',
    '#E2DFD0',
    '#41B06E',
  ];

  public getRandomColor(index: number) {
    return this.colorArray[index];
  }

  public getFiltered() {
    return this.user.extraSections.filter((item) => item.type != 'certificate');
  }

  openBottomSheet(): void {
    this._bottomSheet.open(EditProfileBottomSheetComponent, {
      data: {
        selectedPart: 'extraSection',
        profile: this.profileEditing.profile,
      },
    });
  }

  public getAvatar(section: ExtraSection) {
    if (section.company) {
      return section.company.avatar;
    }
    return null;
  }
}

import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../../messaging.service';
import { BehaviorSubject, map, filter } from 'rxjs';
import { RoomType } from 'src/app/models/messages/message-room';

@Component({
  selector: 'messages-menu',
  templateUrl: './messages-menu.component.html',
  styleUrls: ['./messages-menu.component.scss'],
})
export class MessagesMenuComponent implements OnInit {
  constructor(public messaging: MessagingService) {}

  public rooms$ = new BehaviorSubject<any[]>([]);
  public rooms = []
  public users = []
  public groups = []
  public organizations = []

  public _filterText = '';
  public get filterText() {
    return this._filterText;
  }

  public set filterText(value: string) {
    this._filterText = value;
    this.rooms = this.rooms$.value.filter(room => room?.name?.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase()))
    this.users = this.rooms.filter(room => room?.roomType === RoomType.DIRECT_MESSAGE)
    this.groups = this.rooms.filter(room => room?.roomType === RoomType.GROUP)
    this.organizations = this.rooms.filter(room => room?.roomType === RoomType.ORGANIZATION)
  }

  ngOnInit(): void {
    this.messaging.rooms$.subscribe((rooms) => {
      this.rooms$.next(rooms);
      console.log("rooms get",rooms)
    });

    this.messaging.onNewRoom().subscribe((room) => {
      this.rooms$.next([...this.rooms$.value, room]);
    });

    this.rooms$.subscribe((rooms) => {
      this.rooms = rooms.filter(room => room?.name.includes(this.filterText))
      this.users = this.rooms.filter(room => room?.roomType === RoomType.DIRECT_MESSAGE)
      this.groups = this.rooms.filter(room => room?.roomType === RoomType.GROUP)
      this.organizations = this.rooms.filter(room => room?.roomType === RoomType.ORGANIZATION)
    });
  }
}

<div
  class="selected-tags d-flex align-items-center flex-wrap gap-20"
  *ngIf="selectedTagsAtob$ | async as selectedTags"
>
  <button mat-icon-button [routerLink]="['./']">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div class="content d-flex align-items-center flex-wrap">
    <div class="tags">
      <mat-chip-listbox>
        <mat-chip-option class="selected" *ngFor="let tag of selectedTags">
          <span class="chip-text">{{ tag.name }}</span>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
</div>
<div class="highlights" *ngIf="!controller.searchCategoriesLoading">
  <div class="texts">
    <h3>Yükselişte olanlar</h3>
  </div>
  <div class="row">
    <div
      class="col-sm-4 mt-4"
      *ngFor="let item of controller.searchCategoriesData.trending"
    >
      <generic-card [data]="item"></generic-card>
    </div>
  </div>
</div>
<div class="loading" *ngIf="controller.searchCategoriesLoading">
  <discovery-loading-row></discovery-loading-row>
</div>

import { Component, Input } from '@angular/core';
import { Message } from 'src/app/models/messages/message';

@Component({
  selector: 'message-video',
  templateUrl: './message-video.component.html',
  styleUrls: ['./message-video.component.scss']
})
export class MessageVideoComponent {
  @Input() message:Message;
  constructor() {}
}

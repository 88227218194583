import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/models/community';

@Component({
  selector: 'search-communities',
  templateUrl: './search-communities.component.html',
  styleUrls: ['./search-communities.component.scss']
})
export class SearchCommunitiesComponent implements OnInit {

  @Input('items') items : Community[] = []

  constructor() { }

  ngOnInit(): void {
  }

}

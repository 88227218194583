<div class="container mt-2">
  <mat-card appearance="outlined" class="posts card profile-card">
    <div class="title">
      <h2>Öne Çıkarılan İçerikler</h2>
      <div>
        <div class="d-flex align-items-center" style="gap: 10px">
          <button
            mat-button
            color="primary"
            *ngIf="isMe"
            (click)="openBottomSheet()"
          >
            <mat-icon>edit</mat-icon> Düzenle
          </button>
          <div class="d-flex align-items-center" style="gap: 10px">
            <button
              mat-icon-button
              class="slider-button"
              (click)="slider.scrollPrev()"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              mat-icon-button
              class="slider-button"
              (click)="slider.scrollNext()"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slider [controls]="false" #slider>
        <slider-item *ngFor="let post of content">
          <featured-content
            [item]="post.content"
            [showButton]="false"
          ></featured-content>
        </slider-item>
      </slider>
    </div>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventsService } from '@common/services/events.service';
import { Event } from 'src/app/models/event';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginatorService } from '@common/services/paginator.service';

@UntilDestroy()
@Component({
  selector: 'events-extended',
  templateUrl: './events-extended.component.html',
  styleUrls: ['./events-extended.component.scss'],
})
export class EventsExtendedComponent implements OnInit {
  error: string | null = null;

  constructor(
    private eventsService: EventsService,
    public paginator: PaginatorService
  ) {}

  public key1 = 'past-events';
  ngOnInit() {
    this.paginator.init(this.key1, (page) => {
      return this.eventsService.getPastEvents().pipe(untilDestroyed(this)).pipe(map(data => data.data))
    });
  }
}

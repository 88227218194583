import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ProjectsData,
  ProjectsService,
} from '@common/services/projects.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'projects-home',
  templateUrl: './projects-home.component.html',
  styleUrls: ['./projects-home.component.scss'],
})
export class ProjectsHomeComponent implements OnInit {
  constructor(
    public projectsService: ProjectsService,
    private router: Router
  ) {}

  public projects$ = this.projectsService
    .getProjectsHome()
    .pipe(map((response) => response.data));

  public loading = true;
  public projects: ProjectsData;

  ngOnInit(): void {
    this.projects$.pipe(untilDestroyed(this)).subscribe({
      next: (projects) => {
        this.projects = projects;
        this.loading = false;
      },
    });
  }

  public navigateTo(url: any) {
    this.router.navigate(url);
  }
}

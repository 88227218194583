import { Component, Input, OnInit } from '@angular/core';
import { Members } from 'src/app/models/relations/members';

@Component({
  selector: 'user-communities',
  templateUrl: './user-communities.component.html',
  styleUrls: ['./user-communities.component.scss'],
})
export class UserCommunitiesComponent implements OnInit {
  @Input() isMe: boolean;
  @Input() communities: Members[];

  public filtered: Members[];
  ngOnInit(): void {
    this.filtered = this.geFiltered();
  }

  public geFiltered() {
    return this.communities.filter(
      (community) => community.profileType === 'Community'
    );
  }
}

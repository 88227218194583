import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import {
  CompaniesHomeData,
  CompaniesService,
} from '@common/services/companies.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public companiesService: CompaniesService
  ) {}

  public companies$ = this.companiesService
    .getHome()
    .pipe(map((res) => res.data));
  public companies: CompaniesHomeData;
  public loading = true;

  ngOnInit(): void {
    this.companies$.pipe(untilDestroyed(this)).subscribe({
      next: (companies) => {
        this.companies = companies;
        this.loading = false;
      },
    });
  }
}

<ng-container *ngIf="projects && !loading">
  <div class="content container">
    @if (projects?.joined?.length) {
    <div class="d-flex justify-content-between">
      <h2>Katılımcı olduklarım</h2>
      <a
        [routerLink]="['/projects-extended']"
        routerLinkActive="router-link-active"
        mat-button
        color="primary"
        >Hepsini gör</a
      >
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of projects.joined">
        <project-card [project]="project"></project-card>
      </div>
    </div>
    } @else {
    <div class="row">
      <div class="col-md-6" style="padding: 0 5%">
        <media-image
          src="assets/img/community.png"
          [fill]="true"
          [priority]="true"
        ></media-image>
      </div>
      <div class="col-md-6">
        <h2>Henüz bir projeye katılmadınız</h2>
        <p>Tüm projeleri keşif kataloğu ile keşfedebilirsiniz.</p>
        <a
          [routerLink]="['/discovery-catalog']"
          routerLinkActive="router-link-active"
          mat-button
          color="primary"
          >Kataloğa Git</a
        >
      </div>
    </div>
    }
    <info-card
      icon="people"
      title="Yeni Topluluklar Bulun"
      text="Farklı projeler yapan, yetenekli öğrencilerin bulunduğu toplulukları keşfedin, bir parçası olun ve devrim yaratacak yeni bir projenin öncüsü olun."
      buttonText="Toplulukları Keşfet"
      (buttonClick)="navigateTo(['/communities'])"
    ></info-card>
    @if (projects.suggestions?.length) {
      <div class="d-flex justify-content-between mt-4">
        <h2>Önerilen Projeler</h2>
      </div>
      <div class="row">
        <div class="col-sm-6" *ngFor="let project of projects.suggestions">
          <project-card [project]="project"></project-card>
        </div>
      </div>
    }
  </div>
</ng-container>
<ng-container *ngIf="loading">
  <div class="container mt-4">
    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '300px', height: '30px' }"
      ></ngx-skeleton-loader>

      <div class="row">
        <div class="col-md-6" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
          <ngx-skeleton-loader
            [theme]="{ width: '100%', height: '300px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '100%', height: '150px' }"
      ></ngx-skeleton-loader>
    </div>

    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '300px', height: '30px' }"
      ></ngx-skeleton-loader>

      <div class="row">
        <div class="col-md-6" *ngFor="let i of [1, 2]">
          <ngx-skeleton-loader
            [theme]="{ width: '100%', height: '300px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-container>

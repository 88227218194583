import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { FollowersService } from '@common/services/followers.service';
import { ProfileService } from '@common/services/profile.service';
import { BehaviorSubject, Observable, catchError, map, switchMap } from 'rxjs';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { ExtraSection } from 'src/app/models/relations/profiles/extra-section';
import { TalentList } from 'src/app/models/talentList';
import { User } from 'src/app/models/user';




@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {

  public isMe!: boolean;
  public education: any[] = [];

  public selectedTab = 0;

  public experiences: ExtraSection[] = [];
  public projects: any[] = [];
  certificates: ExtraSection[];
  public talents = [];
  public communities = [];


  constructor(
    public profileService: ProfileService,
    public activatedRoute: ActivatedRoute,
    public currentUser: AuthService,
    public followerService: FollowersService,
    public profileEditing:ProfileEditingService
    
  ) {}


  public error$ = new BehaviorSubject<string>(null);
  public user: User;
  public loading$ = new BehaviorSubject<boolean>(true);

  
  public get loggedIn() {
    return this.currentUser.loggedIn;
  }


  public goContact() {
    location.hash = "#contact";
  }

  public currentDate = new Date();

  public getSectionByType(sectionType:string, user:User) {
    return user.extraSections?.filter(item => item.type == sectionType)
  }
  
  public getTalents(user: User) {
    let talents : TalentList[] =[];
    user.extraSections?.forEach(section => {
      section.talents.forEach(talent=> {
        let existing = talents.find(x=>x.name===talent);
        if(existing) {
          existing.source.push({type: section.type, name:section.organization});
        }
        else {
          talents.push({name:talent, source: [{type: section.type, name:section.organization}]  });
        }
      })
    });
    return talents;
  }
  


  ngOnInit(): void {
    this.isMe = true;
    this.activatedRoute.params.pipe(
      switchMap((params) => 
        this.profileService.getUserProfile(
          params['username'] || this.currentUser.user?.name
        )
        
      ),
      catchError((err) => {
        console.error(err);
        this.error$.next(err.error?.message);
        return [];
      })
    ).subscribe({
      next: (user) => {
        this.user = user;
        this.isMe = this.currentUser.user?.id == user.id;
        this.education = this.getSectionByType('education', user);
        this.experiences = this.getSectionByType('experience', user);
        this.certificates = this.getSectionByType('certificate', user);
        this.talents = this.getTalents(user);
        this.profileEditing.init(user);
        this.communities = this.filterMemberToByType('community');
        this.loading$.next(false);
      }
    });
  }

  public filterMemberToByType(type: string) {
    return this.user.memberTo.filter((item) => item.profileType == type);
  }

 
}

<main-content>
  <div class="content container" style="min-height: 100vh">
    <div class="d-flex justify-content-between flex-wrap">
      <div></div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="buttons">
          <button
            mat-flat-button
            [class.active]="true"
            class="active"
            routerLink="../list"
          >
            Liste
          </button>
          <button routerLink="../week" mat-flat-button>Hafta</button>
          <button routerLink="../month" mat-flat-button>Ay</button>
        </div>
        <div>
          
        </div>
        <div>
            <button routerLink="/notifications" mat-icon-button><mat-icon>notifications</mat-icon></button>
        </div>
        <div>
          <button
            mat-flat-button
            class="button-primary"
            [routerLink]="['/events']"
          >
            Etkinlikleri Keşfet
          </button>
        </div>
      </div>
    </div>
    <div class="contents">
      <calendar-list *ngIf="list" [list]="list"></calendar-list>
    </div>
  </div>
</main-content>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagingService } from '../../messaging.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { MessageRoom, RoomType } from 'src/app/models/messages/message-room';
import { AuthService } from '@common/auth/auth.service';
import { MessageMemberStatus } from 'src/app/models/messages/message-member-status';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'messages-box',
  templateUrl: './messages-box.component.html',
  styleUrls: ['./messages-box.component.scss'],
})
export class MessagesBoxComponent implements OnInit {
  public senderId = this.auth.selectedProfile.id;
  public message = {
    sender: {
      id: '5f9d5f5b9b3f9c0017e7e7a5',
    },
  };

  @ViewChild('messagesDiv') messagesDiv;

  constructor(public messaging: MessagingService, private auth: AuthService) {}

  public messages$ = new BehaviorSubject<any[]>([]);

  public modifiedRoom$ = this.messaging.currentRoom$.pipe(
    map((room) => {
      if (room && room.roomType == RoomType.DIRECT_MESSAGE) {
        return room;
      }
    })
  );

  public currentRoom: MessageRoom;
  public lastOnline: MessageMemberStatus;
  public getLastOnline(room: MessageRoom): MessageMemberStatus {
    const otherMember = (room?.members || []).find(
      (member) => member.memberId != this.auth.selectedMember.profileId
    );
    if (otherMember) {
      const status = otherMember.status;
      return status;
    }
    return null;
  }

  public getStatusText(status: MessageMemberStatus) {
    if (status.status == 'online') {
      return 'Online';
    }
    return '';
  }

  ngOnInit() {
    this.messaging.currentRoom$.pipe(untilDestroyed(this)).subscribe((room) => {
      this.lastOnline = this.getLastOnline(room);
      this.currentRoom = room;
    });

    this.messaging.currentRoom$
      .pipe(
        switchMap((room) => {
          if (room) {
            return this.messaging.getMessages(room.id);
          }
          return [];
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((messages) => {
        this.messages$.next(messages);
        this.scrollBottom();
      });

    this.messaging
      .onNewMessage()
      .pipe(untilDestroyed(this))
      .subscribe((message: any) => {
        console.log('new', message, this.messaging.currentRoom$.value.id);
        if (message.room?.id == this.messaging.currentRoom$.value.id) {
          console.log('new2', message);
          this.messages$.next([...this.messages$.value, message]);
          this.scrollBottom();
        }
      });
  }

  public scrollBottom() {
    setTimeout(() => {
      this.messagesDiv.nativeElement.scrollTo(
        0,
        this.messagesDiv.nativeElement.scrollHeight + 100
      );
    }, 100);
  }
}

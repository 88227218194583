<ng-container *ngIf="people$ | async as data">
  <div class="content">
    <div class="following-area">
      <h2>Önerilen öğrenciler</h2>
      <div class="followed row">
        <div class="col-md-4 col-sm-6" *ngFor="let person of data.suggestions">
          <person-card-small [person]="person"></person-card-small>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <center-loading></center-loading>
</ng-template>

<div class="grid" *ngIf="images.length" [class.multiple]="imagesToShow.length > 1">
    <div class="grid-item" *ngFor="let file of imagesToShow; let i = index;">
        <div class="image-container">
            <img (click)="openImage(i)" [src]="file | imageFile" alt="image" />
        </div>
        <div class="overlay hover-effect" *ngIf="i == imagesToShow.length - 1 && imagesToShow.length > 3" (click)="openImage(i)">
            <div class="more-image-text">
                +{{images.length - imagesToShow.length}}
            </div>
        </div>
    </div>
</div>
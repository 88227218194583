import { Component, OnInit } from '@angular/core';
import { PeopleService } from '@common/services/people.service';
import { map } from 'rxjs';

@Component({
  selector: 'people-professionals',
  templateUrl: './people-professionals.component.html',
  styleUrls: ['./people-professionals.component.scss', '../panel.scss'],
})
export class PeopleProfessionalsComponent implements OnInit {
  constructor(public peopleService: PeopleService) {}

  ngOnInit(): void {}

  public people$ = this.peopleService
    .getProfessionals()
    .pipe(map((response) => response.data));
}

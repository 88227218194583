import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { NotificationsService } from '@common/services/notifications.service';
import { PaginatorService } from '@common/services/paginator.service';
import { map } from 'rxjs';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public notifications: NotificationsService,
    public paginator: PaginatorService,
    public toast: Toast
  ) {}

  public key = 'notifications';
  ngOnInit(): void {
    this.paginator.init(this.key, (page) => {
      return this.notifications
        .getAll(page)
        .pipe(map((response) => response.data));
    });
  }

  public accept(notification) {
    notification.loading = true;
    this.notifications.accept(notification.id).subscribe(() => {
      notification.accepted = true;
      this.auth.unreadNotifications--;
      if (this.auth.unreadNotifications < 0) {
        this.auth.unreadNotifications = 0;
      }
      notification.loading = false;
    });
  }

  public remove(notification) {
    notification.loading = true;
    this.notifications.delete(notification.id).subscribe(() => {
      this.paginator.goToPage(this.key, 1);
      if (this.auth.unreadNotifications < 0) {
        this.auth.unreadNotifications = 0;
      }
    });
  }
}

<div class="container mt-2">
  <mat-card appearance="outlined" class="posts card profile-card">
    <div class="title">
      <h2>Gönderiler</h2>
      <div>
        <div></div>
      </div>
    </div>
    <div>
      <post *ngFor="let post of posts" [post]="post"></post>
    </div>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { map, switchMap } from 'rxjs';
import { DiscoveryCategory } from 'src/app/models/discovery/category';
import { DiscorveryItem } from 'src/app/models/discovery/item';
import { DiscoveryCatalogControllerService } from '../../../discovery-catalog-controller.service';

@Component({
  selector: 'categories-search',
  templateUrl: './categories-search.component.html',
  styleUrls: ['./categories-search.component.scss'],
})
export class CategoriesSearchComponent implements OnInit {
  constructor(
    public discoveryCatalogService: DiscoveryCatalogService,
    public activatedRoute: ActivatedRoute,
    public controller: DiscoveryCatalogControllerService
  ) {}

  public filterText$ = this.activatedRoute.queryParams.pipe(
    map((params) => params['search'] || '')
  );

  public selectedTagsAtob$ = this.filterText$.pipe(
    map((search) => {
      try {
        const data = JSON.parse(
          decodeURIComponent(atob(search))
        ) as DiscoveryCategory[];
        console.log(data);
        return data;
      } catch (error) {
        return [];
      }
    })
  );

  public searchData$ = this.selectedTagsAtob$
    .pipe(
      switchMap((tags) =>
        this.discoveryCatalogService.searchByCategories(
          tags.map((tag) => tag.name)
        )
      )
    )
    .pipe(map((data) => data.data));

  ngOnInit() {
    if (!this.controller.searchCategoriesData) {
      this.searchData$.subscribe({
        next: (data) => {
          this.controller.searchCategoriesData = data;
          this.controller.searchCategoriesLoading = false;
        },
        error: (error) => {
          console.error('Error getting data', error);
          this.controller.searchCategoriesLoading = false;
        },
      });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  @Input('project') project : any
  @Input('dark') dark : boolean = false

  constructor(private auth:AuthService) { }

  ngOnInit(): void {
  }

  public getImage() {
    if (this.project.coverImage && typeof this.project.coverImage !== 'string') {
      return this.project.coverImage
    } else if (this.project.images.length > 0) {
      return this.project.images[0]
    }
  }

  public getParticipantText() {
    if (!this.project.participants) return ''
    const filter = this.project.participants.filter(item => item.userId != this.auth.user.id)
    if (filter.length == 0) {
      return 'Bu projede çalışıyorsunuz'
    } else if (filter.length == 1) {
      return filter[0].user.display_name + ' bu projede çalışıyor'
    } else {
      return filter[0].user.display_name + ' ve ' + (filter.length - 1) + ' kişi daha bu projede çalışıyor'
    }
  }

}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JobPostingService } from '@common/services/job-posting.service';
import { BehaviorSubject, map } from 'rxjs';
import { JobPosting } from 'src/app/models/job-posting';

@Component({
  selector: 'search-job-posting',
  templateUrl: './search-job-posting.component.html',
  styleUrls: ['./search-job-posting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchJobPostingComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    public jobs: JobPostingService,
    public activatedRoute: ActivatedRoute
  ) {}

  public selectedJobPosting: JobPosting = null;
  public results$ = new BehaviorSubject<JobPosting[]>([]);

  public form = this.fb.group({
    query: [''],
    location: [''],
    tags: this.fb.array([]),
  });

  public get tags() {
    return this.form.get('tags') as FormArray;
  }
  ngOnInit(): void {
    this.search(this.form.value);
    this.form.valueChanges.subscribe((value) => {
      this.search(value);
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tag']) {
        this.tags.push(
          this.fb.control({
            name: params['tag'],
            display_name: params['tag'],
            selected: true,
          })
        );
      }
    });
  }

  public search(value: any) {
    this.jobs
      .searchPosts({ title: value.query }, [value.location], value.tags as any)
      .subscribe((results) => {
        this.results$.next(results.data.hits);
      });
  }
}

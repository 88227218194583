import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { UploadService } from '@common/core/upload/upload.service';
import { CountriesService } from '@common/services/countries.service';
import { JobPostingService } from '@common/services/job-posting.service';
import { map, switchMap } from 'rxjs';
import { JobPosting } from 'src/app/models/job-posting';

@Component({
  selector: 'job-posting-application',
  templateUrl: './job-posting-application.component.html',
  styleUrls: ['./job-posting-application.component.scss'],
})
export class JobPostingApplicationComponent implements OnInit {
  constructor(
    public jobPosting: JobPostingService,
    public activatedRoute: ActivatedRoute,
    public auth: AuthService,
    public fb: FormBuilder,
    public countries: CountriesService,
    public toast: Toast,
    public uploads: UploadService,
    public router: Router
  ) {}

  public job$ = this.activatedRoute.params
    .pipe(switchMap((params) => this.jobPosting.getJobPosting(params['id'])))
    .pipe(map((res) => res.data));

  public job: JobPosting;
  public loading = false;

  public countries$ = this.countries.getCountries();

  public form = this.fb.group({
    first_name: [this.auth.user.first_name],
    last_name: [this.auth.user.last_name],
    gender: ['male'],
    id_number: [''],
    country: ['tr'],
    city: [''],
    email: [''],
    phone: [''],
    resume: [''],
  });

  public get resume() {
    return this.form.get('resume') as FormControl;
  }

  public questionForm;

  public cities$ = this.form
    .get('country')
    .valueChanges.pipe(
      switchMap((country) => this.countries.getCities(country))
    )
    .pipe(map((res) => res.data));

  ngOnInit() {
    this.job$.subscribe((job) => {
      this.job = job;
      const form: any = {};
      for (const question of job.questions) {
        form[question.id] = [''];
      }
      this.questionForm = this.fb.group(form);
    });
  }

  public pcikResume() {
    this.uploads
      .openUploadDialog$('pdf', false, {
        allowCrop: false,
      })
      .subscribe((files) => {
        if (files?.length) {
          this.resume.setValue(files[0]);
        }
      });
  }

  public submit() {
    const data = {
      form: this.form.value,
      answers: this.questionForm.value,
      jobPosting: this.job.id,
    };
    this.loading = true;
    this.jobPosting.apply(this.job.id, data).subscribe({
      next: (res) => {
        this.toast.open('Başvuru yapıldı');
        this.router.navigate(['/job-posting']);
      },
      error: (err) => {
        this.toast.open('Bir hata oluştu. Lütfen tekrar deneyin.');
        this.loading = false;
      },
    });
  }
}

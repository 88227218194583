<ng-container *ngIf="!controller.specialLoading">
  <div class="highlights">
    <div class="texts">
      <h3>İlginizi Çekebilecekler</h3>
      <p>Popüler olan keşiflerden ilginizi çekebileceğini düşündüklerimiz.</p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item [itemSize]="33.33" *ngFor="let item of data.interestedIn">
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>
  <info-card
    title="Kendi İnsanlarınla Geleceği İnşa Et"
    icon="people"
    text="Unilocked’da edindiğin arkadaşların ile geleceği değiştirecek o projeyi yapın, yakın çevrenizdeki zeki <span> öğrenciler</span>i keşfedin."
    buttonText="Arkadaşları Keşfet"
    buttonColor="#4B53F2"
    class="info-card"
  >
  </info-card>
  <div class="container-topnotes">
    <div class="texts">
      <h3>Yüksek Notlular</h3>
      <p>
        İlgi alanlarınız ve seçimleriniz sonucu en yüksek İlgi Notu alanlar.
      </p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item [itemSize]="33.33" *ngFor="let item of data.highGrades">
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>
  <div class="container-chips" *ngIf="false">
    <mat-chip-listbox
      class="example-chip"
      cdkDropList
      cdkDropListOrientation="horizontal"
    >
      <mat-chip-option
        class="example-box"
        cdkDrag
        *ngFor="let key of keys"
      >
        <mat-icon matChipTrailingIcon *ngIf="key.icon">{{ key.icon }}</mat-icon
        >{{ key.name }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div class="container-popular">
    <div class="texts">
      <h3>Konumunuzda Popüler</h3>
      <p>
        Ankara içerisinde popüler olan keşiflerden ilginizi çekebileceğini
        düşündüklerimiz.
      </p>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item
          [itemSize]="33.33"
          *ngFor="let item of data.popularInYourLocation"
        >
          <generic-card [data]="item"></generic-card>
        </slider-item>
      </slider>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="controller.specialLoading">
  <discovery-loading-with-titles></discovery-loading-with-titles>
</ng-container>
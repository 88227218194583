import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { map } from 'rxjs';
import { DiscoveryCatalogControllerService } from '../../discovery-catalog-controller.service';

@Component({
  selector: 'discovery-special-for-you',
  templateUrl: './discovery-special-for-you.component.html',
  styleUrls: ['./discovery-special-for-you.component.scss'],
})
export class DiscoverySpecialForYouComponent implements OnInit{
  constructor(public discoveryCatalogService: DiscoveryCatalogService, public controller:DiscoveryCatalogControllerService) {}

  ngOnInit(): void {
   if (!this.controller.specialData) {
     this.data$.subscribe({
       next: (data) => {
         this.controller.specialData = data;
         this.controller.specialLoading = false;
       },
       error: (err) => {
         console.error(err);
       },
     });
   }
  }

  public get data() {
    return this.controller.specialData;
  }

  public data$ = this.discoveryCatalogService
    .getSpecial()
    .pipe(map((res) => res.data));

  drop(event: CdkDragDrop<Keys[]>) {
    moveItemInArray(this.keys, event.previousIndex, event.currentIndex);
  }

  keys: Keys[] = [
    {
      name: 'UI Designer',
      color: '#343A40',
      icon: 'search',
      textColor: '#F1F3F5',
    },
    {
      name: 'Eğlence Sektörü • İlgi Alanınız',
      color: '#343A40',
      textColor: '#F1F3F5',
    },
    {
      name: 'İletişim Sektörü • İlgi Alanınız',
      color: '#343A40',
      textColor: '#F1F3F5',
    },
    {
      name: 'Yükselişte Olanlar',
      color: '#E9ECEF',
      icon: 'stars',
      textColor: '#495057',
    },
    {
      name: 'Staj İlanı Yayınlayan Şirketler',
      color: '#E9ECEF',
      textColor: '#495057',
    },
    { name: '10.000+ Takipçi', color: '#E9ECEF', textColor: '#495057' },
    { name: '25.000+ Takipçi', color: '#E9ECEF', textColor: '#495057' },
  ];
}

export interface Keys {
  name: string;
  color?: string;
  icon?: string;
  textColor?: string;
}

<!--         PROJECTS       -->
<div class="container" *ngIf="items">
  <div class="content">
    <div class="projects">
      <div class="text-container">
        <p>
          Projeler
          <a
            [routerLink]="['/projects-extended']"
            routerLinkActive="router-link-active"
            href="#filter"
            class="button-filter"
            >Hepsini Gör</a
          >
        </p>
      </div>
    </div>
    <div class="project-cards">
      <div class="row" style="align-items: stretch;" *ngIf="items?.length > 0 else noResult">
        <div class="col-sm-4" *ngFor="let item of items">
          <project-card [project]="item"></project-card>
        </div>
      </div>
      <ng-template #noResult>
        <div class="no-result">
          <p>Aradığınız kriterlere uygun proje bulunamadı.</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<br />

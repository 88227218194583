import { Component, Input, OnInit } from '@angular/core';
import { EditProfileBottomSheetComponent } from '../edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { ExtraSection } from 'src/app/models/relations/profiles/extra-section';

@Component({
  selector: 'user-extrasection',
  templateUrl: './user-extrasection.component.html',
  styleUrls: ['./user-extrasection.component.scss'],
})
export class UserExtrasectionComponent implements OnInit {
  @Input() extraSections: any[];
  @Input() type: string;
  @Input() isMe: boolean;

  selectedSection: any[] = [];

  constructor(
    public _bottomSheet: MatBottomSheet,
    public profileEditing: ProfileEditingService
  ) {}

  ngOnInit(): void {
    this.selectedSection = this.getSectionByType(this.type);
  }

  public getSectionByType(sectionType: string) {
    return this.extraSections.filter((item) => item.type == sectionType);
  }

  openBottomSheet(): void {
    this._bottomSheet.open(EditProfileBottomSheetComponent, {
      data: {
        selectedPart: 'extraSection',
        profile: this.profileEditing.profile,
        title: 'Deneyimler',
      },
    });
  }

  public getAvatar(section: ExtraSection) {
    if (section.company) {
      return section.company.avatar;
    }
    return null;
  }
}

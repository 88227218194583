<div class="container">
  <mat-card appearance="outlined" class="card profile-card">
    <div class="title">
      <h2>Yetenekler</h2>
      <div>
        <button mat-button color="primary" *ngIf="isMe" (click)="openBottomSheet()">
          <mat-icon>edit</mat-icon> Düzenle
        </button>
      </div>
    </div>
    <ng-container *ngIf="talents.length > 0">
      <div *ngFor="let talent of talents; let i = index" class="skill">
        <h4 class="mat-subtitle-1">{{ talent.name }}</h4>
        <div *ngFor="let s of talent.source" class="avatar-container">
          <div class="avatar-icon" [style.backgroundColor]="getRandomColor(i)">
            <mat-icon *ngIf="s.type === 'education'">school</mat-icon>
            <mat-icon *ngIf="s.type === 'experience'">work</mat-icon>
            <mat-icon *ngIf="s.type === 'certificate'"
              >insert_drive_file</mat-icon
            >
          </div>

          <div>
            <p class="description-text">
              <strong>{{ s.name }} </strong>
              deneyiminden.
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>

<main-content>
  <div class="container" style="min-height: 100vh">
    <div class="d-flex justify-content-center justify-content-md-between flex-wrap">
      <div>
        <div class="top-left">
          <div class="choose-month-buttons">
            <button mat-icon-button (click)="calendar.prev()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <span>{{ calendar.firstDayOfMonth | dateMonthYearNumber }} </span>
            <button mat-icon-button (click)="calendar.next()">
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
          <div>
            <h2>{{ calendar.firstDayOfMonth | dateMonthName }} Ayı</h2>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
        <div class="buttons">
          <button mat-flat-button routerLink="../list">Liste</button>
          <button routerLink="../week" mat-flat-button>Hafta</button>
          <button routerLink="../month" [class.active]="true" mat-flat-button>
            Ay
          </button>
        </div>
        <div>
        </div>
        <div>
          <button routerLink="/notifications" mat-icon-button><mat-icon>notifications</mat-icon></button>
        </div>
        <div>
          <button
            [routerLink]="['/events']"
            mat-flat-button
            class="button-primary"
          >
            Etkinlikleri Keşfet
          </button>
        </div>
      </div>
    </div>
    <div class="contents">
      <calendar-month #calendar></calendar-month>
    </div>
  </div>
</main-content>

import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { FeaturedContent } from 'src/app/models/relations/profiles/featured-content';
import { EditProfileBottomSheetComponent } from '../edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';

@Component({
  selector: 'user-featured',
  templateUrl: './user-featured.component.html',
  styleUrls: ['./user-featured.component.scss'],
})
export class UserFeaturedComponent {
  @Input() content: FeaturedContent[];
  @Input() link: string[];
  @Input() isMe: boolean;
  constructor(
    public _bottomSheet: MatBottomSheet,
    public profileEditing: ProfileEditingService
  ) {}

  ngOnInit(): void {}

  openBottomSheet(): void {
    this._bottomSheet.open(EditProfileBottomSheetComponent, {
      data: {
        selectedPart: 'featuredContent',
        profile: this.profileEditing.profile,
        title: 'Öne Çıkarılan İçerikler',
      },
    });
  }
}

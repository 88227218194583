<!--     EVENTS      -->
<div class="container" *ngIf="items && items.length > 0">
  <div class="content">
    <div class="highlights">
      <div class="text-container">
        <p>
          Etkinlikler
          <a [routerLink]="['/events-extended']" routerLinkActive="router-link-active"  class="button-filter">Hepsini Gör</a>
        </p>
      </div>
    </div>
    <div class="row align-items-stretch">
      <div class="col-md-4" *ngFor="let item of items">
        <event-card [event]="item" [fullHeight]="true" [title]="item.name"  color="blue" title="Antalya Teknokent Demoday"></event-card>
      </div>
    </div>
  </div>
</div>

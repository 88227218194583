<ng-container [ngSwitch]="data.type">
    <person-card *ngSwitchCase="'User'" [data]="asPerson"></person-card>
    <project-post-card *ngSwitchCase="'Project'" [data]="asProject"></project-post-card>
    <events-card *ngSwitchCase="'Event'" [data]="asEvent"></events-card>
    <company-card *ngSwitchCase="'Company'" [data]="asCompany"></company-card>
    <community-card *ngSwitchCase="'Community'" [data]="asCommunity"></community-card>
    <job-card *ngSwitchCase="'Job'" [data]="asJobPosting"></job-card>
    <job-card *ngSwitchCase="'JobPosting'"[data]="asJobPosting"></job-card>
    <job-card *ngSwitchCase="'jobPosting'"[data]="asJobPosting"></job-card>
    <project-post-card *ngSwitchCase="'ProjectPost'" [data]="asProject"></project-post-card>
    
</ng-container>
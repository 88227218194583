import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { ExtraSection } from 'src/app/models/relations/profiles/extra-section';
import { Talent } from 'src/app/models/talent';
import { TalentList } from 'src/app/models/talentList';
import { EditProfileBottomSheetComponent } from '../edit-profile-bottom-sheet/edit-profile-bottom-sheet.component';

@Component({
  selector: 'user-skills',
  templateUrl: './user-skills.component.html',
  styleUrls: ['./user-skills.component.scss'],
})
export class UserSkillsComponent implements OnInit {
  @Input() isMe: boolean;
  @Input() talents: TalentList[];

  ngOnInit(): void {}

  constructor(
    public _bottomSheet: MatBottomSheet,
    public profileEditing: ProfileEditingService
  ) {}

  public colorArray = [
    '#615EFC',
    '#DC5F00',
    '#028391',
    '#FFA27F',
    '#C738BD',
    '#AF8F6F',
    '#D2649A',
    '#A91D3A',
    '#151515',
    '#03AED2',
    '#E2DFD0',
    '#41B06E',
  ];

  public getRandomColor(index: number) {
    if (index >= this.colorArray.length) {
      index = index % this.colorArray.length;
    }
    return this.colorArray[index];
  }

  public getAvatar(section: ExtraSection) {
    if (section.company) {
      return section.company.avatar;
    }
    return null;
  }


  openBottomSheet(): void {
    this._bottomSheet.open(EditProfileBottomSheetComponent, {
      data: {
        selectedPart: 'extraSection',
        profile: this.profileEditing.profile,
        title: "Deneyimler"
      },
    });
  }
}

<ng-container *ngIf="currentRoom; else noRoom">
  <div class="chat-info">
    <div class="info-main">
      <div class="left-panel">
        <div class="sender-image-container">
          <img
            class="sender-image"
            [src]="currentRoom.image || '/assets/img/default.png'"
          />
          <div class="pin-container">
            <mat-icon class="pin-icon">push_pin</mat-icon>
          </div>
        </div>
        <div class="sender-name-container">
          <div class="sender-name">{{ currentRoom.name }}</div>
          <div class="sent-hour" *ngIf="lastOnline; let status">
            <ng-container
              *ngIf="status.status == 'online'; else showLastOnline"
            >
              Çevrimiçi
            </ng-container>
            <ng-template #showLastOnline>
              {{ status.updatedAt | dateAgo }}
            </ng-template>
          </div>
        </div>
      </div>
      <div class="chat-actions"></div>
    </div>
  </div>

  <div class="messages" *ngIf="messages$ | async as messages" #messagesDiv>
    <ng-container *ngFor="let message of messages; let i = index">
      <div
        class="send-chat"
        *ngIf="
          message.sender.id === senderId &&
          (i === 0 || messages[i].sender.id !== messages[i - 1].sender.id)
        "
      >
        <img class="send-image" [src]="message.sender.avatar" />
        {{ message.sender.display_name }}
      </div>
      <div
        class="receive-chat"
        *ngIf="
          message.sender.id !== senderId &&
          (i === 0 || messages[i].sender.id !== messages[i - 1].sender.id)
        "
      >
        <img class="send-image" [src]="message.sender.avatar" />
        {{ message.sender.display_name }}
      </div>
      <message-item [message]="message"></message-item>
    </ng-container>
  </div>

  <div class="write-message-container">
    <write-message></write-message>
  </div>
</ng-container>
<ng-template #noRoom>
  <div class="no-room">
    <div>
      <div class="text-container">
        <div class="no-room-icon">
          <mat-icon>chat</mat-icon>
        </div>
        <div class="no-room-text">
          Mesajlaşmaya başlamak için bir sohbet seçin
        </div>
      </div>
      <div class="button-container">
        <button mat-button (click)="messaging.openNewChatDialog()">
          <mat-icon>add</mat-icon>
          <span class="new-message-button">Yeni Görüşme</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Toast } from '@common/core/toast/toast/toast.service';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { map } from 'rxjs';
import { DiscoveryCategory } from 'src/app/models/discovery/category';

@Component({
  selector: 'user-interests',
  templateUrl: './user-interests.component.html',
  styleUrls: ['./user-interests.component.scss'],
})
export class UserInterestsComponent implements OnInit {
  constructor(
    public discoveryService: DiscoveryCatalogService,
    public ref: MatDialogRef<UserInterestsComponent>,
    private toast: Toast
  ) {}
  public selectedInterests: DiscoveryCategory[] = [];
  public items: DiscoveryCategory[] = [];

  public interests$ = this.discoveryService.getInterests().pipe(
    map((res) => {
      const data = res.data;
      for (let item of data.main) {
        item.in = 'main';
      }
      for (let key in data.other) {
        for (let item of data.other[key]) {
          item.in = 'other.' + key;
        }
      }
      for (let item of data.sectors) {
        item.in = 'sectors';
      }
      return data;
    })
  );

  public isSelected(interest: DiscoveryCategory) {
    return interest.selected;
  }

  public other = [];
  public getOther(items: any) {
    return Object.keys(items.other).map((key) => {
      return {
        name: this.getName(key),
        data: items.other[key],
      };
    });
  }

  public getName(key: string) {
    if (key == 'people') return 'Kişiler';
    if (key == 'companies') return 'Şirketler';
    if (key == 'projects') return 'Projeler';
    if (key == 'events') return 'Etkinlikler';
    if (key == 'jobPostings') return 'İş İlanları';
    if (key == 'communities') return 'Topluluklar';
    return key;
  }

  public toggleSelection(interest: DiscoveryCategory) {
    if (this.isSelected(interest)) {
      this.selectedInterests = this.selectedInterests.filter(
        (item) => item.key != interest.key
      );
    } else {
      this.selectedInterests.push(interest);
    }
    interest.selected = !interest.selected;
  }

  ngOnInit(): void {
    this.interests$.subscribe((res) => {
      const array = [];
      const data = res;
      for (let item of data.main) {
        array.push(item);
      }
      for (let key in data.other) {
        for (let item of data.other[key]) {
          array.push(item);
        }
      }
      for (let item of data.sectors) {
        array.push(item);
      }
      this.items = array;
      this.other = this.getOther(data);
      this.selectedInterests = this.items.filter((item) => item.selected);
      console.log(this.selectedInterests);
    });
  }

  public save() {
    this.discoveryService
      .putInterests(this.selectedInterests)
      .subscribe((res) => {
        this.toast.open('İlgi alanlarınız kaydedildi');
      });
    this.ref.close();
  }
}

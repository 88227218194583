import {
  Component,
  ElementRef,
  HostListener,
  QueryList,
  ViewChildren,
} from '@angular/core';
interface sectionItem {
  sectionId: number;
  id: number;
}

interface AboutItem {
  title: string;
  text: string;
  image: string;
}

interface SectionConfig {
  sectionId: number;
  nextThreshold: number;
  prevThreshold: number;
  nextSection?: string;
  prevSection?: string;
  scrollDelay?: number;
}

interface ScrollRange {
  min: number;
  max: number;
  id: number;
}

const scrollRanges: ScrollRange[] = [
  { min: 7, max: 13, id: 1 },
  { min: 13, max: 17, id: 2 },
  { min: 17, max: 21, id: 3 },
  { min: 21, max: 25, id: 4 },
  { min: 29, max: 33, id: 5 },
  { min: 33, max: 37, id: 6 },
  { min: 37, max: 45, id: 1 },
  { min: 45, max: 50, id: 2 },
  { min: 50, max: 55, id: 3 },
  { min: 55, max: 60, id: 4 },
  { min: 60, max: 65, id: 5 },
  { min: 65, max: 68, id: 6 },
];

const sectionConfigs: Record<string, SectionConfig> = {
  section2: {
    sectionId: 2,
    nextThreshold: 37,
    prevThreshold: 0.1,
    nextSection: 'pageCards',
  },
  pageCards: {
    sectionId: 3,
    nextThreshold: 70,
    prevThreshold: 37,
    nextSection: 'popularToday',
    prevSection: 'section2',
  },
  popularToday: {
    sectionId: 4,
    nextThreshold: 76,
    prevThreshold: 40,
    nextSection: 'planet',
    prevSection: 'pageCards',
  },
  planet: {
    sectionId: 5,
    nextThreshold: 84,
    prevThreshold: 76,
    nextSection: 'flippingCards',
    prevSection: 'popularToday',
  },
  flippingCards: {
    sectionId: 6,
    nextThreshold: 92,
    prevThreshold: 84,
    nextSection: 'blogPosts',
    prevSection: 'planet',
  },
  blogPosts: {
    sectionId: 7,
    nextThreshold: 98,
    prevThreshold: 92,
    nextSection: 'footerSection',
    prevSection: 'flippingCards',
  },
  footerSection: {
    sectionId: 8,
    nextThreshold: Infinity,
    prevThreshold: 98,
    prevSection: 'blogPosts',
  },
};

@Component({
  selector: 'guest-home',
  templateUrl: './guest-home.component.html',
  styleUrls: ['./guest-home.component.scss'],
})
export class GuestHomeComponent {
  @ViewChildren('sectionElement') sections!: QueryList<ElementRef>;
  @ViewChildren('pageCards') pageCards!: QueryList<ElementRef>;
  @ViewChildren('popularToday') popularToday!: QueryList<ElementRef>;
  @ViewChildren('planet') planet!: QueryList<ElementRef>;
  @ViewChildren('flippingCards') flippingCards!: QueryList<ElementRef>;
  @ViewChildren('blogPosts') blogPosts!: QueryList<ElementRef>;
  @ViewChildren('footerSection') footerSection!: QueryList<ElementRef>;

  lastKnownScrollPosition = 0;
  activeSectionItem: sectionItem = {
    sectionId: 0,
    id: 0,
  };

  aboutItems: AboutItem[] = [
    {
      title: 'Potansiyelini Keşfetmen İçin',
      text: 'Akademik ve profesyonel kariyerinde aradığın her şey tek platformda.',
      image: 'educationIcon.svg',
    },
    {
      title: 'Potansiyeli Keşfetmeniz İçin',
      text: 'Aradığınız özelliklerdeki umut vadeden öğrencileri kolayca bulmanıza yardımcı olan Yapay Zeka destekli araçlarımızla hizmet sağlıyoruz.',
      image: 'worldIcon.svg',
    },
    {
      title: 'Hayalinizi Gerçeğe Dönüştürmeniz İçin',
      text: 'Projeniz için proje ortağı eşleştirme sistemini ve potansiyel yatırım olanaklarını tek çatı altında buluşturuyoruz',
      image: 'worldIcon.svg',
    },
    {
      title: 'Görkemli Etkinlikler Düzenlemek İçin',
      text: 'Hedef kitlenize etkinliklerinizi duyurmanızı ve etkinliklerinizi sertifikalandırarak CV havuzlarında yer almalarını sağlıyoruz.',
      image: 'worldIcon.svg',
    },
    {
      title: 'Büyük Topluluklar Kurmak İçin',
      text: 'Diğer topluluklar ile bağlantı kurmanıza, ve potansiyel üyelere kendinizi tanıtmanıza yardımcı oluyoruz.',
      image: 'worldIcon.svg',
    },
    {
      title: 'Herkes İçin Unilocked',
      text: 'Herkese başarının kilidini açabileceği bir platform sunuyoruz.',
      image: 'worldIcon.svg',
    },
  ];

  ngAfterViewInit(): void {
    if (typeof window === 'undefined') {
      return;
    }

    console.log(this.aboutItems[0].title);

    const options = {
      root: null, // Use the viewport as the container
      threshold: 0.8, // Trigger when 80% of the element is in view
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Collect all section types into a single array
    const allSections = [
      ...this.sections,
      ...this.pageCards,
      ...this.popularToday,
      ...this.planet,
      ...this.flippingCards,
      ...this.blogPosts,
      ...this.footerSection,
    ];

    // Observe each section
    allSections.forEach((section) => observer.observe(section.nativeElement));
  }

  timeout: any;
  disableScroll(timeout = 2000) {
    /*if (this.timeout) {
      clearTimeout(this.timeout);
    }
    document.documentElement.style.overflow = 'hidden';
    this.timeout = setTimeout(() => {
      document.documentElement.style.overflow = 'auto';
    }, timeout);*/
  }

  handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const sections = {
      section2: { sectionId: 2, id: 1 },
      pageCards: { sectionId: 3, id: 1},
      popularToday: { sectionId: 4, id: 1},
      planet: { sectionId: 5, id: 1},
      flippingCards: { sectionId: 6, id: 1 },
      blogPosts: { sectionId: 7, id: 1 },
      footerSection: { sectionId: 8, id: 1 },
    };

    entries.forEach((entry) => {
      if (entry.isIntersecting && this.activeSectionItem.sectionId === 0) {
        const sectionConfig =
          sections[entry.target.id as keyof typeof sections];

        if (sectionConfig) {
          this.activeSectionItem = sectionConfig;
          console.log(entry.target.id, this.activeSectionItem);

          entry.target.classList.add('in-viewport', 'fixed');

          if (entry.target.id === 'section2') {
            const topNavMenu = document.querySelector(
              '.topNavMenu'
            ) as HTMLElement;
            topNavMenu.style.backgroundColor = '#fbfbfc';
            topNavMenu.style.width = '100%';
          }

          if (entry.target.id !== 'footerSection') {
            this.disableScroll(1000);
          }
        }
      }
    });
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    event.preventDefault();
    let deltaY = window.scrollY - this.lastKnownScrollPosition;
    this.lastKnownScrollPosition = window.scrollY;

    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollTop = window.scrollY;
    const scrollPercent = (scrollTop / scrollableHeight) * 100;
    console.log(scrollPercent)
    this.handleSection(scrollPercent);
  }

  handleSection(scrollPercent: number) {
    const currentSectionKey =
      Object.keys(sectionConfigs).find(
        (key) =>
          sectionConfigs[key].sectionId === this.activeSectionItem.sectionId
      ) || '';
    const currentConfig = sectionConfigs[currentSectionKey];

    if (this.activeSectionItem.sectionId === 0) {
      console.log('No active section');
      document.getElementById('section2').style.width =
        Math.min(100, 70 + scrollPercent * 4) + '%';
    } else {
      console.log(this.activeSectionItem.sectionId);
    }

    if (currentConfig) {
      const {
        sectionId,
        nextThreshold,
        prevThreshold,
        nextSection,
        prevSection,
        scrollDelay = 500,
      } = currentConfig;

      if (scrollPercent > nextThreshold && nextSection) {
        this.switchSection(
          currentSectionKey,
          nextSection,
          nextSection === 'footerSection'
            ? 0
            : sectionConfigs[nextSection].scrollDelay || scrollDelay
        );
      } else if (scrollPercent < prevThreshold) {
        console.log('prevSection', prevSection);
        this.switchSection(currentSectionKey, prevSection, scrollDelay);
      } else {
        this.updateSectionStyles(currentSectionKey, scrollPercent, sectionId);
        this.updateActiveSectionItem(scrollPercent);
      }
    }
  }

  switchSection(
    currentSectionId: string,
    targetSectionId: string,
    scrollDelay: number
  ) {
    if (targetSectionId === undefined) {
      document
        ?.getElementById(currentSectionId)
        ?.classList?.remove('fixed', 'in-viewport');
      this.activeSectionItem.sectionId = 0;
    } else {
      document
        ?.getElementById(currentSectionId)
        ?.classList?.remove('fixed', 'in-viewport');
      document
        ?.getElementById(targetSectionId)
        ?.classList?.add('fixed', 'in-viewport');
      this.activeSectionItem.sectionId =
        sectionConfigs[targetSectionId].sectionId;
      console.log(`Go to ${targetSectionId}`);
      this.disableScroll(scrollDelay);
    }
  }

  updateSectionStyles(
    currentSectionId: string,
    scrollPercent: number,
    sectionId: number
  ) {
    if (sectionId === 2) {
      document.getElementById('section2').style.width =
        Math.min(100, 70 + scrollPercent * 5) + '%';
      document.getElementById('heroSection').classList.remove('in-viewport');
    }
  }

  updateActiveSectionItem(scrollPercent: number): void {
    for (let { min, max, id } of scrollRanges) {
      if (
        scrollPercent > min &&
        scrollPercent < max &&
        this.activeSectionItem.id !== id
      ) {
        this.activeSectionItem.id = id;
        this.disableScroll(500);
        break;
      }
    }
  }
}

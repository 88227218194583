<mat-card class="card">
  <mat-card-title class="title">İlan</mat-card-title>

  <mat-card-header class="header">
  
    <mat-card-title class="header-title" style="cursor: pointer;" [routerLink]="['/job-posting', data.id]">{{data.title}}</mat-card-title>

    <mat-card-subtitle>{{data.description.slice(0, 100) + '...'}}</mat-card-subtitle>
    
  
      <mat-card-subtitle class="header-subtitle">
        <div class="icon"><mat-icon>computer</mat-icon></div>
        <div class="date-text">{{data.workType == 'Remote' ? 'Uzaktan' : 'Ofiste'}}</div>
      </mat-card-subtitle>
  
      <mat-card-subtitle class="header-subtitle">
        <div class="icon"> • Staj • </div>
        <div class="location-text">{{data.workingTime}} gün</div>
      </mat-card-subtitle>


  </mat-card-header>

  <mat-card-content class="content">
    <div class="content-group">
      <img [src]="data.creator.avatar" alt="Company Logo" class="company-logo" />
      <div class="company-info">
        <div class="company-name">{{data.creator.name}}</div>
      </div>
    </div>
  
    <main-text-card></main-text-card>
  </mat-card-content>

    <like-save-card [data]="getMappedData()"></like-save-card>
</mat-card>
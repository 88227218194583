<ng-container *ngIf="members$ | async as members">
  <div class="title-container">
    <h3 class="title">Kişiler</h3>
  </div>
  <mat-action-list>
    <button
      mat-list-item
      *ngFor="let member of members"
      [matMenuTriggerFor]="menu"
    >
      <media-image
        [src]="member.member.avatar"
        matListItemAvatar
        appereance="rounded"
        objectFit="cover"
        width="48px"
        height="48px"
      ></media-image>
      <h4 style="margin: 2px" mat-line>{{ member.member.display_name }}</h4>
      <span style="font-size: 14px" mat-line>{{ member.member.headnote }}</span>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openChat(member)">
          <mat-icon>chat</mat-icon>
          <span>Mesaj Gönder</span>
        </button>
        <button mat-menu-item *ngIf="room | isRoomEditable" (click)="toggleIsAdmin(member)">
          <mat-icon>auto_awesome</mat-icon>
          <span>{{member | makeMessageAdminButtonText : room}}</span>
        </button>
        <button mat-menu-item *ngIf="room | isRoomEditable" (click)="removeMember(member)">
          <mat-icon>delete</mat-icon>
          <span>Sohbetten Çıkar</span>
        </button>
      </mat-menu>
    </button>
  </mat-action-list>
</ng-container>

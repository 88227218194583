<main-content>
  <div class="content container" style="min-height: 100vh">
    <div class="d-flex justify-content-md-between justify-content-center flex-wrap">
      <div class="top-left">
        <div class="choose-week-buttons">
          <button mat-icon-button (click)="calendar.prev()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span
            >{{ calendar.firstDayOfWeek | dateDayNumber }} -
            {{ calendar.lastDayOfWeek | dateDayNumber }}</span
          >
          <button mat-icon-button (click)="calendar.next()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
        <div>
          <h2>{{ calendar.firstDayOfWeek | dateDayMonth }} Haftası</h2>
        </div>
      </div>
      <div class="d-flex justify-content-md-end align-items-center flex-wrap justify-content-center">
        <div class="buttons">
          <button mat-flat-button routerLink="../list">Liste</button>
          <button routerLink="../week" [class.active]="true" mat-flat-button>
            Hafta
          </button>
          <button routerLink="../month" mat-flat-button>Ay</button>
        </div>
        <div>
        </div>
        <div>
            <button routerLink="/notifications" mat-icon-button><mat-icon>notifications</mat-icon></button>
        </div>
        <div>
          <button
            mat-flat-button
            class="button-primary"
            [routerLink]="['/events']"
          >
            Etkinlikleri Keşfet
          </button>
        </div>
      </div>
    </div>
    <div class="contents">
      <calendar-week #calendar></calendar-week>
    </div>
  </div>
</main-content>

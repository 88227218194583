import { Component, OnInit } from '@angular/core';
import { PeopleService } from '@common/services/people.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { User } from 'src/app/models/user';

@UntilDestroy()
@Component({
  selector: 'people-home',
  templateUrl: './people-home.component.html',
  styleUrls: ['./people-home.component.scss', '../panel.scss'],
})
export class PeopleHomeComponent implements OnInit {
  constructor(public peopleService: PeopleService) {}

  public people$ = this.peopleService
    .getIndex()
    .pipe(map((response) => response.data));
  public data: {
    following: User[];
    suggestions: User[];
    followingYou: User[];
  };
  public loading = true;

  ngOnInit(): void {
    this.people$.pipe(untilDestroyed(this)).subscribe({
      next: (people) => {
        this.data = people;
        this.loading = false;
      },
    });
  }
}

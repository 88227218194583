<!--        POSTS        -->
<div class="container" *ngIf="items">
  <div class="highlights">
    <div class="text-container">
      <p>
        Gönderiler
        <a
          [routerLink]="['/user-posts']"
          routerLinkActive="router-link-active"
          class="button-filter"
          >Hepsini Gör</a
        >
      </p>
    </div>
  </div>
  <div class="content">
    
    <div *ngIf="items?.length > 0 else noResult">
     <slider>
      <slider-item *ngFor="let item of items">
        <featured-content [item]="item"></featured-content>
      </slider-item>
    </slider>
    </div>
    <ng-template #noResult>
      <div class="no-result">
        <p>Aradığınız kriterlere uygun paylaşım bulunamadı.</p>
      </div>
    </ng-template>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { PeopleService } from '@common/services/people.service';
import { map } from 'rxjs';

@Component({
  selector: 'people-students',
  templateUrl: './people-students.component.html',
  styleUrls: ['./people-students.component.scss']
})
export class PeopleStudentsComponent implements OnInit {

  constructor(public peopleService:PeopleService) { }

  public people$ = this.peopleService.getStudents().pipe(map(response => response.data));

  ngOnInit(): void {
  }

}

<div class="container">
  <mat-card appearance="outlined" class="profile-card card">
    <div class="title">
      <h2>Projeler</h2>
      <div>
        <div class="d-flex align-items-center" style="gap: 10px">
          <button
            mat-button
            color="primary"
            *ngIf="isMe"
            [routerLink]="['/projects']"
          >
            <mat-icon>nature</mat-icon> Projelerime Git
          </button>
          <div class="d-flex align-items-center" style="gap: 10px">
            <button
              type="button"
              mat-icon-button
              class="slider-button"
              (click)="slider.scrollPrev()"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              class="slider-button"
              (click)="slider.scrollNext()"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slider [controls]="false" #slider>
        <slider-item *ngFor="let project of projects">
          <mat-card appearance="outlined" class="project-card z-elevation-0">
            <img
              class="project-image"
              [src]="project.project?.images[0] | imageFile"
            />
            <div class="project-details">
              <h3>{{ project.project.name }}</h3>
              <ng-container
                *ngIf="
                  project.project.participants &&
                  project.project.participants.length > 0
                "
              >
                <div class="workers-area">
                  <div class="workers">
                    <img
                      class="worker"
                      *ngFor="
                        let worker of project.project.participants;
                        let i = index
                      "
                      [src]="worker.user?.avatar"
                      alt=""
                      [style.z-index]="3 - i"
                    />
                    <div
                      *ngIf="project.project.participants.length > 3"
                      class="worker more-worker"
                      [style.z-index]="0"
                    >
                      +{{ project.participants.length - 3 }}
                    </div>
                  </div>
                  <div class="workers-text"></div>
                </div>
              </ng-container>
              <p class="status">
                {{ project.project.startDate | dateLocale }} •
                {{ project.project.endDate | dateLocale }}
              </p>
              <div class="description">
                <p>{{ project.project.description | slice : 0 : 150 }}</p>
              </div>
              <div class="actions row">
                <div class="col-sm">
                  <button
                    routerLink="/project/{{ project.project.id }}"
                    style="width: 100%"
                    mat-flat-button
                    color="primary"
                    class="action mat-elevation-z0"
                  >
                    Detaylar
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </slider-item>
      </slider>
    </div>

    <!-- <div class="projects-area">
          <div>
            <h3>Devam Edenler</h3>
            <div class="row">
              <div
                class="col-sm-4"
                *ngFor="let project of projects.inProgress"
              >
                <div class="project">
                  <img
                    class="project-image"
                    [src]="project.image"
                    alt="Project Image"
                  />
                  <h4 class="project-title">{{ project.title }}</h4>
                  <p class="project-info">
                    20 Ocak 2023 - 30 Mart 2023 (Tahmini) • Devam Ediyor
                  </p>
                  <div class="project-parent">
                    <img [src]="project.parent.image" alt="Parent Image" />
                    <p class="project-parent-name">
                      <span>{{ project.parent.name }}</span> Topluluğu
                      Kapsamında
                    </p>
                  </div>
                  <p class="project-description">{{ project.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3>Tamamlananlar</h3>
            <div class="row">
              <div
                class="col-sm-4"
                *ngFor="let project of projects.completed"
              >
                <div class="project">
                  <img
                    class="project-image"
                    [src]="project.image"
                    alt="Project Image"
                  />
                  <h4 class="project-title">{{ project.title }}</h4>
                  <p class="project-info">
                    20 Ocak 2023 - 30 Mart 2023 (Tahmini) • Devam Ediyor
                  </p>
                  <div class="project-parent">
                    <img [src]="project.parent.image" alt="Parent Image" />
                    <p class="project-parent-name">
                      <span>{{ project.parent.name }}</span> Topluluğu
                      Kapsamında
                    </p>
                  </div>
                  <p class="project-description">{{ project.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
  </mat-card>
</div>

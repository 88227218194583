import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit{
  
  @Input() projects: any[];
  @Input() isMe: boolean;

  ngOnInit(): void {
    console.log(this.projects)
  }
}

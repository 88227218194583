<main-content>
  <div class="header">
    <div class="container">
      <h1>Projeler</h1>
      <mat-tab-group>
        <mat-tab label="Ana Sayfa"> <projects-home></projects-home></mat-tab>
        <mat-tab label="Topluluklarımdan"
          ><projects-from-communities></projects-from-communities>
        </mat-tab>
        <mat-tab label="Arkadaşlarımdan">
          <projects-from-friends></projects-from-friends>
        </mat-tab>
        <mat-tab label="Projelerim"> <my-projects></my-projects></mat-tab>
      </mat-tab-group>
    </div>
  </div>
</main-content>

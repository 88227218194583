<!--      PEOPLE      -->
<div class="container">
  <div class="highlights">
    <div class="text-container">
      <p>
        Kişiler
        <a
          [routerLink]="['/people-extended']"
          routerLinkActive="router-link-active"
          href="#filter"
          class="button-filter"
          >Hepsini Gör</a
        >
      </p>
    </div>
  </div>
  <ng-container *ngIf="items?.length > 0 else noResult">
    <mat-card style="margin-top: 10px;" appearance="outlined" class="user-card hover-effect-card" *ngFor="let item of items" [routerLink]="['../user-profile',item.name]">
      <mat-card-header>
        <img
          mat-card-md-image
          [src]="item.avatar"
          alt="Image of a Shiba Inu"
          class="container-card-img"
          style="aspect-ratio: 16/3; object-fit: cover;"
        />
        <mat-card-title-group>
          <mat-card-title>{{item.first_name}} {{item.last_name}}</mat-card-title>
          <mat-card-subtitle>
            <mat-card-content>
             {{item.headnote}}
            </mat-card-content>
          </mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <br>
  </ng-container>
  <ng-template #noResult>
    <div class="no-result">
      <p>Aradığınız kriterlere uygun kişi bulunamadı.</p>
    </div>
  </ng-template>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'user-highlights',
  templateUrl: './user-highlights.component.html',
  styleUrls: ['./user-highlights.component.scss'],
})
export class UserHighlightsComponent {
  constructor() {}

  ngOnInit(): void {}
}

<main-content>
  <ng-container *ngIf="communities && !loading">
    <div class="header">
      <div class="container">
        <h1>Topluluklar</h1>
        <div class="content">
          @if (communities.joined?.length) {
          <div class="d-flex justify-content-between">
            <h2>Üye Olduğum Topluluklarım</h2>
            <a
              [routerLink]="['/communities-extended']"
              routerLinkActive="router-link-active"
              mat-button
              color="primary"
              >Hepsini gör</a
            >
          </div>

          <div class="joined">
            <slider>
              <slider-item *ngFor="let community of communities.joined">
                <community-card-big
                  [community]="community"
                ></community-card-big>
              </slider-item>
            </slider>
          </div>
          } @else {
          <div class="row">
            <div class="col-md-6" style="padding: 0 5%;">
              <media-image
                src="assets/img/community-3.png"
                [fill]="true"
                [priority]="true"
              ></media-image>
            </div>
            <div class="col-md-6">
              <h2>Henüz bir topluluğa üye olmadınız</h2>
              <p>Tüm toplulukları keşif kataloğu ile keşfedebilirsiniz.</p>
              <a
                [routerLink]="['/discovery-catalog']"
                routerLinkActive="router-link-active"
                mat-button
                color="primary"
                >Kataloğa Git</a
              >
            </div>
          </div>
          }

          <info-card
            [routerLink]="['/projects']"
            routerLinkActive="router-link-active"
            title="Toplulukların projelerini keşfet"
            icon="rocket-launch"
            text="Üyesi olduğun toplulukların yeni projelerini keşfet ve ilgini çeken projeler yapan yeni toplulukları takip et."
            buttonText="Projeleri Keşfet"
          ></info-card>
          <div class="following-area">
            <h2>Takip ettiğim topluluklarım</h2>
            <div class="followed row">
              <div
                class="col-md-4 col-sm-6"
                *ngFor="let community of communities.followed"
              >
                <community-card-small
                  [community]="community"
                ></community-card-small>
              </div>
            </div>
          </div>
          <div class="following-area">
            <h2>Önerilen Topluluklar</h2>
            <div class="followed row">
              <div
                class="col-md-4 col-sm-6"
                *ngFor="let community of communities.suggestions"
              >
                <community-card-small
                  [community]="community"
                ></community-card-small>
              </div>
            </div>
          </div>
        </div>
      </div></div
  ></ng-container>
  <div class="loading container" *ngIf="loading">
    <!-- Title Loader -->
    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{
          width: '300px',
          height: '40px'
        }"
        [animation]="true"
      ></ngx-skeleton-loader>
    </div>

    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{
          width: '300px',
          height: '30px'
        }"
        [animation]="true"
      ></ngx-skeleton-loader>
    </div>

    <div class="mt-4 row">
      <div class="col-md-3" *ngFor="let _ of [1, 2, 3, 4]">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '300px'
          }"
          [animation]="true"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '100px'
        }"
        [animation]="true"
      ></ngx-skeleton-loader>
    </div>
    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{
          width: '300px',
          height: '30px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <error-page *ngIf="error" [error]="error"></error-page>
</main-content>

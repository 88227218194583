import { pipe } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { CalendarService } from '@common/services/calendar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'personal-calendar-list',
  templateUrl: './personal-calendar-list.component.html',
  styleUrls: ['./personal-calendar-list.component.scss' , '../panel.scss'],
})
export class PersonalCalendarListComponent implements OnInit {
  constructor(public calendarService: CalendarService) {}
  public loading = true;
  public list: any[];

  ngOnInit(): void {
    this.calendarService
      .getList()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.list = data.data;
      });
  }
}

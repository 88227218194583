import { Component, OnInit } from '@angular/core';

import { AuthService } from '@common/auth/auth.service';
import { EventsHomeData, EventsService } from '@common/services/events.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  constructor(public auth: AuthService, public eventsService: EventsService) {}

  public events$ = this.eventsService
    .getHome()
    .pipe(map((response) => response.data));

  public loading = true;
  public events: EventsHomeData;

  public community = {
    name: 'AlecTED',
    backdrop: 'assets/img/alekted.png',
    avatar: 'assets/img/alekted-avatar.jpeg',
    shortDesc: 'TED University Alternative Energy Car Team',
  };

  public communities = [
    this.community,
    this.community,
    this.community,
    this.community,
  ];

  ngOnInit(): void {
    this.events$.pipe(untilDestroyed(this)).subscribe({
      next: (data) => {
        this.events = data;
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}

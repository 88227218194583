<main-content>
  <div class="container">
    <div class="header">
      <div class="d-flex justify-content-between align-items-center">
        <h1 style="margin: 0">Bildirimler</h1>
        <paginator [key]="key"></paginator>
      </div>
    </div>
    <div class="st" *ngIf="!paginator.loadings.get(key)">
      <mat-card
        class="mt-4"
        *ngFor="let notification of paginator.datas.get(key)"
      >
        <mat-card-header>
          <mat-card-title>{{ notification.title }}</mat-card-title>
          <mat-card-subtitle>{{ notification.body }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button
            *ngIf="!notification.accepted"
            mat-flat-button
            color="primary"
            (click)="accept(notification)"
            [loading]="notification.loading"
          >
            Kabul Et
          </button>
          <button
            mat-button
            color="primary"
            (click)="remove(notification)"
            [loading]="notification.loading"
          >
            Sil
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="st" *ngIf="paginator.loadings.get(key)">
      <div
        *ngFor="let a of [].constructor(10)"
        style="
          background-color: var(--surface-1);
          border-radius: 7px;
          padding: 16px;
          margin-top: 16px;
        "
      >
        <ngx-skeleton-loader
          [theme]="{ height: '18px', width: '400px' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
          [theme]="{ height: '14px', width: '200px' }"
        ></ngx-skeleton-loader>

        <div class="d-flex" style="gap: 16px">
          <ngx-skeleton-loader
            [theme]="{ height: '18px', width: '80px' }"
          ></ngx-skeleton-loader>

          <ngx-skeleton-loader
            [theme]="{ height: '18px', width: '80px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</main-content>

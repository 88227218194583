<main-content>
  <div class="container">
    <div class="search-result">
      <p>{{ query | async }} ile ilgili arama sonuçları</p>
    </div>
    <mat-tab-group *ngIf="searchResults$ | async as results" mat-stretch-tabs="false">
      <mat-tab label="Hepsi">
        <search-people
          *ngIf="results.users?.length > 0"
          [items]="results.users"
        ></search-people>
        <search-projects
          *ngIf="results.projects?.length > 0"
          [items]="results.projects"
        ></search-projects>
        <search-companies
          *ngIf="results.companies?.length > 0"
          [items]="results.companies"
        ></search-companies>
        <search-communities
          *ngIf="results.communities?.length > 0"
          [items]="results.communities"
        >
        </search-communities>
        <search-events
          *ngIf="results.events?.length > 0"
          [items]="results.events"
        ></search-events>
        <search-posts
          *ngIf="results.posts?.length > 0"
          [items]="results.posts"
        ></search-posts>
      </mat-tab>
      <mat-tab label="Gönderiler">
        <search-posts [items]="results.posts"></search-posts>
      </mat-tab>
      <mat-tab label="Kişiler">
        <search-people [items]="results.users"></search-people>
      </mat-tab>
      <mat-tab label="Topluluklar">
        <search-communities [items]="results.communities"></search-communities>
      </mat-tab>
      <mat-tab label="Projeler">
        <search-projects [items]="results.projects"></search-projects>
      </mat-tab>
      <mat-tab label="Şirketler">
        <search-companies [items]="results.companies"></search-companies>
      </mat-tab>
      <mat-tab label="Etkinlikler">
        <search-events [items]="results.events"></search-events>
      </mat-tab>
    </mat-tab-group>
  </div>
</main-content>

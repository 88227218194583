<div class="messages-menu">
  <div class="header">Mesajlar</div>
  <div class="new-message-box">
    <button mat-button (click)="messaging.openNewChatDialog()">
      <mat-icon>add</mat-icon>
      <span class="new-message-button">Yeni Görüşme</span>
    </button>
    <button mat-button>
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>
</div>
<div class="search-box">
  <text-input
    [placeholder]="'Ara'"
    [icon]="'search'"
    [(ngModel)]="filterText"
  ></text-input>
</div>
<div class="message-tabs">
  <mat-tab-group class="tab-group" mat-align-tabs="center">
    <mat-tab label="Hepsi">
      <div class="chats">
        <chat-box *ngFor="let room of rooms" [contact]="room"></chat-box>
      </div>
    </mat-tab>
    <mat-tab label="Üyeler">
      <div class="chats">
        <chat-box *ngFor="let room of users" [contact]="room"></chat-box>
      </div>
    </mat-tab>
    <mat-tab label="Gruplar">
      <div class="chats">
        <chat-box *ngFor="let room of groups" [contact]="room"></chat-box>
      </div>
    </mat-tab>
    <mat-tab label="Kurumlar ve Topluluklar">
      <div class="chats">
        <chat-box
          *ngFor="let room of organizations"
          [contact]="room"
        ></chat-box>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="Group108">
  <div class="Ellipse Ellipse38 animated4">
    <img
    class="community-logo"
    src="../../../../../assets/img/itucapella.png"
    alt=""
  />
  </div>
  <div class="Ellipse Ellipse37 animated8">
    <img
    class="community-logo"
    src="../../../../../assets/img/ggtyes.png"
    alt=""
  />
  </div>
  <div class="Ellipse Ellipse35 animated10">
    <img
      class="community-logo"
      src="../../../../../assets/img/ato.png"
      alt=""
    />
  </div>
  <div class="Ellipse Ellipse36 animated15">
    <img
      class="community-logo"
      src="../../../../../assets/img/alekted.png"
      alt=""
    />
  </div>
</div>

import { Component, Input } from '@angular/core';
import { JobPosting } from 'src/app/models/job-posting';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-post-card',
  templateUrl: './project-post-card.component.html',
  styleUrls: ['./project-post-card.component.scss']
})
export class ProjectPostCardComponent {
  @Input() data :Project

  public getMappedData() {
    return {
      type: 'Project',
      id: this.data.id,
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(public http: HttpClient) {}

  public getCountries() {
    return this.http.get<any[]>('https://restcountries.com/v3.1/all');
  }

  public getCities(country: string) {
    return this.http.post<{data:any[]}>('https://countriesnow.space/api/v0.1/countries/cities', {
      country,
    });
  }
}

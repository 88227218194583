import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-following',
  templateUrl: './user-following.component.html',
  styleUrls: ['./user-following.component.scss']
})
export class UserFollowingComponent {
  @Input() isMe: boolean;
  @Input() followings: any[];
  public selectedTab = 0;
}

<div class="container">
  <div class="title" *ngIf="data.title">
    <h2>{{ data.title }}</h2>
  </div>
</div>
<profile-editing-about
  *ngIf="data.selectedPart === 'about'"
></profile-editing-about>
<profile-editing-extra-sections
  *ngIf="data.selectedPart === 'extraSection'"
></profile-editing-extra-sections>
<profile-editing-featured-content
  *ngIf="data.selectedPart === 'featuredContent'"
></profile-editing-featured-content>
<div class="container mt-4">
  <div class="row">
    <div class="d-flex flex-wrap" style="gap: 20px">
      <button mat-flat-button (click)="closeBottomSheet()">İptal</button>
      <button mat-flat-button color="primary" (click)="save()">Kaydet</button>
    </div>
  </div>
</div>

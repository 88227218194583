import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'search-projects',
  templateUrl: './search-projects.component.html',
  styleUrls: ['./search-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchProjectsComponent implements OnInit {

  @Input('items') items : Project[]

  constructor() { }

  ngOnInit(): void {
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitText',
  standalone: true
})
export class LimitTextPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    const limit = args[0] || 100;
    const trail = args[1] || '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }

}

import { map } from 'rxjs';
import { DiscoveryCatalogService } from './../../../../../common/services/discovery-catalog.service';
import { Component, OnInit } from '@angular/core';
import { DiscoveryCatalogControllerService } from '../../discovery-catalog-controller.service';

@Component({
  selector: 'discovery-followed',
  templateUrl: './discovery-followed.component.html',
  styleUrls: ['./discovery-followed.component.scss'],
})
export class DiscoveryFollowedComponent implements OnInit {
  constructor(
    public discoveryCatalogService: DiscoveryCatalogService,
    public controller: DiscoveryCatalogControllerService
  ) {}

  public get data() {
    return this.controller.followedData;
  }

  public data$ = this.discoveryCatalogService
    .getFollowing()
    .pipe(map((data) => data.data));

  ngOnInit(): void {
    if (!this.controller.followedData) {
      this.data$.subscribe({
        next: (data) => {
          this.controller.followedData = data;
          this.controller.followedLoading = false;
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }
}

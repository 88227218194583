import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  inject,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { BootstrapDataService } from '@common/core/bootstrap/bootstrap-data.service';
import { AppHttpClient } from '@common/core/http/http.service';
import { MetaTagsService } from '@common/core/meta/meta-tags.service';
import { SettingsService } from '@common/core/settings/settings.service';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { BehaviorSubject, catchError, finalize, throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'unista';
  public loadingBootstrap$ = new BehaviorSubject(true);
  public bootstrapError$ = new BehaviorSubject<any>(null);

  private readonly document = inject(DOCUMENT);

  constructor(
    public auth: AuthService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public appTheme: AppThemeService,
    public bootstrapData: BootstrapDataService,
    public settings: SettingsService,
    public metaTagsService: MetaTagsService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private http: AppHttpClient,
    private r: Renderer2
  ) {
    iconRegistry.addSvgIcon(
      'google',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/google-logo.svg')
    );
    iconRegistry.addSvgIcon(
      'settings_alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/settings_alert.svg')
    );
    if (isPlatformBrowser(this.platformId)) {
      this.auth.init(location?.pathname?.replace('login', 'home') || 'home');
    } else {
      this.auth.init(this.router.url);
    }
  }

  ngOnInit() {
    console.log('app component init');
    this.document.body.classList.add('dark-theme');
    this.appTheme.changed$.subscribe((darkMode) => {
      if (darkMode) {
        this.document.body.classList.add('dark-theme');
        this.document.body.classList.remove('light-theme');
        this.document.body.style.backgroundColor = '#121212';
      } else {
        this.document.body.classList.remove('dark-theme');
        this.document.body.classList.add('light-theme');
      }
    });

    //this.metaTagsService.init();
    this.bootstrapData
      .init()
      .pipe(finalize(() => this.loadingBootstrap$.next(false)))
      .subscribe({
        next: (data) => {
          if (data.success) {
            this.settings.settings = data.data.settings;
            this.auth._members = data.data.members;
            if (data.data.user) {
              this.auth.handleLogin(
                {
                  success: true,
                  user: data.data.user,
                },
                false
              );
              this.auth.followedIds = data.data.followedIds;
              this.http.setToken(this.http.getToken());
            } else {
              this.auth.loading$.next(false);
              this.appTheme.loggedIn = false;
            }
            this.loadingBootstrap$.next(false);
            this.auth.loadingStatus$.next(false);
            this.auth.unreadNotifications = data.data.unreadNotifications;

            if (this.appTheme.darkMode) {
              this.document.body.classList.add('dark-theme');
              this.document.body.classList.remove('light-theme');
              this.document.body.style.backgroundColor = '#121212';
            } else {
              this.document.body.classList.remove('dark-theme');
              this.document.body.classList.add('light-theme');
            }

            console.log('user', this.auth.user);
          }
        },
        error: (error) => {
          console.error(error.status);
          this.bootstrapError$.next(error);
          this.http.setToken(undefined);
        },
      });
  }
}

<div class="highlights">
  <div class="texts">
    <ngx-skeleton-loader
      [theme]="{ width: '200px', height: '24px' }"
    ></ngx-skeleton-loader>
    <br />
    <ngx-skeleton-loader
      [theme]="{ width: '300px', height: '16px' }"
    ></ngx-skeleton-loader>
  </div>
  <div class="container-fluid">
    <slider>
      <slider-item [itemSize]="33.33" *ngFor="let _ of [].constructor(10)">
        <mat-card>
          <div class="title" style="padding: 16px">
            <ngx-skeleton-loader
              [theme]="{ width: '80%', height: '24px' }"
            ></ngx-skeleton-loader>
          </div>
          <div class="card-container">
            <div class="card-content d-flex">
              <div class="card-image">
                <ngx-skeleton-loader
                  appearance="circle"
                  [theme]="{ width: '80px', height: '80px' }"
                ></ngx-skeleton-loader>
              </div>
              <div class="card-name">
                <ngx-skeleton-loader
                  [theme]="{ width: '200px', height: '24px' }"
                ></ngx-skeleton-loader>

                <div style="margin-top: 8px">
                  <ngx-skeleton-loader
                    [theme]="{ width: '150px', height: '16px' }"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-column">
              <div *ngFor="let _ of [].constructor(5)">
                <ngx-skeleton-loader
                  [theme]="{ width: '200px', height: '24px' }"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </mat-card>
      </slider-item>
    </slider>
  </div>
</div>
<info-card
  title="Kendi İnsanlarınla Geleceği İnşa Et"
  icon="people"
  text="Unilocked’da edindiğin arkadaşların ile geleceği değiştirecek o projeyi yapın, yakın çevrenizdeki zeki <span> öğrenciler</span>i keşfedin."
  buttonText="Arkadaşları Keşfet"
  buttonColor="#4B53F2"
  class="info-card"
>
</info-card>
<div class="highlights">
    <div class="texts">
      <ngx-skeleton-loader
        [theme]="{ width: '200px', height: '24px' }"
      ></ngx-skeleton-loader>
      <br />
      <ngx-skeleton-loader
        [theme]="{ width: '300px', height: '16px' }"
      ></ngx-skeleton-loader>
    </div>
    <div class="container-fluid">
      <slider>
        <slider-item [itemSize]="33.33" *ngFor="let _ of [].constructor(10)">
          <mat-card>
            <div class="title" style="padding: 16px">
              <ngx-skeleton-loader
                [theme]="{ width: '80%', height: '24px' }"
              ></ngx-skeleton-loader>
            </div>
            <div class="card-container">
              <div class="card-content d-flex">
                <div class="card-image">
                  <ngx-skeleton-loader
                    appearance="circle"
                    [theme]="{ width: '80px', height: '80px' }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="card-name">
                  <ngx-skeleton-loader
                    [theme]="{ width: '200px', height: '24px' }"
                  ></ngx-skeleton-loader>
  
                  <div style="margin-top: 8px">
                    <ngx-skeleton-loader
                      [theme]="{ width: '150px', height: '16px' }"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center flex-column">
                <div *ngFor="let _ of [].constructor(5)">
                  <ngx-skeleton-loader
                    [theme]="{ width: '200px', height: '24px' }"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </mat-card>
        </slider-item>
      </slider>
    </div>
  </div>

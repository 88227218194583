<div class="container mt-2">
    <mat-card appearance="outlined" class="about card profile-card">
      <div class="title">
        <h2>Hakkında</h2>
        <button mat-button color="primary" *ngIf="isMe" (click)="openBottomSheet()">
          <mat-icon>edit</mat-icon> Düzenle
        </button>
      </div>
      <p class="about-content" [innerHTML]="user.about || ''">
      </p>   
      <div class="row">
        <div class="col-sm-6">
            <ng-container *ngIf="user.contactList && user.contactList.length>0">
                <span *ngFor="let contact of user.contactList">
                    <h4 class="mat-subtitle-2">{{contact.name | uppercase}}</h4>
                        {{contact.data}}
                </span>
            </ng-container>
          
        </div>
      </div>   
    </mat-card>
  </div>
import { Pipe, PipeTransform } from '@angular/core';
// turndown
import TurndownService from 'turndown';

@Pipe({
  name: 'convertToMd',
  standalone: true,
})
export class ConvertToMdPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const turndownService = new TurndownService();
    const before = value?.replace("<pre>", "<pre><code>").replace("</pre>", "</code></pre>");
    const converted = turndownService.turndown(before);
    //console.log('converted', converted);
    return converted;
  }
}


<div *ngIf="message.sender.id == userId else otherbox">
  <div class="user-box">
    <message-user [message]="message"></message-user>
  </div>
</div>
<ng-template #otherbox>
  <div class="other-box">
    <message-other [message]="message"></message-other>
  </div>
</ng-template>

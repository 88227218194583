<main-content>
  <ng-container *ngIf="job">
    <form [formGroup]="form" (submit)="submit()">
      <div class="container">
        <div>
          <h1>İlan Başvuru Formu</h1>
        </div>
        <div>
          <mat-card class="form-card top">
            <h3>Başvurulan ilan</h3>
            <div class="job-detials">
              <div class="left">
                <div class="image">
                  <img [src]="job.creator.avatar" alt="avatar" />
                </div>
                <div class="name-company">
                  <h2 class="name">{{ job.title }}</h2>
                  <p class="company">{{ job.creator.display_name }}</p>
                </div>
              </div>
              <div class="right">
                <button type="button" mat-flat-button class="button-other">
                  Detaylar
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <div>
          <mat-card class="form-card top">
            <h3>Temel Bilgiler</h3>
            <p>İsim, cinsiyet gibi temel bilgileriniz.</p>
            <div class="inputs">
              <div class="row">
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>İsim</mat-label>
                      <input matInput formControlName="first_name" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>Soyisim</mat-label>
                      <input matInput formControlName="last_name" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>Cinsiyet</mat-label>
                      <mat-select formControlName="gender">
                        <mat-option value="male">Erkek</mat-option>
                        <mat-option value="female">Kadın</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>TC Kimlik Numarası</mat-label>
                      <input matInput formControlName="id_number" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field *ngIf="countries$ | async as countries">
                      <mat-label>Ülke</mat-label>
                      <mat-select formControlName="country">
                        <mat-option
                          *ngFor="let county of countries"
                          [value]="county.name.common"
                          >{{ county.translations.tur.common }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field *ngIf="cities$ | async as countries">
                      <mat-label>Şehir</mat-label>
                      <mat-select formControlName="city">
                        <mat-option
                          *ngFor="let city of countries"
                          [value]="city"
                          >{{ city }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div>
          <mat-card class="form-card top">
            <h3>İletişim Bilgileri</h3>
            Eposta, numaranız gibi iletişim bilgileriniz.
            <div class="inputs">
              <div class="row">
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>E-posta Adresi</mat-label>
                      <input matInput formControlName="email" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input">
                    <mat-form-field>
                      <mat-label>Telefon Numarası</mat-label>
                      <input matInput formControlName="phone" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div>
          <mat-card class="form-card top">
            <h3>CV</h3>
            Lütfen pdf formatında CV dosyanızı yükleyin.
            <div class="mt-2">
              <button type="button" mat-flat-button color="primary" (click)="pcikResume()">Dosya Seç</button>
            </div>
            <div class="file mt-2" *ngIf="resume.value">
              <p>Seçilen Dosya: {{ resume?.value?.name }}</p>
            </div>
          </mat-card>
        </div>

        <div *ngIf="questionForm" [formGroup]="questionForm">
          <mat-card class="form-card top">
            <h3>İlan Soruları</h3>
            <p>
              İlan veren tarafından istenen bilgiler ve cevaplandırılması
              gereken sorular.
            </p>
            <div class="inputs">
              <div class="input" *ngFor="let question of job.questions">
                <ng-container *ngIf="question.questionType === 'TEXT'">
                  <p>{{ question.questionText }}</p>
                  <mat-form-field>
                    <mat-label>Cevap</mat-label>
                    <input matInput [formControlName]="question.id" />
                  </mat-form-field>
                </ng-container>
                <ng-container
                  *ngIf="question.questionType === 'MULTIPLECHOICE'"
                >
                  <p>{{ question.questionText }}</p>
                  <mat-form-field>
                    <mat-label>Cevap</mat-label>
                    <mat-select [formControlName]="question.id">
                      <mat-option
                        *ngFor="let option of question.options"
                        [value]="option"
                        >{{ option }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" [loading]="loading" mat-flat-button color="primary">
            Başvuruyu Tamamla
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</main-content>

import { filter } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Message } from 'src/app/models/messages/message';
import { OverlayPanel } from '@common/ui/overlay-panel/overlay-panel.service';
import { MessageFilePreviewComponent } from '../../message-file-preview/message-file-preview.component';

@Component({
  selector: 'message-images',
  templateUrl: './message-images.component.html',
  styleUrls: ['./message-images.component.scss'],
})
export class MessageImagesComponent {
  constructor(public overlay: OverlayPanel) {}

  @Input() public message: Message;

  public get images() {
    if (!this.message) return [];
    return this.message.files.filter((file) => file.type.includes('image'));
  }

  public get imagesHasMoreThanFour() {
    return this.images.length > 4;
  }

  public get imagesToShow() {
    return this.images.slice(0, 4);
  }

  public openImage(index: number) {
    this.overlay.open(MessageFilePreviewComponent, {
      origin: 'global',
      position: 'center',
      panelClass: 'player-overlay-container',
      hasBackdrop: false,
      fullScreen: true,
      data: {
        selectedIndex: index,
        message: this.message,
      }
    });
  }
}

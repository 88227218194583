import { Pipe, PipeTransform } from '@angular/core';
import { MessageMember } from 'src/app/models/messages/message-member';
import { MessageRoom } from 'src/app/models/messages/message-room';

@Pipe({
  name: 'makeMessageAdminButtonText',
})
export class MakeMessageAdminButtonTextPipe implements PipeTransform {
  transform(value: MessageMember, ...args: MessageRoom[]): string {
    if (
      args.length &&
      args[0].admins.find((admin) => admin.memberId == value.memberId)
    ) {
      return 'Yöneticilikten Al';
    } else {
      return 'Yönetici Yap';
    }
  }
}

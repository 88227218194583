<div class="container">
    <mat-card appearance="outlined" class="card profile-card" >
        <div class="title">
          <h2>Topluluklar</h2>
          <div>
            <div>
              <button mat-button color="primary" *ngIf="isMe">
                <mat-icon>people</mat-icon> Topluluklarıma Git
              </button>
             
            </div>
          </div>
        </div>
        <ng-container *ngFor="let community of filtered">
          <div class="community">
          
            <div class="avatar-container">
                <span class="avatar-card">
                    <img [src]="community?.profile.avatar">
                </span>        
                <div>      
                    <h4 class="mat-subtitle-1 description-text"> {{ community.profile.display_name }} </h4>
                    <p class="muted"> {{ community.profile?.headnote }}   </p>
                </div>
              </div>
              
              <!-- <p class="description-text" [innerHTML]="community.profile?.about |slice: 0:150">
                {{ community.profile.about?.length > 150 ? '...' : '' }}   
              </p> -->
          </div>
   
        </ng-container>

      </mat-card>    
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'discovery-loading-row',
  templateUrl: './discovery-loading-row.component.html',
  styleUrls: ['./discovery-loading-row.component.scss']
})
export class DiscoveryLoadingRowComponent {

}

<div
  class="d-flex company hover-effect"
  [routerLink]="['/company', company.id]"
>
  <img [src]="company.avatar" alt="" class="avatar" />
  <div>
    <h3 class="name">{{ company.name }}</h3>
    <p class="short-desc">{{ company.sector }} Şirketi</p>
    <p class="details">
      {{ company.followerCount }} Takipçi • {{ company.headnote }}
    </p>
    <follow-button [profile]="company" type="Company"></follow-button>
  </div>
</div>

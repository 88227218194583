<navbar></navbar>
<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <h1 class="header-404">503</h1>
            <p class="title">Unilocked Servislerine Ulaşılamıyor</p>
            <p class="subtitle">Unilocked Servisleri geçici olarak devre dışıdır.</p>
            <div class="subtext">
                <p class="subtext-inline">
                    Çeşitli sebeplerden dolayı servislerimiz devre dışıdır. Çözüm için:
                </p>
                <ul>
                    <li>
                        Sayfayı yenilemeyi deneyebilirsiniz
                    </li>
                    <li>
                        Sosyal medya adreslerimizden ve destek sayfamızdan servis durumlarını görüntüleyebilirsiniz
                    </li>
                </ul>
            </div>
            <div class="bottom-container">
                <button mat-raised-button color="primary" class="small-button first-button">Destek - Servis Durumları</button>
                <button mat-raised-button color="primary" class="small-button second-button">Destek - Servis Durumları</button>
            </div>
        </div>
    </div>
</div>
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserInterestsComponent } from './panels/user-interests/user-interests.component';

@Component({
  selector: 'discovery-catalog',
  templateUrl: './discovery-catalog.component.html',
  styleUrls: ['./discovery-catalog.component.scss'],
})
export class DiscoveryCatalogComponent implements OnInit {
  constructor(
    public discoveryCatalogService: DiscoveryCatalogService,
    private activatedRoute: ActivatedRoute,
    private dialog:MatDialog
  ) {}

  public tabIndex$ = this.activatedRoute.queryParams.pipe(
    map((params) => {
      if (params['search']) {
        return 2;
      } else if (params['index']) {
        return params['index'];
      } else {
        return 0;
      }
    })
  );

  public selectedTagsBtoa$ = this.discoveryCatalogService.selectedTags.pipe(
    map((tags) => {
      return {
        search:btoa(encodeURIComponent(JSON.stringify(tags)))
      }
    })
  );

  ngOnInit(): void {
    this.discoveryCatalogService.selectedTags.subscribe((tags) => {
      const selectedTagsDiv = document.querySelector(
        '.selected-tags'
      ) as HTMLElement;
      if (selectedTagsDiv) {
        if (tags.length > 0) {
          selectedTagsDiv.style.opacity = '1';
          selectedTagsDiv.style.zIndex = '12';
        } else {
          selectedTagsDiv.style.opacity = '0';
          selectedTagsDiv.style.zIndex = '0';
        }
      }
    });
  }

  public openInterests() {
    this.dialog.open(UserInterestsComponent)
  }
}

import { ProfileEditingService } from './../../../../admin/profile-editing/profile-editing.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { User } from 'src/app/models/user';

@Component({
  selector: 'edit-profile-bottom-sheet',
  templateUrl: './edit-profile-bottom-sheet.component.html',
  styleUrls: ['./edit-profile-bottom-sheet.component.scss'],
})
export class EditProfileBottomSheetComponent implements OnInit {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<EditProfileBottomSheetComponent>,
    private profileEditingService: ProfileEditingService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      selectedPart: string;
      profile: User;
      title?:string;
    }
  ) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit(): void {
    this.profileEditingService.init(this.data.profile);
  }

  public closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }

  public save() {
    this.profileEditingService.submit(this.data.profile);
    this.closeBottomSheet();
  }
}

<mat-card class="blog-card hover-effect-card" [routerLink]="['/blog',blog.id]" (click)="scrollTop()">
    <div class="card-content">
        <div class="card-image">
            <media-image [fill]="true" [src]="blog.image" alt="" appereance="rounded" />
        </div>
        <div class="tags">
            <mat-chip-set>
                <mat-chip *ngFor="let tag of limitedTags()">{{tag.display_name}}</mat-chip>
                
            </mat-chip-set>
        </div>
        <div class="title">
            <h2>{{blog.title}}</h2>
        </div>

        <div class="content">
            <p>{{(blog.body) | replaceHtmlTags | limitText}} </p>
        </div>
        <div style="flex:1 1 auto;"></div>
        <div class="info">
            <p>{{blog.user.display_name || blog.user.name}} tarafından • {{blog.created_at | dateAgo}}</p>
        </div>
    </div>
</mat-card>
<main-content>
  <div class="container">
    <h4>Kişiler</h4>
    <mat-tab-group>
      <mat-tab label="Takip Ettikleriniz">
        <div class="content" *ngIf="following$ | async as following">
          <div class="d-flex justify-content-between">
            <h2>Takip Ettiklerim</h2>
          </div>
          <div class="joined">
            <div class="container">
              <div class="row">
                <div class="col-sm-4" *ngFor="let item of following">
                  <person-card-big [person]="item"></person-card-big>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Takipçiler">
        <div class="content" *ngIf="followers$ | async as followers">
          <div class="d-flex justify-content-between">
            <h2>Takipçilerim</h2>
          </div>
          <div class="joined">
            <div class="container">
              <div class="row">
                <div class="col-sm-4" *ngFor="let item of followers">
                  <person-card-big [person]="item"></person-card-big>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Bağlantılar">
        <div class="content" *ngIf="connections$ | async as connections">
          <div class="d-flex justify-content-between">
            <h2>Bağlantılarım</h2>
          </div>
          <div class="joined">
            <div class="container">
              <div class="row">
                <div class="col-sm-4" *ngFor="let item of connections">
                  <person-card-big [person]="item"></person-card-big>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</main-content>

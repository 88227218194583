<div class="main-container">
  <mat-drawer-container class="example-container">
    <mat-drawer #drawer1 class="drawer" [mode]="(isMobile | async) ? 'over' : 'side'" [opened]="true">
      <messages-menu></messages-menu>
    </mat-drawer>
    <mat-drawer-content class="content">
      <mat-drawer-container>
        <mat-drawer #drawer2 class="drawer2" [mode]="(isMobile | async) ? 'over' : 'side'" [opened]="false" position="end">
          <message-chat-details *ngIf="messaging.currentMenu == 'roomDetails'"></message-chat-details>
        </mat-drawer>
        <mat-drawer-content class="content messages-background">
          <div class="menu-button">
            <button mat-icon-button (click)="drawer1.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="right-menu-button">
            <button mat-icon-button (click)="drawer2.toggle()">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
          <messages-box></messages-box>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Notification } from 'src/app/models/notification';
import { Pagination } from 'src/app/models/pagination';

const BASE = 'notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http:AppHttpClient
  ) { }

  public getAll(page:number) {
    return this.http.get<StandartResponse<Pagination<Notification>>>(`${BASE}/all`, {page});
  }

  public accept(id:string) {
    return this.http.post<StandartResponse<any>>(`${BASE}/accept/${id}`);
  }

  public delete(id:string) {
    return this.http.delete<StandartResponse<any>>(`${BASE}/delete/${id}`);
  }
}

import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: AppHttpClient) {}

  public getList() {
    return this.http.get<
      StandartResponse<
        {
          date: string;
          items: any[];
        }[]
      >
    >('calendar/list');
  }

  public getMonth(startDate: string) {
    return this.http.post<
      StandartResponse<
        {
          date: Date;
          items: any[];
        }[]
      >
    >('calendar/month', {
      startDate,
    });
  }

  public getWeek(startDate: string) {
    return this.http.post<
      StandartResponse<
        {
          date: string;
          items: any[];
        }[]
      >
    >('calendar/week', {
      startDate,
    });
  }
}

<ng-container *ngIf="projects$ | async as projects">
  <div class="content container">
    @if (projects?.joined?.length) {
    <div class="d-flex justify-content-between">
      <h2>Katılımcı olduklarım</h2>
      <a
        [routerLink]="['/projects-extended']"
        routerLinkActive="router-link-active"
        mat-button
        color="primary"
        >Hepsini gör</a
      >
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of projects.joined">
        <project-card [project]="project"></project-card>
      </div>
    </div>
    } @else {
    <div class="row">
      <div class="col-md-6" style="padding: 0 5%">
        <media-image
          src="assets/img/community.png"
          [fill]="true"
          [priority]="true"
        ></media-image>
      </div>
      <div class="col-md-6">
        <h2>Topluluklarınızdan katıldığınız proje yok</h2>
        <p>Tüm projeleri keşif kataloğu ile keşfedebilirsiniz.</p>
        <a
          [routerLink]="['/discovery-catalog']"
          routerLinkActive="router-link-active"
          mat-button
          color="primary"
          >Kataloğa Git</a
        >
      </div>
    </div>
    }
    <info-card
      icon="people"
      title="Yeni Topluluklar Bulun"
      text="Farklı projeler yapan, yetenekli öğrencilerin bulunduğu toplulukları keşfedin, bir parçası olun ve devrim yaratacak yeni bir projenin öncüsü olun."
      buttonText="Toplukuları Keşşfet"
    ></info-card>
    @if (projects?.suggestions?.length) {
      <div class="d-flex justify-content-between mt-4">
        <h2>Topluluklarımdan Önerilen Projeler</h2>
      </div>
      <div class="row">
        <div class="col-sm-6" *ngFor="let project of projects.suggestions">
          <project-card [project]="project"></project-card>
        </div>
      </div>
    }
  </div>
</ng-container>

<div class="main-container">
    <div class="badge">
        ÖNE ÇIKANLAR
    </div>
    <div class="title">
        En Çok Okunan ve Takip Edilen
        Blog Gönderileri
    </div>
    <div class="blog-posts" #blogPosts>
       
          <ng-container *ngIf="posts">
            <div *ngFor="let post of posts" class="blog-tip-card">
              <img class="card-image" width="100%" height="70%" [src]="post.image" alt="Photo of a Shiba Inu">
              <div class="title-div">            
                 <span class="card-title"> {{post.title}} </span>
                  <span class="card-icon"> <img [src]="post.icon"/> </span>
              </div>
              <div>
                <p>
                  {{post.content}}
                </p>
              </div>
              <div class="blog-note">
                 {{post.subnote}}
              </div>          
          </div>
          </ng-container>
            
      
        
    </div>
    <div class="d-flex justify-content-end mx-3">
      <div class="arrows">
        <button mat-flat-button (click)="slide(false)"><mat-icon>arrow_back_ios</mat-icon></button>
      <button mat-flat-button (click)="slide(true)"><mat-icon>arrow_forward_ios</mat-icon></button>
      </div>
      
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AppCommonModule } from '@common/common.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { ConvertToMdPipe } from './convert-to-md.pipe';
import { LimitTextPipe } from './limit-text.pipe';
import { ReplaceHtmlTagsPipe } from './replace-html-tags.pipe';
import { YoumightknowComponent } from "../../../common/ui/youmightknow/youmightknow.component";

@NgModule({
  declarations: [
    BlogDetailsComponent,
    BlogsComponent,
    BlogCardComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    RouterModule,
    MarkdownModule.forChild(),
    ConvertToMdPipe,
    LimitTextPipe,
    ReplaceHtmlTagsPipe,
    YoumightknowComponent
]
})
export class BlogModule { }


<ng-container *ngIf="popularCards">
    <div class="carousel-primary">
       
        <div *ngFor="let card of popularCards" class="pop-card">
            <div class="card-header">
              <div class="avatar">
                  <img [src]="card.image"/>
                </div>
              <div class="title">{{card.title}}
                  <div class="sub-title">{{card.subtitle}}</div>
  
              </div>
              <div class="header-action">
                <button mat-flat-button><mat-icon>more_horiz</mat-icon></button>
              </div>
            </div>
            <div class="card-content" [innerHTML]="card.content">
                
            </div>
            <div class="card-footer">
                <div> <button mat-flat-button><mat-icon>thumb_up</mat-icon></button> <span class="badge">{{card.like}}</span> </div>
                <div> <button mat-flat-button><mat-icon>chat_bubble</mat-icon></button> <span class="badge">{{card.comment}}</span> </div>
          
            </div>
          </div>
      </div>
      
</ng-container>



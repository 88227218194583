import { Component } from '@angular/core';
import { MessagingService } from '../../messaging.service';
import { MessageRoom, RoomType } from 'src/app/models/messages/message-room';
import { AuthService } from '@common/auth/auth.service';
import { UploadService } from '@common/core/upload/upload.service';
import { imageFile } from '@common/core/pipes/image-file.pipe';

@Component({
  selector: 'message-chat-details',
  templateUrl: './message-chat-details.component.html',
  styleUrls: ['./message-chat-details.component.scss'],
})
export class MessageChatDetailsComponent {
  constructor(
    public messagingService: MessagingService,
    public auth: AuthService,
    public upload:UploadService
  ) {}

  public editName = false;
  public editDescription = false;
  public editable(room: MessageRoom) {
    console.log(room);
    if (room.roomType == RoomType.DIRECT_MESSAGE) {
      return false;
    }
    const member = (room?.admins || []).find(
      (member) => member.memberId == this.auth.selectedProfile.id
    );
    const isAdmin = member ? true : false;
    return isAdmin;
  }

  public selectImage(room:MessageRoom) {
    if (this.editable(room)) {
      this.upload.openUploadDialog('image',false,(files) => {
        const file = files[0];
        room.image = imageFile(file)
        room.imageFile = file;
        this.saveRoom(room);
      })
    }
  }

  public saveRoom(room: MessageRoom) {
    this.editName = false;
    this.messagingService.updateRoom(room).subscribe()
  }
}

<main-content>
  @if(blog) {
  <div class="container">
    <div class="top-container">
      <div>
        <div class="tags">
          <mat-chip-set>
            <mat-chip *ngFor="let tag of blog.blog.tags">{{
              tag.display_name
            }}</mat-chip>
          </mat-chip-set>
        </div>
        <div class="title mt-4">
          <h1>{{ blog.blog.title }}</h1>
        </div>
        <div class="info">
          <p>
            {{ blog.blog.user.display_name || blog.blog.user.name }}
            tarafından • {{ blog.blog.created_at | dateAgo }}
          </p>
        </div>
      </div>
    </div>
    <div class="image">
      <media-image [fill]="true" [priority]="true" [src]="blog.blog.image" alt="" />
    </div>
    <mat-card class="user-area">
      <div class="user-area-content">
        <div class="left">
          <div class="avatar">
            <img
              [src]="
                blog.blog.user.avatarFile
                  | imageFile : 'thumbnail' : blog.blog.user.avatar
              "
              alt=""
            />
          </div>
        </div>
        <div class="mid">
          <div class="user-name">
            <h3>
              {{ blog.blog.user.display_name || blog.blog.user.name }}
            </h3>
          </div>
          <div class="headnote">
            <p>{{ blog.blog.user.headnote }}</p>
          </div>
        </div>
        <div class="right">
          <button mat-flat-button color="primary" class="button-primary">
            Takip Et
          </button>
        </div>
      </div>
    </mat-card>
    <div class="blog-content">
      <markdown [data]="blog.blog.body | convertToMd" (ready)="onReady()">
      </markdown>
    </div>
    <div class="from-user">
      <h2>
        {{ blog.blog.user.display_name || blog.blog.user.name }}
        tarafından
      </h2>
      <p class="sub">
        Aynı oluşturucu tarafından yazılan başka blog gönderileri
      </p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4" *ngFor="let blog of blog.blogsFromSameAuthor">
          <blog-card [blog]="blog"></blog-card>
        </div>
      </div>
    </div>
  </div>
  }
</main-content>

<mat-card class="card">
  <mat-card-title class="title">Etkinlik</mat-card-title>

  <div class="content-bar">
    <div class="profile-color-bar">
      <div class="bar" *ngFor="let color of colors">
        <div
          *ngFor="let item of items"
          style="width: 8px; height: 8px;  background: {{color}}"
        ></div>
      </div>
    </div>

    <mat-card-header class="header">
      <mat-card-title class="header-title">{{ data.name }}</mat-card-title>

      <mat-card-subtitle class="header-subtitle">{{
        data.headnote
      }}</mat-card-subtitle>

      <mat-card-subtitle class="header-subtitle">
        <div class="icon"><mat-icon>calendar_today</mat-icon></div>
        <div class="date-text">{{ data.startDate | dateDayMonth }}</div>
      </mat-card-subtitle>

      <mat-card-subtitle class="header-subtitle">
        <div class="icon"><mat-icon> location_on</mat-icon></div>
        <div class="location-text">{{ data.location }}</div>
      </mat-card-subtitle>
    </mat-card-header>
  </div>

  <mat-card-content class="content">
    <main-text-card></main-text-card>
  </mat-card-content>
  <like-save-card [data]="getMappedData()"></like-save-card>
</mat-card>

import { Component } from '@angular/core';
import { FollowersService } from '@common/services/followers.service';
import { map } from 'rxjs';

@Component({
  selector: 'people-extended',
  templateUrl: './people-extended.component.html',
  styleUrls: ['./people-extended.component.scss'],
})
export class PeopleExtendedComponent {
  constructor(public followers: FollowersService) {}

  public following$ = this.followers
    .getFollowing()
    .pipe(map((res) => res.data));
  public followers$ = this.followers
    .getMyFollowers()
    .pipe(map((res) => res.data));
  public connections$ = this.followers
    .getConnections()
    .pipe(map((res) => res.data));
}

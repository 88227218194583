import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayPanel } from '@common/ui/overlay-panel/overlay-panel.service';
import { filter, switchMap } from 'rxjs';
import { File } from 'src/app/models/file';
import { Message } from 'src/app/models/messages/message';
import { MessagingService } from '../../messaging.service';
import { MessageFilePreviewComponent } from '../message-file-preview/message-file-preview.component';

@Component({
  selector: 'message-chat-files',
  templateUrl: './message-chat-files.component.html',
  styleUrls: ['./message-chat-files.component.scss'],
})
export class MessageChatFilesComponent implements OnInit , OnDestroy{
  constructor(
    public messaging: MessagingService,
    public overlay: OverlayPanel
  ) {}

  public subscription;
  public media: File[] = [];
  public files: File[] = [];
  public messages: Message[] = [];

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.messaging.currentRoom$
    .pipe(filter((room) => room?.id))
      .pipe(
        switchMap((room) => {
          return this.messaging.getMessagesHasFiles(room.id);
        })
      )
      .subscribe((messages) => {
        this.messages = messages;
        for (let message of messages) {
          if (message.files) {
            for (let file of message.files) {
              if (file.type.includes('image') || file.type.includes('video')) {
                if (this.media.length < 4) {
                  console.log('file', file);
                  this.media.push(file);
                }
              } else {
                if (this.files.length < 4) {
                  this.files.push(file);
                }
              }
            }
          }
        }
      });
  }

  public openImage(file: File) {
    const message: Message = this.messages.find((m) =>
      m.files.find((f) => f.id === file.id)
    );
    const index = message.files.findIndex((f) => f.id === file.id);
    this.overlay.open(MessageFilePreviewComponent, {
      origin: 'global',
      position: 'center',
      panelClass: 'player-overlay-container',
      hasBackdrop: false,
      fullScreen: true,
      data: {
        selectedIndex: index,
        message: message,
      },
    });
  }
}

import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'person-card-small',
  templateUrl: './person-card-small.component.html',
  styleUrls: ['./person-card-small.component.scss']
})
export class PersonCardSmallComponent {

  @Input() public person: User;

}

<div class="container">
  <mat-card appearance="outlined" class="about card profile-card">
    <div class="title">
      <h2>Takip Edilenler</h2>
    </div>

    <div class="stepper-container">
      <div class="customStepper flex-wrap">
        <span
          (click)="tabs.selectedIndex = 0"
          [class.activeStep]="selectedTab === 0"
          class="step"
        >
          Kişiler</span
        >
        <span
          (click)="tabs.selectedIndex = 1"
          [class.activeStep]="selectedTab === 1"
          class="step"
        >
          Şirketler</span
        >
        <span
          (click)="tabs.selectedIndex = 2"
          [class.activeStep]="selectedTab === 2"
          class="step"
        >
          Topluluklar</span
        >
      </div>
    </div>
    <mat-tab-group
      class="tabs"
      #tabs
      (selectedTabChange)="selectedTab = $event.index"
    >
      <mat-tab label="Kişiler">
        <div class="row row-cols-2 m-1">
          <ng-container
            *ngFor="
              let following of followings
                | usertypefilter : 'User'
                | slice : 0 : 4
            "
          >
            <div class="col">
              <mat-card class="profile-card" appearance="outlined">
                <div class="row align-items-center">
                  <div class="col-sm-2">
                    <div class="logo-container">
                      <div class="avatar-card">
                        <img [src]="following.following?.avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <h4 class="mat-subtitle-2" style="margin: 2px;">
                      {{ following.following.display_name }}
                    </h4>
                    <p style="margin: 2px;">
                      <span *ngFor="let tag of following.following.tags"
                        ># {{ tag.display_name }}
                      </span>
                    </p>
                    <p style="margin: 2px;">{{ following.following.followerCount }} Takipçi</p>
                  </div>
                </div>
                <div class="row block-button">
                  <button mat-flat-button class="block">
                    <mat-icon>check</mat-icon>
                    Takip Ediliyor
                  </button>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Şirketler">
        <div class="row row-cols-2 m-1">
          <ng-container
            *ngFor="
              let following of followings
                | usertypefilter : 'Company'
                | slice : 0 : 4
            "
          >
            <div class="col p-1">
              <mat-card appearance="outlined" class="profile-card">
                <div class="row align-items-center">
                  <div class="col-sm-2">
                    <div class="logo-container">
                      <div class="avatar-card">
                        <img [src]="following.following?.avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <h4 class="mat-subtitle-2" style="margin: 2px;">
                      {{ following.following.display_name }}
                    </h4>
                    <p>
                      <span *ngFor="let tag of following.following.tags"
                        ># {{ tag.display_name }}
                      </span>
                    </p>
                    <p>{{ following.following.followerCount }} Takipçi</p>
                  </div>
                </div>
                <div class="row block-button">
                  <button mat-flat-button class="block">
                    <mat-icon>check</mat-icon>
                    Takip Ediliyor
                  </button>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Topluluklar">
        <div class="row row-cols-2 m-1">
          <ng-container
            *ngFor="
              let following of followings
                | usertypefilter : 'Community'
                | slice : 0 : 4
            "
          >
            <div class="col p-1">
              <mat-card appearance="outlined" class="profile-card">
                <div class="row align-items-center">
                  <div class="col-sm-2">
                    <div class="logo-container">
                      <div class="avatar-card">
                        <img [src]="following.following?.avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <h4 class="mat-subtitle-2 mt-2">
                      {{ following.following.display_name }}
                    </h4>
                    <p>
                      <span *ngFor="let tag of following.following.tags"
                        ># {{ tag.display_name }}
                      </span>
                    </p>
                    <p>{{ following.following.followerCount }} Takipçi</p>
                  </div>
                </div>
                <div class="row block-button">
                  <button mat-flat-button class="block">
                    <mat-icon>check</mat-icon>
                    Takip Ediliyor
                  </button>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

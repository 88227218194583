<ng-container *ngIf="messagingService.currentRoom$ | async as room">
  <div class="content" *ngIf="room.roomType != 'DIRECT_MESSAGE'">
    <div class="header">
      <div class="title">
        <h3>Oda Bilgisi</h3>
      </div>
    </div>
    <div class="top">
      <div class="image" (click)="selectImage(room)">
        <img [src]="room.image || '/assets/img/default.png'" alt="avatar" />
      </div>
      <div>
        <div class="name">
          <ng-container *ngIf="!editName">
            <h3>{{ room.name }}</h3>
            <mat-icon
              *ngIf="room | isRoomEditable"
              class="edit"
              (click)="editName = !editName"
              >edit</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="editName">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="room.name" />
              <button matSuffix mat-icon-button (click)="saveRoom(room)">
                <mat-icon> done </mat-icon>
              </button>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="info">
          <p>
            {{
              room.type == "DIRECT_MESSAGE" ? "Özel Mesaj" : "Gurup Görüşmesi"
            }}
          </p>
        </div>
      </div>
    </div>
    <message-room-members></message-room-members>
    <message-member-details
      *ngIf="room.roomType == 'DIRECT_MESSAGE'"
    ></message-member-details>
    <message-chat-files></message-chat-files>
  </div>
</ng-container>

<div class="content">
    <div class="top-button">
        <div class="name">
            <h2>{{messaging.currentRoom$.value?.name}}</h2>
        </div>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="mid">
        <div class="mid-content">
            <div class="image-content">
                <img [src]="images[activeFileIndex] | imageFile" alt="">
            </div>
        </div>
    </div>
    <div class="bottom">
        <div class="bottom-content">
            <div class="image-content" *ngFor="let file of images; let i = index">
                <img [class.active]="activeFileIndex==i" [src]="file | imageFile" alt="" (click)="activeFileIndex=i;">
            </div>
        </div>
    </div>
</div>
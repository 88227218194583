<h1 mat-dialog-title>İlgi alanlarım</h1>
<div mat-dialog-content>
  <ng-container *ngIf="interests$ | async as interests; else loading">
    <mat-tab-group>
      <mat-tab label="İlgi Alanlarım">
        <ng-template matTabContent>
          <div style="margin-top: 16px">
            <h2>İlgi Alanları</h2>
            <mat-chip-set>
              <mat-chip-option
                *ngFor="let item of interests.main"
                [selected]="isSelected(item)"
                (click)="toggleSelection(item)"
              >
                {{ item.name }}
              </mat-chip-option>
            </mat-chip-set>
          </div>
          <div class="mt-4">
            <h2>Sektörler</h2>
            <mat-chip-set>
              <mat-chip-option
                *ngFor="let item of interests.sectors"
                [selected]="isSelected(item)"
                (click)="toggleSelection(item)"
              >
                {{ item.name }}
              </mat-chip-option>
            </mat-chip-set>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Seçimlerim">
        <ng-template matTabContent>
          <div style="margin-top: 16px" *ngFor="let category of other">
            <h2>
              {{ category.name }}
            </h2>
            <mat-chip-set>
              <mat-chip-option
                *ngFor="let item of category.data"
                [selected]="isSelected(item)"
                (click)="toggleSelection(item)"
                [value]="item"
              >
                {{ item.name }}
              </mat-chip-option>
            </mat-chip-set>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-template #loading>
    <div class="row">
      <div class="col-lg-2" *ngFor="let _ of [].constructor(20)">
        <loading-chip></loading-chip>
      </div>
    </div>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button class="button-other" [mat-dialog-close]="">Vazgeç</button>
  <button mat-button class="button-primary" cdkFocusInitial (click)="save()">Kaydet</button>
</div>

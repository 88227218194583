
<div class="header">
    <div class="badge">TOPLULUKLAR</div>
    <h2 class="popular-title">Yörüngemizde Kendi Topluluğunuzu Bulun</h2>
    
</div>
   
<div class="planet-container">
     <home-partners></home-partners>
</div>
<div class="d-flex align-items-center flex-column gap-10 footer">
    <div class="bottom-text">
        Sen de zengin topluluk havuzumuzun yörüngesine katılmak istiyorsan,
    </div>
   <div class="px-2">
    <button class="primary-button">Yörüngedeki Topluluklara Göz At</button>
    <button class="secondary-button">Kendi Topluluğunu Yörüngeye Ekle</button>
   </div>
</div>
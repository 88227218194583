import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'search-people',
  templateUrl: './search-people.component.html',
  styleUrls: ['./search-people.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPeopleComponent implements OnInit {

  @Input('items') items : User[]

  constructor() { }

  ngOnInit(): void {
    console.log(this.items)
  }

  longText = ` Türkiye • İnsan Kaynakları Yöneticisi`;


}

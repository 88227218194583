<div class="container">
  <div class="container-searchs">
    <mat-card class="st card">
      <p><span>Önerilen Anahtar Kelimeler</span></p>
      <mat-chip-set class="example-chip">
        <ng-container  *ngFor="let key of suggestedTags">
          <mat-chip class="example-box" *ngIf="key" [routerLink]="['/job-posting']" [queryParams]="{tag:key}">
            <mat-icon matChipAvatar>search</mat-icon>{{ key }}
          </mat-chip>
          <loading-chip *ngIf="!key"></loading-chip>
        </ng-container>
      </mat-chip-set>
    </mat-card>
  </div>
  <br />
  <div class="st container-suggestions">
    <mat-card appearance="outlined" class="card">
      <h2>Önerilen Staj İlanları</h2>
      <div class="row">
        <div class="col-sm-6" *ngFor="let job of suggestedJobPostings">
          <job-posting-card-small [item]="job"></job-posting-card-small>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="st container-newposts">
    <mat-card appearance="outlined" class="card">
      <h2>Yakındaki Yeni İlanlar</h2>
      <div class="row">
        <div class="col-sm-6" *ngFor="let job of newJobPostings">
          <job-posting-card-small [item]="job"></job-posting-card-small>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="container-explore">
    <info-card
      title="Projeleri Keşfet"
      text="Kariyerini başlatmak için projeleri keşfet ve ilgini çeken projeler yapan yeni toplulukları takip et."
      icon="rocket_launch"
      buttonText="Projeleri Keşfet"
      (buttonClick)="navigateProjects()"
    ></info-card>
  </div>
  <div class="st container-peoples">
    <mat-card appearance="outlined" class="card">
      <h2>İş Verenler</h2>
      <div class="row">
        <div class="col-sm-6" *ngFor="let profile of profiles">
          <person-card-small [person]="profile"></person-card-small>
        </div>
      </div>
    </mat-card>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loggedIn } from '@common/auth/logged-in.service';
import { LoginComponent } from '@common/auth/login/login.component';
import { notLoggedIn } from '@common/auth/not-logged-in.service';
import { RegisterComponent } from '@common/auth/register/register.component';
import { MainSettingsComponent } from '@common/auth/settings/main-settings/main-settings.component';
import { NotFoundComponent } from './site/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [notLoggedIn],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [notLoggedIn],
  },
  {
    path: 'settings',
    component: MainSettingsComponent,
    canActivate: [loggedIn],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'moderation',
    loadChildren: () =>
      import('./moderation/moderation.module').then((m) => m.ModerationModule),
  },
  // for all other pages
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<main-content>
  <div class="content container">
    <div class="d-flex justify-content-between">
      <h2>Aktif Projeler</h2>
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of active">
        <project-card [project]="project"></project-card>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <h2>Geçmiş projeler</h2>
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of past">
        <project-card [project]="project"></project-card>
      </div>
    </div>
  </div>
</main-content>

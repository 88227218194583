<main-content>
  <div class="container">
    <h1>Şirketler</h1>

    <div class="header">
      <div class="container">
        <div class="content">
          <div class="d-flex justify-content-between">
            <h2>Takip Ettiklerim</h2>
          </div>
          <div class="joined">
            <div class="container">
              <div class="row">
                <div class="col-sm-4" *ngFor="let company of followedCompanies">
                  <company-card-big [company]="company"></company-card-big>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main-content>
import { Component } from '@angular/core';
import { ProjectsService } from '@common/services/projects.service';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'projects-extended',
  templateUrl: './projects-extended.component.html',
  styleUrls: ['./projects-extended.component.scss'],
})
export class ProjectsExtendedComponent {
  constructor(public projects: ProjectsService) {}

  public past: Project[] = [];
  public active: Project[] = [];

  ngOnInit(): void {
    this.projects.getMyProjects().subscribe((res) => {
      this.active = res.data.active;
      this.past = res.data.past;
    });
  }
}

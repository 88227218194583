import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import {
  CommunitiesHomeData,
  CommunitiesService,
} from '@common/services/communities.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss'],
})
export class CommunitiesComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public communitiesService: CommunitiesService
  ) {}

  public error: any;
  public communities: CommunitiesHomeData;
  public loading = true;
  public communities$ = this.communitiesService
    .getHome()
    .pipe(map((res) => res.data));

  ngOnInit(): void {
    this.communities$.pipe(untilDestroyed(this)).subscribe({
      next: (communities) => {
        this.communities = communities;
        this.loading = false;
      },
      error: (err) => {
        this.error = err;
        this.loading = false;
      },
    });
  }
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="files-selected" *ngIf="files.controls.length">
    <p class="files-text">{{ files.controls.length }} dosya seçili</p>
    <button type="button" mat-icon-button (click)="clearFiles()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-form-field appearance="outline">
    <button type="button" matIconPrefix mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>attachment</mat-icon>
    </button>
    <mat-label>Bir mesaj yaz</mat-label>
    <input matInput placeholder="Mesaj" formControlName="message" />
    <mat-icon matSuffix>send</mat-icon>
  </mat-form-field>
</form>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="addFile('image')"><mat-icon>image</mat-icon> Resim</button>
  <button mat-menu-item (click)="addFile('video')"><mat-icon>movie</mat-icon> Video</button>
  <button mat-menu-item (click)="addFile('all')"><mat-icon>file_copy</mat-icon> Dosya</button>
</mat-menu>

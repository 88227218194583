
<div class="footerSection">
  <div class="contact">
    <div class="left-side">
      Peki sen, kariyerinin kilidini<br />
      hala açmadın mı? <br />
      Anahtar, bir tık uzağında.
    </div>
    <div class="right-side">
      <text-input placeholder="E-posta adresiniz" icon="event"></text-input>
      <div class="buttons">
        <button class="primary-button">Bugün Aramıza Katılın</button>
        <span class="text">veya</span>
        <button class="flat-button">Google ile Kaydolun</button>
        <button class="flat-button">Apple ile Kaydolun</button>
      </div>
      <div class="promote-text">
        Ayrıca şimdi kayıt olarak Erken Erişim avantajlarımızdan ve fırsatlarımızdan yararlanın.
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="d-flex justify-content-center"> <img src="assets/img/logo-dark.png" style="width: 186px; height: 39px;" /></div>
   
    <div class="serviceCards">
      <div class="card">
        <div class="icon"><img src="assets/svg/starIcon.svg"></div>
        <div class="content">
          <div class="title">UNILOCKED VE YAPAY ZEKA - BLOG</div>
          <div class="text">Yapay Zeka'yı Deneyiminize Nasıl Entegre Ettiğimizi Öğrenin</div>
        </div>
      </div>
      <div class="card">
        <div class="icon"><img src="assets/svg/notificationIcon.svg"></div>
        <div class="content">
          <div class="title">ERKEN ERİŞİM - BLOG</div>
          <div class="text">Unilocked Erken Erişim’e Hoş Geldiniz!</div>
        </div>
      </div>
      <div class="card">
        <div class="icon"><img src="assets/svg/peopleIcon.svg"></div>
        <div class="content">
          <div class="title">SERVİSLER</div>
          <div class="text">Sorunsuz Çalışıyor Tüm servisler çevirmiçi</div>
        </div>
      </div>
    </div>
    <div class="linkColumns">
      <div>
        <div class="title">KEŞİF</div>
        <p>Topluluğu Keşfedin</p>
        <p>Katalog ile Aradığınızı Bulun</p>
        <p>Yeni Bağlantılar Edinin</p>
        <p>Topluluklar Bulun</p>
        <p>Projelere Katılın</p>
      </div>
      <div>
        <div class="title">İLANLAR</div>
        <p>Yeni İlanları Keşfedin</p>
        <p>İyi Bir Staj Başvurusu
          Nasıl Olmalıdır - Unilocked Blog</p>
        <p>Kazanan Bir CV
          Hazırlama - Unilocked Blog</p>
        
      </div>
      <div>
        <div class="title">UNILOCKED BLOG</div>
        <p>Öne Çıkanları Keşfedin</p>
        <p>En Taze Gönderiler</p>
        <p>Blog Sayfanızı 
          Başlatma - Unilocked Blog</p>
        
      </div>
      <div>
        <div class="title">HAKKIMIZDA</div>
        <p>İletişim</p>
        <p>Unilocked Hakkında</p>
        <p>Partnerler</p>
        <p>Aramıza Katılın</p>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between pt-2">
      <div class="d-flex flex-row justify-content-between">
        <button class="primary-button rounded" >Unilocked Destek</button>
      <button class="flat-button">Kullanıcı Anlaşması</button>
      <button class="flat-button">Gizlilik Politikası</button>
      <button class="flat-button">Çerez Politikası</button>
      <button class="flat-button">Erişebilirlik</button>
      </div>
      <div class="flex-end copyright">Unilocked &#169; 2024 - Tüm Hakları Saklıdır</div>
      
     
    </div>
  </div>
</div>

<!--convert to @if-->
@if(bootstrapError$ | async; as bootstrapError) {
<error-page [error]="bootstrapError"></error-page>
} @else { @if(loadingBootstrap$ | async) {
<div class="loading-container">
  <mat-spinner></mat-spinner>
</div>
} @else {
<div class="bg">
  <router-outlet></router-outlet>
</div>
} }

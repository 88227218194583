import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from '@common/services/calendar.service';
import { CalendarWeekComponent } from 'src/app/admin/calendar/calendar-week/calendar-week.component';

@Component({
  selector: 'personal-calendar-week',
  templateUrl: './personal-calendar-week.component.html',
  styleUrls: ['./personal-calendar-week.component.scss', '../panel.scss'],
})
export class PersonalCalendarWeekComponent implements AfterViewInit, OnInit {
  constructor(public calendarService: CalendarService) {}
  @ViewChild('calendar') calendar: CalendarWeekComponent;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.calendar.dataObserver$.next((date) => {
      return this.calendarService.getWeek(date);
    });
  }
}

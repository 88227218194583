import { Component, Input } from '@angular/core';
import { JobPosting } from 'src/app/models/job-posting';

@Component({
  selector: 'job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent {
  @Input() data: JobPosting;

  public getMappedData() {
    return {
      type: 'JobPosting',
      id: this.data.id,
    };
  }
}

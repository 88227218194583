import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { imageFile } from '@common/core/pipes/image-file.pipe';
import { Toast } from '@common/core/toast/toast/toast.service';
import { CompaniesService } from '@common/services/companies.service';
import { MembersService } from '@common/services/members.service';
import { ProfileService } from '@common/services/profile.service';
import { SchoolsService } from '@common/services/schools.service';
import { switchMap, map } from 'rxjs';
import { Community } from 'src/app/models/community';
import { Event } from 'src/app/models/event';
import { JobPosting } from 'src/app/models/job-posting';
import { Post } from 'src/app/models/post';
import { Project } from 'src/app/models/project';
import { Members } from 'src/app/models/relations/members';
import { University } from 'src/app/models/university';

@Component({
  selector: 'school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss'],
})
export class SchoolComponent implements OnInit {
  subscriber: any;
  constructor(
    public profileService: ProfileService,
    public currentUser: AuthService,
    public activatedRoute: ActivatedRoute,
    public membersService: MembersService,
    public companies: SchoolsService,
    public toast: Toast
  ) {}

  public getStyle(company: University) {
    return {
      'background-image': `url(${imageFile(company.backdropFile)})`,
    };
  }

  public companyData$ = this.activatedRoute.params
    .pipe(switchMap((params) => this.companies.getSchool(params['id'])))
    .pipe(map((res) => res.data));

  public members: Members[] = [];
  public communities: Community[] = [];
  public jobPostings: JobPosting[] = [];
  public company: University;
  public posts: Post[] = [];
  public isMe = false;
  public projects: Project[] = [];
  public events: Event[] = [];

  ngOnInit(): void {
    this.subscriber = this.companyData$.subscribe((data) => {
      this.members = data.members;
      this.jobPostings = data.jobPostings;
      this.company = data.school;
      this.communities = data.communities;
      this.projects = data.projects;
      this.events = data.events;
      this.posts = data.posts;
      this.isMe =
        this.currentUser.selectedMember.profileId === this.company.id;
    });
  }

  public getActiveProjects() {
    return this.projects.filter((project) => project.endDate > new Date());
  }

  public getPastProjects() {
    return this.projects.filter((project) => project.endDate < new Date());
  }

  public copyLink() {
    navigator.clipboard.writeText(window.location.href);
    this.toast.open('Bağlantı kopyalandı');
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}

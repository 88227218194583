import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { CustomPage } from 'src/app/models/custom-page';

const BASE = 'pages';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(private http: AppHttpClient) {}

  public getBySlug(slug: string) {
    return this.http.get<
      StandartResponse<{
        page: CustomPage;
      }>
    >(`${BASE}/${slug}`);
  }

  public getById(id: string) {
    return this.http.get<
      StandartResponse<{
        page: CustomPage;
      }>
    >(`${BASE}/${id}`);
  }

  public getPages() {
    return this.http.get(`${BASE}`);
  }

  public get;
}

import { Component } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { CompaniesService } from '@common/services/companies.service';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'companies-extended',
  templateUrl: './companies-extended.component.html',
  styleUrls: ['./companies-extended.component.scss'],
})
export class CompaniesExtendedComponent {
  constructor(public auth: AuthService, public companies: CompaniesService) {}

  public followedCompanies: Company[] = [];

  ngOnInit(): void {
    this.companies.getFollowed().subscribe((companies) => {
      this.followedCompanies = companies.data;
    });
  }
}

<main-content>
  <div class="saved-area container">
    <h1>Kaydedilenler</h1>
    <mat-tab-group>
      <mat-tab label="Kaydedilen Gönderiler">
        <saved-posts></saved-posts>
      </mat-tab>
    </mat-tab-group>
  </div>
</main-content>

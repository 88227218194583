import { Component, Input } from '@angular/core';
import { Community } from 'src/app/models/community';

@Component({
  selector: 'community-card-small',
  templateUrl: './community-card-small.component.html',
  styleUrls: ['./community-card-small.component.scss'],
})
export class CommunityCardSmallComponent {
  @Input() community: Community;
}

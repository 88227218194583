import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usertypefilter'
})
export class UsertypefilterPipe implements PipeTransform {

  transform(followings: any[], type: string): any[] {
    if (!followings || !type) {
      return [];
    }
    return followings.filter(following => following.following.type === type);
  }

}

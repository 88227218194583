import { Component } from '@angular/core';
import { PostsService } from '../posts.service';
import { AuthService } from '@common/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, switchMap } from 'rxjs';
import { Post } from 'src/app/models/post';

@Component({
  selector: 'post-page',
  templateUrl: './post-page.component.html',
  styleUrls: ['./post-page.component.scss'],
})
export class PostPageComponent {
  constructor(
    public posts: PostsService,
    public auth: AuthService,
    public activatedRoute: ActivatedRoute
  ) {}

  public post$ = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.posts.get(params['id']);
    })
  );

  public page$ = new BehaviorSubject(1);

  public replies$ = this.page$.pipe(
    switchMap((page) => {
      return this.posts.getReplies(
        this.activatedRoute.snapshot.params['id'],
        page
      );
    })
  );

  public addReply(post:Post) {
    
  }
}

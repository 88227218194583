import { Component, Inject, OnInit } from '@angular/core';
import { OVERLAY_PANEL_DATA } from '@common/ui/overlay-panel/overlay-panel-data';
import { OverlayPanelRef } from '@common/ui/overlay-panel/overlay-panel-ref';
import { Message } from 'src/app/models/messages/message';
import { MessagingService } from '../../messaging.service';

@Component({
  selector: 'message-file-preview',
  templateUrl: './message-file-preview.component.html',
  styleUrls: ['./message-file-preview.component.scss'],
})
export class MessageFilePreviewComponent implements OnInit{
  constructor(
    public ref: OverlayPanelRef<MessageFilePreviewComponent>,
    @Inject(OVERLAY_PANEL_DATA) public data: {
      selectedIndex: number;
      message: Message;
    },
    public messaging:MessagingService
  ) {}

  public get message() {
    return this.data.message;
  }

  public activeFileIndex = 0;

  public close() {
    this.ref.close();
  }

  public get images() {
    if (!this.message) return [];
    return this.message.files.filter((file) => file.type.includes('image'));
  }

  public get imagesHasMoreThanFour() {
    return this.images.length > 4;
  }

  public get imagesToShow() {
    return this.images.slice(0, 4);
  }

  ngOnInit(): void {
    this.activeFileIndex = this.data.selectedIndex;
  }
}

<ng-container *ngIf="searchParam$ | async; else categories">
  <categories-search></categories-search>
</ng-container>
<ng-template #categories>
  <ng-container *ngIf="categories$ | async as categories">
    <div class="container">
      <div class="input-container">
        <i class="material-icons">search</i>
        <input type="text" placeholder="Arama Yap" [(ngModel)]="filterText" />
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Kişiler</h2>
        </div>

        <mat-chip-option
          *ngFor="let category of categories.people"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Topluluklar</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.communities"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Şirketler</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.companies"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Kurumlar</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.schools"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Etkinlikler</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.events"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Projeler</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.projects"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>İlanlar</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.jobPostings"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
      <div style="width: 100%; margin-top: 5%">
        <div style="padding: 0">
          <h2>Sektörler</h2>
        </div>
        <mat-chip-option
          *ngFor="let category of categories.sectors"
          (click)="discoveryCatalogService.toggleTag(category)"
          [class.selected]="discoveryCatalogService.isTagSelected(category)"
          [class.active]="category.type == 'big'"
        >
          <span class="chip-text">{{ category.name }}</span>
        </mat-chip-option>
      </div>
    </div>
  </ng-container>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '@common/services/blog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs';
import { CustomPage } from 'src/app/models/custom-page';

@UntilDestroy()
@Component({
  selector: 'blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent implements OnInit {
  constructor(
    public acitvatedRoute: ActivatedRoute,
    public blogService: BlogService
  ) {}

  public blog: {
    blog: CustomPage;
    blogsFromSameAuthor: CustomPage[];
  };

  public blog$ = this.acitvatedRoute.params
    .pipe(
      switchMap((params) => {
        return this.blogService.getBlogPost(params['id']);
      })
    )
    .pipe(map((res) => res.data))
    .pipe(untilDestroyed(this));

  public getUserBlogPosts(user: string) {
    return this.blogService.getUserPosts(user).pipe(map((res) => res.data));
  }

  ngOnInit(): void {
    this.blog$.subscribe({
      next: (blog) => {
        this.blog = blog;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public onReady() {}
}

<ng-container *ngIf="projects$ | async as projects">
  <div class="content container">
    <div class="d-flex justify-content-between">
      <h2>Aktif Projeler</h2>
      <a
        [routerLink]="['/projects-extended']"
        routerLinkActive="router-link-active"
        mat-button
        color="primary"
        >Hepsini gör</a
      >
    </div>
    <div class="row">
      @if (projects.active?.length) {
      <div
        class="col-lg-6 col-md-6 col-sm-12"
        *ngFor="let project of projects.active"
      >
        <project-card [project]="project"></project-card>
      </div>
      } @else {
      <div class="col-sm-12">Burası boş görünüyor</div>
      }
    </div>

    <div class="d-flex justify-content-between">
      <h2>Geçmiş projeler</h2>
      <button mat-button color="primary">Hepsini gör</button>
    </div>
    <div class="row">
      @if (projects?.past?.length) {
      <div class="col-sm-6" *ngFor="let project of projects.past">
        <project-card [project]="project"></project-card>
      </div>
      } @else {
      <div class="col-sm-12">Burası boş görünüyor</div>
      }
    </div>

    <div class="d-flex justify-content-between">
      <h2>İstekler</h2>
    </div>
    <div class="row">
      @if (projects?.invited?.length) {
      <div class="col-sm-6" *ngFor="let project of projects.invited">
        <project-card [project]="project"></project-card>
      </div>
      } @else {
      <div class="col-sm-12">Burası boş görünüyor</div>
      }
    </div>
  </div>
</ng-container>

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobPostingService } from '@common/services/job-posting.service';
import { map } from 'rxjs';
import { JobPosting } from 'src/app/models/job-posting';
import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'job-posting-home',
  templateUrl: './job-posting-home.component.html',
  styleUrls: ['./job-posting-home.component.scss'],
})
export class JobPostingHomeComponent implements OnInit {
  public profiles: any[] = [];
  public suggestedJobPostings: JobPosting[] = [null, null, null, null, null];
  public newJobPostings: JobPosting[] = [null, null, null, null, null];
  public suggestedTags: string[] = [
    null,
    null,
    null,
    null,
    null,
  ];

  constructor(public jobPostings: JobPostingService, public router: Router) {}

  public navigateProjects() {
    this.router.navigate(['/projects']);
  }

  ngOnInit(): void {
    this.jobPostings
      .getHome()
      .pipe(map((data) => data.data))
      .subscribe((data) => {
        this.suggestedJobPostings = data.suggestedJobPostings;
        this.newJobPostings = data.newJobPostings;
        this.suggestedTags = data.suggestedTags;
        this.profiles = data.profiles;
      });
  }
}

<div class="main">
  <guest-navbar></guest-navbar>
  <!-- Hero -->

  <section id="heroSection" class="heroSection in-viewport section">
    <div
      class="d-flex justify-content-center align-items-start flex-column hero"
    >
      <div class="hero-text">
        <h1>Kariyerinizin kilidini<br/>
        <hero-text-animation></hero-text-animation><br/>
        Açın</h1>
      </div>
      <div class="sub-hero-text">
        <p>
          Eğer sen de kariyerinin kilidini açarak hayallerini ve hedeflerini bir
          üst noktaya taşımak istiyorsan hiç beklemeden kayıt ol.
        </p>

        <div class="input-area">
          
            <text-input
            class="input-text"
              
              placeholder="Eposta Adresinizi Girin"
              icon="event"
            ></text-input>
         
          
            <button mat-flat-button class="button-other">
            Bugün Aramıza Katılın
          </button>
            
          
        </div>
        <div class="d-flex align-items-center gap-20 mt-2 mb-2">
          <span>veya</span>
          <button mat-button color="primary">Google ile Kaydolun</button>
        </div>
      </div>
    </div>
  </section>
  <!--About-->
  <div class="d-flex justify-content-center">
    <section class="section about-section" id="section2" #sectionElement>
      <div class="about-container">
        <div class="left-side">
          <h4>Unilocked Nedir?</h4>
          <div class="items">
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 1 ? 'active' : ''"
            >
              Unilocked, Öğrencilerin kendi potansiyellerini keşfedebilecekleri
            </div>
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 2 ? 'active' : ''"
            >
              Profesyonellerin aradıkları kriterlerdeki genç uzmanlara
              kolaylıkla ulaşabilecekleri
            </div>
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 3 ? 'active' : ''"
            >
              Proje sahiplerinin kendileri gibi düşünen yeteneklerle
              buluşabilecekleri
            </div>
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 4 ? 'active' : ''"
            >
              Organizatörlerin, etkinliklerini geniş kitlelere duyurabileceği
            </div>
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 5 ? 'active' : ''"
            >
              Toplulukların kolayca bağlantı kurabildiği
            </div>
            <div
              class="item"
              [ngClass]="activeSectionItem.id === 6 ? 'active' : ''"
            >
              Herkes için özelleştirilmiş bir kariyer yönetim platformu
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="right-item active">
            <div>
              <img
                src="assets/svg/worldIcon.svg"
                width="112"
                height="112"
                alt="worldIcon"
              />
              <h1>{{ aboutItems[activeSectionItem.id - 1]?.title }}</h1>
              <span>
                {{ aboutItems[activeSectionItem.id - 1]?.text }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- Sliding Cards -->
  <section class="section page-cards-section" id="pageCards" #pageCards>
    <div class="cards">
      <div
        class="card card-1-color"
        [ngClass]="{'hidden' : activeSectionItem.id>1,
            'display-none' : activeSectionItem.id>2,
            }"
      >
        <div
          class="d-flex flex-column justify-content-end"
          style="height: 100%; padding: 10px; gap: 10px"
        >
          <div class="d-flex gap-10">
            <div class="chip">
              <mat-icon>explore</mat-icon>
              <span>KEŞİF</span>
            </div>
            <div class="chip-button">
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div>
            Kapsamlı keşfetme panelimiz ile kariyerinle ilgili aradığın her şey
            bir arama uzağında.
          </div>
        </div>
      </div>
      <div
        class="card card-2-color"
        [ngClass]="{
          hidden: activeSectionItem.id > 2,
          'card-slide-1': activeSectionItem.id >= 2,
          'display-none': activeSectionItem.id > 3
        }"
      >
        <div
          class="d-flex flex-column justify-content-between"
          style="height: 100%; padding: 10px; gap: 10px"
        >
          <div class="d-flex gap-10 justify-content-end">
            <div class="chip-button">
              <mat-icon>add</mat-icon>
            </div>
            <div class="chip">
              <mat-icon>layers</mat-icon>
              <span>KEŞİF KATALOĞU</span>
            </div>
          </div>
          <div>
            Yapay Zeka algoritmamızın senin için tavsiye ettiği keşiflere göz
            atabilirsin.
          </div>
        </div>
      </div>
      <div
        class="card card-3-color"
        [ngClass]="{
          hidden: activeSectionItem.id > 3,
          'card-slide-1': activeSectionItem.id >= 2,
          'card-slide-2': activeSectionItem.id >= 3,
          'display-none': activeSectionItem.id > 4
        }"
      >
        <div
          class="d-flex flex-column justify-content-end"
          style="height: 100%; padding: 10px; gap: 10px"
        >
          <div class="d-flex gap-10 justify-content-end">
            <div class="chip-button">
              <mat-icon>add</mat-icon>
            </div>
            <div class="chip">
              <mat-icon>lightbulb</mat-icon>
              <span>PROJELER</span>
            </div>
          </div>
          <div>
            Fikrini projeye dökmen ya da proje arkadaşı bulman için projeni,
            proje sayfamızda yayınlaman yeterli.
          </div>
        </div>
      </div>
      <div
        class="card card-4-color"
        [ngClass]="{
          hidden: activeSectionItem.id > 4,
          'card-slide-1': activeSectionItem.id >= 2,
          'card-slide-2': activeSectionItem.id >= 3,
          'card-slide-3': activeSectionItem.id >= 4,
          'display-none': activeSectionItem.id > 5
        }"
      >
        <div
          class="d-flex flex-column justify-content-start"
          style="height: 100%; padding: 10px; gap: 10px"
        >
          <div class="d-flex gap-10 justify-content-start">
           
            <div class="chip">
              <mat-icon>newspaper</mat-icon>
              <span>İLANLAR</span>
            </div>
            <div class="chip-button">
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div>
            Kariyerinle ilgili aradığın sponsorluklar, yatırımlar, kurslar...
            Kısacası tüm ilanlar avcunun içinde.
          </div>
        </div>
      </div>
      <div
        class="card card-5-color"
        [ngClass]="{
          hidden: activeSectionItem.id > 5,
          'card-slide-1': activeSectionItem.id >= 2,
          'card-slide-2': activeSectionItem.id >= 3,
          'card-slide-3': activeSectionItem.id >= 4,
          'card-slide-4': activeSectionItem.id >= 5
        }"
      >
        <div
          class="d-flex flex-column justify-content-center"
          style="height: 100%; padding: 10px; gap: 10px"
        >
          <div class="d-flex gap-10 justify-content-start">
            <div class="chip">
              <mat-icon>event</mat-icon>
              <span>ETKİNLİKLER</span>
            </div>
            <div class="chip-button">
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div>
            Çevrende gerçekleşen tüm etkinlikleri takip edebilir, takvimine
            ekleyebilir ve hatta sen de bu etkinlikleri düzenleyenlerden biri
            olarak daha geniş kitlelere ulaşabilirsin.
          </div>
        </div>
      </div>
    </div>
    <div class="page-cards-title">Unilocked'u Keşfedin</div>
  </section>
  <div class="section-saver" id="popular-today-section-saver"></div>
  <div class="section-saver" id="popular-today-section-saver"></div>
  
  <!-- Popular Today -->
  <section
    #popularToday
    id="popularToday"
    class="section popular-today-section"
  >
  <div class="badge mt-5 mx-5">
    BUGÜN POPÜLER
  </div>
  <h4 class="popular-title mx-5">Unilocked Topluluğunda Bugün Neler Popüler?</h4>
    <popular-today></popular-today>
    <div class="planet-container">
      <home-partners></home-partners>
    </div>
  </section>
  <section #planet id="planet" class="section planet-section">
   <partners></partners>
  </section>
  <section
    class="section flipping-cards-section"
    #flippingCards
    id="flippingCards"
  >
    <flipping-cards></flipping-cards>
  </section>
  <section class="section blog-posts-section" #blogPosts id="blogPosts">
    <blog-posts></blog-posts>
  </section>
  <section class="footer-section" #footerSection id="footerSection">
    <footer></footer>
  </section>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
interface BlogPost {
  title: string;
  image: string;
  icon:string;
  content: string;
  subnote: string;
}
@Component({
  selector: 'blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent implements OnInit {
  @ViewChild('blogPosts') blogPosts : ElementRef;
 posts: BlogPost[] = [];
  ngOnInit(): void {
    this.posts = [{
      title: 'Keşif Sayfasını Keşfetmek',
      image: '/assets/img/image-55.png',
      icon: '/assets/svg/BlogIcon.svg',
      content: ' Keşif sayfasını en iyi şekilde kullanmaya başlamak için ipuçları burada.',
      subnote: 'Unilocked tarafından • 2 dakika okuma'
    },
    {
      title: 'Kariyeriniz İçin Gezilecek En Güzel Yerler',
      image: '/assets/img/blog-2.png',
      icon: '/assets/svg/BlogIcon.svg',
      content: 'Kariyer gelişimi için mutlaka seyehat etmeniz ve görmeniz gereken 10 şehir ve nedenleri.',
      subnote: 'Unilocked tarafından • 2 dakika okuma'
    },
  {
    title: 'Etkili Bir Kariyer Planlaması İçin Neler Yapmalı?',
    image: '/assets/img/blog-2.png',
    icon: '/assets/svg/BlogIcon.svg',
    content: 'Hayallerinin kilidini açacak etkili bir kariyer planlaması için yapman gerekenler ve çok daha fazlası yazının devamında.',
    subnote: 'Unilocked tarafından • 2 dakika okuma'
  },
  {
    title: 'Etkili Bir Kariyer Planlaması İçin Neler Yapmalı?',
    image: '/assets/img/blog-2.png',
    icon: '/assets/svg/BlogIcon.svg',
    content: 'Hayallerinin kilidini açacak etkili bir kariyer planlaması için yapman gerekenler ve çok daha fazlası yazının devamında.',
    subnote: 'Unilocked tarafından • 2 dakika okuma'
  }];
    
  }

  slide(left: boolean) {
    const scrollAmount = 350; 
    const scrollOptions: ScrollToOptions = {
      left: left ? scrollAmount : -scrollAmount,
      behavior: 'smooth' // Smooth scrolling
    };
  
    this.blogPosts.nativeElement.scrollBy(scrollOptions);
  }
}
